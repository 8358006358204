import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { Model } from 'projects/core/src/include/model';
import { User, UserGroup } from 'projects/core/src/include/structures';
import { UserService } from 'projects/core/src/lib/user.service';
import { UserGroupService } from 'projects/core/src/lib/usergroup.service';

@Component({
  selector: 'app-usergroup',
  templateUrl: './usergroup.component.html',
  styleUrl: './usergroup.component.css'
})
export class UserGroupComponent extends Model<UserGroup> implements OnInit,AfterViewInit {

  constructor(
    private route1: ActivatedRoute,
    private usergroupService: UserGroupService
  ){
    super(route1,usergroupService); 
  }

  get currentUser(){
    return Globals.user;
  }

  ngOnInit (): void {

    this.title = 'Dettaglio ruolo';
    this.record=new UserGroup();
    super.ngOnInit();

    Globals.setLoading(true);
    this.afterGetItem=()=>{      
      this.getPermissions();
    }

    this.getItem();

    
    this.afterSave=()=>{
      if(Globals.user.role==this.record.name){
        Globals.user.permissions=this.record.permissions;
      }
    }

    Globals.setLoading(false);
  }


  getPermissions(){

    

    let oldpermissions=[];

    if(this.record.permissions){
      oldpermissions=JSON.parse(this.record.permissions);
    }
    
    this.record.permissions=[];
    
    for(let k=0;k<Globals.permissions.list.length;k++){
      let m=Globals.permissions.list[k];
      
      let module={"modulename":m.modulename,"permissions":[]};
      for(let n=0;n<m.permissions.length;n++){
        let p=m.permissions[n];   

        let value=false;
        //verifica se vi era stata già effettuata un associazione
        for(let i=0;i<oldpermissions.length;i++){
          if(oldpermissions[i]['modulename']==m.modulename){
            for(let j=0;j<oldpermissions[i]['permissions'].length;j++){
              if(oldpermissions[i]['permissions'][j]['name']==p.name){
                value=oldpermissions[i]['permissions'][j]['value'];
              }
            }
          }
        }


        let permission={"name":p.name,"description":p.description,"value":value}
        module.permissions.push(permission);
      }
      this.record.permissions.push(module); 
      
    }
    console.log(this.record.permissions)
  
  }


  checkAll(modulename){
    for(let i=0;i<this.record.permissions.length;i++){
      if(this.record.permissions[i]['modulename']==modulename){
        for(let j=0;j<this.record.permissions[i]['permissions'].length;j++){
          this.record.permissions[i]['permissions'][j]['value']=!this.record.permissions[i]['permissions'][j]['value'];
        }
      }
    }
  }


  getModulename(modulename){
    
  }

}
