<app-windowlist [model]="this">
    <div class="row">
        <div class="col-lg-3 pr-lg-0 mb-1">
            <app-filtercontainer [title]="'Filtra per categorie o giacenze'" >
                <app-treecategories [class]="'card p-1'" [multiple]="true" (id_selected)="filter_id_category.value=$event;getItems()" [showAll]="true" [id]="filter_id_category.value" [editButton]="false" #tree [list]="categories"></app-treecategories>
                <ul class="card small-box list-group p-1 mt-2" *ngIf="isModuleEnabled('inventories')">
                    <li class="list-group-item pointer" [class.active]="filter_stock.mode==0" (click)="filter_stock.mode=0;getItems();">Tutti</li>
                    <li class="list-group-item pointer" [class.active]="filter_stock.mode==6" (click)="filter_stock.mode=6;getItems();">Giacenza positiva</li>
                    <li class="list-group-item pointer" [class.active]="filter_stock.mode==7" (click)="filter_stock.mode=7;getItems();">Giacenza negativa</li>
                    <li class="list-group-item pointer" [class.active]="filter_stock.mode==1" (click)="filter_stock.mode=1;getItems();">Giacenza nulla</li>
                    <div class="icon"><i class="fas fa-cubes"></i></div> 
                </ul>
            </app-filtercontainer>
        </div>
        <div class="col-lg-9 mb-1">
            <div class="card card-outline">
                <div class="p-0">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="input-group input-group-info input-group-sm p-1">
                                <input type="text" #table_search name="table_search" class="form-control form-control-sm" title="cerca ID con id:{id} oppure modello, codice articolo, codice fornitore o EAN" placeholder="cerca id:{id}, modello, cod. art./forn./EAN..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                <div class="input-group-append ">
                                    <button type="submit" class="btn btn-default text-primary"  title="avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger" *ngIf="filter_search.value!=''"  title="annulla ricerca" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 px-lg-0">
                            <div class="input-group input-group-sm p-1" >
                                <select class="form-control form-control-sm" [(ngModel)]="filter_id_brand.value" (ngModelChange)="getItems()" [class.alert-info]="filter_id_brand.value">
                                    <option value="">Tutte le marche</option>
                                    <option *ngFor=" let r of brands" [ngValue]="r.id">{{r.name}}</option>
                                </select>
                                <div class="input-group-append ">
                                    <button class="btn btn-sm btn-default" (click)="openBrandsManager()" title="Apri gestione marche"><i class="fa fa-cog"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-2 pl-lg-0">
                            <div class="input-group input-group-sm p-1" >
                                <select class="form-control form-control-sm" [(ngModel)]="filter_id_type.value" (ngModelChange)="getItems()" [class.alert-info]="filter_id_type.value">
                                    <option value="">Tutte le tipologie</option>
                                    <option *ngFor=" let r of types" [ngValue]="r.id">{{r.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 pl-0 py-1">
                            <div #checkboxselectall class="float-right icheck-primary d-inline m-2">
                                <input  type="checkbox" title="Seleziona tutti"
                                (change)="onChangeAll($event.target.checked)" />
                            </div>
                            <ng-container *ngIf="canEdit">
                                <app-windowlistbuttons 
                                [model]="this" 
                                [table]="'products'" 
                                [funs]="[]"
                                ></app-windowlistbuttons>
<!-- 
                                <button  title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right mr-1 mt-1"><i class="far fa-trash-alt"></i></button>
                                <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right mr-1mt-1 "><i class="fas fa-trash"></i></button>
                                <button  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right mr-1 mt-1"><i class="fas fa-undo"></i></button>
                                <button  title="Esporta" (click)="export()" class="btn btn-secondary btn-xs float-right mr-1 mt-1"><i class="fas fa-file-export"></i></button>
                                <button  title="Aggiungi" (click)="openDetail()" class="btn btn-success btn-xs float-right mr-1 mt-1"><i class="fas fa-plus"></i></button> -->
                            </ng-container>
                        </div>
                    </div>

                    <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                        <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>
                    </div>
                    <form *ngIf="list && list.length>0" [formGroup]="form">
                        <ul class="list-group border-top">
                            <li class="d-none d-lg-inline list-group-item text-sm bg-light border-bottom">                               
                                <div class="row">
                                    <div *ngIf="isModuleEnabled('inventories')" class="col-1 text-center">
                                        <br><b>Qt</b></div>
                                    <div class="px-0" [class.col-7]="isModuleEnabled('inventories')" [class.col-8]="!isModuleEnabled('inventories')">
                                        <br><b>Descrizione</b></div>
                                    <div class="col-lg-2 text-lg-center">
                                        <b>Prezzi</b>
                                        <div style="zoom:0.75">
                                            <app-type [showAll]="false" [id_type]="id_type_pricelist" [mode]="2"  [table]="'addresses'" (onEntrySelected)="id_type_pricelist=$event.id_type"   ></app-type>
                                        </div>                                    
                                    </div>
                                    <div class="col-2"></div>
                                </div>
                            </li>
                            <li class="list-group-item pb-0" *ngFor="let record of list">
                                <div class="row border-bottom pb-1">
                                    <div *ngIf="isModuleEnabled('inventories')" class="d-none d-lg-flex flex-column align-items-center justify-content-center col-lg-1"
                                            [class.bg-success]="record.stock>0" 
                                            [class.bg-light]="record.stock==0" 
                                            [class.bg-danger]="record.stock <0">
                                        <i *ngIf="!record.icon" class="fas fa-2x fa-box-open"></i>
                                        <i class="fa-2x {{record.icon}}"></i>
                                        <b *ngIf="record.stockable">{{record.stock}} <small>{{record.unit}}</small></b>
                                    </div>
                                    <div class="px-1" [class.col-lg-7]="isModuleEnabled('inventories')" [class.col-lg-8]="!isModuleEnabled('inventories')" >
                                       <app-productitem (OnSelected)="openDetail(record)" [product]="record"></app-productitem>
                                    </div>
                                    <div class="col-6 col-lg-2 text-lg-center p-1" *ngIf="showPrice">
                                        <h5 *ngIf="record.pricelist!=undefined && record.pricelist.length>0" title="prezzo di vendita (IVA inclusa)" class="text-primary mb-0 help">{{record.getPrice(1,2,{"id_type":id_type_pricelist})*(1+record.tax/100) | currency:"&euro; "}}</h5>
                                        <h5 *ngIf="record.pricelist==undefined || record.pricelist.length==0" title="nessun prezzo di vendita" class="help">nd</h5>
                                        <i  class="d-none d-lg-inline help" title="prezzo di acquisto (IVA inclusa)">({{record.netcost*(1+record.tax/100) | currency:" &euro; "}})</i>
                                    </div>                                   
                                    <div class="col-6 col-lg-2 pr-0 d-flex align-items-center justify-content-end" [class.col-4]="!showPrice">                                        
                                        <a (click)="duplicate(record)" title="Duplica" class="btn btn-info btn-xs float-right ml-1">
                                            <i class="far fa-clone"></i></a>
                                        <a type="button" *ngIf="mode!='modal'"  (click)="exportInRow(record)" title="Stampa" class="btn btn-warning btn-xs float-right ml-1">
                                                <i class="fas fa-print"></i></a>
                                                <a *ngIf="mode!='modal'" (click)="openDetail(record)" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right ml-1">
                                                    <i class="fas fa-pencil-alt"></i></a>
                                        <a *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right ml-1" (click)="selectRecord(record)">
                                            <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i></a>
                                        <input type="checkbox" #checkrecord id_record="{{record.id}}"  title="{{record.id}}"
                                            (change)="onChange(record.id, $event.target.checked)" class="m-1 float-right" [checked]="checkedAll"
                                            />
                                    </div>
                                </div>
                            </li>                            
                        </ul>

                        <!--<table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm" >
                            <thead class="bg-light">
                                <th>
                                    <input #checkboxselectall type="checkbox" (change)="onChangeAll($event.target.checked)" />
                                    &nbsp;Cod
                                </th>
                                <th>Marca e Modello</th>
                                <th>Categoria</th>
                                <th>Prezzo</th>
                                <th>Costo</th>
                                <th>Giacenza</th>
                                <th>
                                </th>
                            </thead>
                            <tbody *ngIf="list!=null">                           
                            <tr *ngFor="let record of list" class="border" >
                                <td><input type="checkbox" #checkrecord id_record="{{record.id}}" (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll" />
                                &nbsp;<small>{{record.codeinternal}}</small>
                                </td>
                                <td>
                                    <a  class="text-info" [class.trashed]="record.status == 2" [class.disabled]="mode=='modal'" (click)="openDetail(record)">
                                    <i class="{{record.icon}}"></i>
                                    {{record.brand}}<br/><strong>{{record.name}}</strong> <span *ngIf="record.has_serials">&nbsp;<i class="fas fa-barcode"></i></span>
                                    </a>
                                </td>
                                <td>{{record.category}}</td>
                                <td class="bg-success" *ngIf="record.pricelist!=undefined && record.pricelist.length>0" >{{record.pricelist[0].price | currency:"&euro; "}}</td>
                                <td *ngIf="record.pricelist==undefined || record.pricelist.length==0">nd</td>
                                <td class="bg-light">{{record.netcost | currency:" &euro; "}}</td>
                                <td ><span [class.text-success]="record.stock>0" [class.text-danger]="record.stock <=0">{{record.stock}} {{record.unit}}</span></td>
                                <td>
                                    <button *ngIf="mode!='modal'" (click)="openDetail(record)"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right m-1">
                                        <i class="fas fa-pencil-alt"></i></button>
                                    <button *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(record)">
                                        <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i></button>                                    
                                </td>
                            </tr>                            
                            </tbody>
                        </table>-->
                        <!-- /.table-responsive -->

                    </form>
                </div>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>