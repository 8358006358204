<section class="content">   
    <ul class="nav nav-tabs">
        <li class="nav-item">
            <a class="nav-link" [class.active]="tab==1" (click)="tab=1">
                <i class="fas fa-list"></i><span class="d-none d-sm-inline-block ml-2">Lista</span>
            </a>
        </li>
        <li class="nav-item" *ngIf="isModuleEnabled('deadlines') || isModuleEnabled('activities')">
            <a class="nav-link" [class.active]="tab==2" (click)="tab=2">
                <i class="fa fa-calendar-alt"></i><span class="d-none d-sm-inline-block ml-2">Scadenze</span>
            </a>
        </li>
        <li class="nav-item" *ngIf="isModuleEnabled('activities')">
            <a class="nav-link" [class.active]="tab==4" (click)="tab=4">
                <i class="fa fa-tasks"></i><span class="d-none d-sm-inline-block ml-2">Attività</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" [class.active]="tab==3" (click)="tab=3">
                <i class="fas fa-chart-bar"></i><span class="d-none d-sm-inline-block ml-2">Statistiche</span>
            </a>
        </li>
    </ul>
    <div class="tab-content">
        <div class="tab-pane fade show active" *ngIf="tab==1">
            <app-serials-list (selectedRecordOut)="recordSelected=$event" [modalWindow]="modalWindow" [mode]="mode"></app-serials-list>
        </div>
        <div class="tab-pane fade show active" *ngIf="tab==2">
            <app-serials-deadlines></app-serials-deadlines>
        </div>
        <div class="tab-pane fade show active" *ngIf="tab==3">
            <app-serials-analytics ></app-serials-analytics>
        </div>
        <div class="tab-pane fade show active" *ngIf="tab==4">
            <app-serials-activities (selectedRecordOut)="recordSelected=$event" [modalWindow]="modalWindow" [mode]="mode"></app-serials-activities>       
        </div>        
    </div>
</section>