<div class="d-none d-lg-block">    
    <ng-container *ngTemplateOutlet="list"></ng-container>
</div>
<div class="d-lg-none d-xs-block">
    <button class="btn btn-block btn-secondary btn-sm mb-1" (click)="openFilter()"><i class="fas fa-filter text-warning mr-1"></i>{{title}}</button>
</div>
<ng-template #list>
        <div class="p-2 d-lg-none d-xs-block">
            <b>{{title}}</b>
            <button type="button" class="btn btn-tool float-right" (click)="closeFilter()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
        <div class="px-2 p-lg-0 text-xs">
            <ng-content #content></ng-content>
        </div>
        <div class="p-2 d-lg-none d-xs-block">
            <button class="btn btn-sm btn-block btn-secondary" (click)="closeFilter()"><i class="fas fa-times mr-1"></i>Chiudi</button>
        </div>
</ng-template>