import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsValuesRoutingModule } from './forms-routing.module';
import { FormDetailComponent } from './form-list/form-detail/form-detail.component';
import { FormsModule } from '@angular/forms';



import { ReferenceModule } from '../../modules/reference/reference.module';
import { ModalModule } from 'projects/core/src/common/modal/modal.module';
import { FilterdateModule } from '../../modules/filterdate/filterdate.module';
import { SelectfieldcustomModule } from 'projects/core/src/common/selectfieldcustom/selectfieldcustom.module';
import { ToolbarmodelModule } from 'projects/core/src/common/toolbarmodel/toolbarmodel.module';
import { PaginationModule } from 'projects/core/src/common/pagination/pagination.module';
import { WindowModule } from 'projects/core/src/common/window/window.module';
import { WindowlistModule } from 'projects/core/src/common/windowlist/windowlist.module';
import { CustomfieldsModule } from 'projects/core/src/common/customfields/customfields.module';
import { PreviewnotificationModule } from '../../modules/previewnotification/previewnotification.module';
import { FormListComponent } from './form-list/form-list.component';
import { FormsComponent } from './forms.component';
import { FormsSettingsComponent } from './form-settings/forms.component';
import { FormSettingDetailComponent } from './form-settings/form-detail/form-detail.component';
import { SelectTablesModule } from 'projects/core/src/common/tables/tables.module';
import { FieldsModule } from '../../modules/fields/fields.module';


@NgModule({
  declarations: [
    FormsComponent,
    FormListComponent,
    FormDetailComponent,
    FormsSettingsComponent,
    FormSettingDetailComponent
  ],
  exports:[FormsComponent,FormListComponent],
  imports: [
        FormsModule,
        CommonModule,
        FormsValuesRoutingModule,
        PreviewnotificationModule,
        CustomfieldsModule,
        WindowlistModule,
        WindowModule,
        PaginationModule,
        ToolbarmodelModule,
        SelectfieldcustomModule,
        FilterdateModule,
        ModalModule,
        ReferenceModule,
        SelectTablesModule,
        FieldsModule
        

  ]
})
export class FormsValuesModule { }
