import { NgModule } from '@angular/core';
import { CustomfieldsComponent } from './customfields.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
//import { NgxTagsInputModule } from 'ngx-tags-input';
import { FieldsModule } from 'projects/c1-backend/src/app/modules/fields/fields.module';
import { TaxCodeModule } from 'projects/c1-backend/src/app/modules/tax-code/tax-code.module';
import { ImportCustomfieldsComponent } from './import/import.component';


@NgModule({
   declarations: [CustomfieldsComponent,ImportCustomfieldsComponent],
   imports:[
      CommonModule,
      FormsModule,
      //NgxTagsInputModule,
      TaxCodeModule],
   exports: [CustomfieldsComponent,ImportCustomfieldsComponent]   
})
export class CustomfieldsModule{ 

}
