  <div class="modal-header">
    <b>Check-out</b>
    <div class="card-tools mt-2">
        <button type="button" class="btn btn-tool float-right" (click)="closeWindow();" >
            <i class="fas fa-times"></i>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <h5>{{steptitle}}</h5>
    <hr/>

    <!-- PRODOTTI -->
    <div class="form-group mt-1" *ngIf="step==0">
            <h6>Sono stati riconsegnati i seguenti prodotti?</h6>
            <div class="row mt-4">
                <div class="col-sm-4 py-2 text-center" *ngFor="let i of inventoryItems">
                    <img src="{{i.product.coverImage().imagePath}}" style="height:64px" /><br/>
                    <small>{{i.product.name}}</small><span class="badge badge-info ml-2">{{i.sn}}</span><br>
                    <span *ngIf="i.km" class="badge badge-danger mt-1">
                        <i class="fas fa-tachometer-alt mr-2"></i>
                        {{i.km}}
                    </span><br>
                    <small class="text-info mt-1" *ngIf="i.note"><i>{{i.note}}</i><br></small>
                    <small class="badge bg-purple" *ngIf="i.latitude && i.longitude" >
                        <i class="far fa-compass mr-2"></i>
                        {{i.latitude | number : '1.4'}}° ; {{i.longitude | number : '1.4'}}°
                    </small>
                    <div class="row mt-2">
                        <div class="col-12 text-center">
                            <button class="btn btn-sm btn-primary m-1" (click)="openConfirmProduct(i)"><span *ngIf="i['selected']">Dati aggiuntivi</span><span *ngIf="!i['selected']">Conferma</span></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12 text-center">
                    <button class="btn btn-info" (click)="openSales()">Pagare danno/mancata restituzione</button>
                </div>
            </div>
    </div>
    
    <!-- CONFERMA -->
    <div class="form-group row mt-1" *ngIf="step>0">
        <div class="col-12 col-sm-2"></div>
        <div class="col-12 col-sm-8">
            <div class="alert alert-warning text-center">
                <h4>Confermi di voler confermare il check-out <i class="fas fa-question"></i></h4>
                <p>Proseguendo verranno caricati i prodotti nel magazzino corrente.</p>
                <textarea row="3" type="text" class="form-control" placeholder="inserisci della note ..." name="note" [(ngModel)]="booking.note"></textarea>
                <!-- <div class="text-center">
                    <button class="btn btn-success mt-2" (click)="confirm()">Sì</button>
                </div> -->
            </div>
        </div>
        <div class="col-12 col-sm-2"></div>
    </div>
  </div>

  <div class="card-footer">
    <button *ngIf="showPrevious" class="btn btn-secondary float-left" (click)="goPrevious()"><i class="fa fa-chevron-left mr-2"></i>Indietro <span class="d-none d-sm-inline-block">(F2)</span></button>
    <button  *ngIf="showNext" class="btn btn-success float-right" (click)="goNext()">{{NextTitleBtn}} <span class="d-none d-sm-inline-block">(F4)</span><i class="fa fa-chevron-right ml-2"></i></button>
  </div>


<ng-template let-modal #confirmProductModal>
    <div class="modal-header bg-light">
        <b>Dati aggiuntivi</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="modal.close('cancel')" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <div class="form-group" *ngIf="selectedProduct.product.id_category==id_category_log">
                    <label>Km totali</label>
                    <div class="input-group">
                        <input class="form-control"  name="km" [(ngModel)]="selectedProduct.km">
                        <div class="input-group-append">
                            <span *ngIf="selectedProduct.km-selectedProduct.last_km" class="input-group-text">Percorsi: {{selectedProduct.km-selectedProduct.last_km}} km</span>
                        </div>
                    </div>
                    <i *ngIf="selectedProduct.last_km"><small>Ultimo rilevamento: <b>{{selectedProduct.last_km}} km</b> / Km percorsi stimati: <b>{{selectedProduct.km_route_estimated}}</b> km</small></i>
                </div>
                <div class="form-group">
                    <label>Note</label>
                    <input class="form-control" name="note" [(ngModel)]="selectedProduct.note">
                </div>
                <div class="form-group">
                    <label>Latitudine</label>
                    <input class="form-control" name="latitude" [(ngModel)]="selectedProduct.latitude">
                </div>
                <div class="form-group">
                    <label>Longitudine</label>
                    <input class="form-control" name="longitude" [(ngModel)]="selectedProduct.longitude">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer text-center">
        <button class="btn btn-warning" (click)="getCoordinate()"><i class="far fa-compass mr-2"></i>Ottieni coordinate</button>
        <button class="btn btn-success" (click)="confirmProduct()">Ok</button>
    </div>
</ng-template>