import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivitiesComponent } from './activities.component';
import { ActivitiesDetailsComponent } from './activities-details/activities-details.component';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'projects/core/src/common/pagination/pagination.module';
import { WindowlistModule } from 'projects/core/src/common/windowlist/windowlist.module';
import { WindowModule } from 'projects/core/src/common/window/window.module';
import { ReferenceModule } from '../../modules/reference/reference.module';
import { ActivitiesRoutingModule } from './activities-routing.module';
import { FilterdateModule } from '../../modules/filterdate/filterdate.module';
import { ToolbarmodelModule } from 'projects/core/src/common/toolbarmodel/toolbarmodel.module';
import { CustomfieldsModule } from 'projects/core/src/common/customfields/customfields.module';
import { ActivitiesListComponent } from './list/list.component';
import { ActivitiesReschedulePaymentComponent } from './reschedule-payment/reschedule-payment.component';
import { TypeModule } from '../../modules/type/type.module';
import { AnalyticsComponent } from './analytics/analytics.component';
import { NgChartsModule } from 'ng2-charts';
import { CitiesModule } from '../../modules/cities/cities.module';
import { CashFlowModule } from '../cash-flow/cash-flow.module';
import { SelectfieldcustomModule } from 'projects/core/src/common/selectfieldcustom/selectfieldcustom.module';
import { ItemuserinfoModule } from '../../modules/itemuserinfo/itemuserinfo.module';
import { ConfirmMissingValuesComponent } from './missing-values/confirm-missing-values/confirm-missing-values.component';
import { MissingValuesComponent } from './missing-values/missing-values.component';
import { UserActionsComponent } from './user-actions/user-actions.component';
import { TaxCodeModule } from '../../modules/tax-code/tax-code.module';
import { SubstitutionComponent } from './user-actions/substitution/substitution.component';
import { RetiredComponent } from './user-actions/retired/retired.component';
import { DocumentsModule } from '../documents/documents.module';
import { Globals } from 'projects/core/src/globals';
import { FiltercontainerModule } from '../../modules/filtercontainer/filtercontainer.module';
import { ActivitiesBatchComponent } from './batch/batch.component';
import { ActivitiesLogsComponent } from './logs/logs.component';
import { ActivitiesCalendarComponent } from './calendar/calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { WindowlistbuttonsModule } from 'projects/core/src/common/windowlistbuttons/windowlistbuttons.module';
import { TimesheetModule } from '../../modules/timesheet/timesheet.module';


@NgModule({
  declarations: [ActivitiesComponent,ActivitiesDetailsComponent, ActivitiesListComponent, ActivitiesReschedulePaymentComponent, AnalyticsComponent,MissingValuesComponent, ConfirmMissingValuesComponent, UserActionsComponent,SubstitutionComponent,RetiredComponent,ActivitiesBatchComponent,ActivitiesLogsComponent,ActivitiesCalendarComponent],
  exports:[ActivitiesComponent,ActivitiesDetailsComponent,ActivitiesListComponent,ActivitiesReschedulePaymentComponent,MissingValuesComponent, ConfirmMissingValuesComponent,SubstitutionComponent,RetiredComponent,ActivitiesLogsComponent,ActivitiesCalendarComponent],
  imports: [
    CommonModule,
    FormsModule,
    WindowModule,
    WindowlistModule,
    PaginationModule,
    ReferenceModule,
    CustomfieldsModule,
    ActivitiesRoutingModule,
    FilterdateModule,
    FiltercontainerModule,
    ToolbarmodelModule,
    CashFlowModule,
    DocumentsModule,
    TypeModule,
    NgChartsModule,
    CitiesModule,
    SelectfieldcustomModule,
    ItemuserinfoModule,
    TaxCodeModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    WindowlistbuttonsModule,
    TimesheetModule
    
  ]
})
export class ActivitiesModule { 
  constructor(){
    Globals.parameters.loadModulesEvent.subscribe((result)=>{
      Globals.parameters.createParam(
          "activities",
          "id_type_reschedule_payment",
          "Tipologia Attività per pagamento rischedulato",
          "number",
          "16"
      );

      Globals.parameters.createParam(
        "activities",
        "default_view",
        "Visualizzazione di default",
        "select",
        [
            {"label":"Lista","value":1},
            {"label":"Mappa","value":2},
            {"label":"Ibrido","value":3}
        ],1
    );
   
    Globals.parameters.createParam(
          "activities",
          "id_shop_inventory_retired",
          "ID Esercizio di default in fase di ritiro prodotto",
          "number"
      );
   

      Globals.parameters.createParam(
          "activities",
          "id_product_type_retired",
          "ID tipologia del prodotto ritirato",
          "type"
      );


      Globals.parameters.createParam(
        "activities",
        "enable_notify_after_created",
        "ID Notifica dopo aver creato l'attività",
        "notification"
    );



      Globals.parameters.createParam(
        "activities",
        "default_modeview",
        "Modalità di visualizzazione di default",
        "select",
        [
            {"label":"Tutti","value":0},
            {"label":"Solo quelli dell'anno corrente","value":1},
            {"label":"Solo gli ultimi 365 giorni","value":2}
        ]
    );


    Globals.parameters.createParam(
      "activities",
      "autocreate_activities",
      "Crea una nuova attività alla chiusura",
      "table",
     [
      {"name":"id_type_old","label":"Tipologia dell'attività corrente"},
      {"name":"id_type_new","label":"Tipologia nuova attività"},
      {"name":"date_new","label":"Nuova data"},
      {"name":"actions","label":"Azioni"}
   
      
     ]
    );

    Globals.permissions.addPermissionRule("activities","edit","Modifica");
    Globals.permissions.addPermissionRule("activities","save","Salvataggio");
    Globals.permissions.addPermissionRule("activities","delete","Eliminazione");
    Globals.permissions.addPermissionRule("activities","showPayments","Mostra pagamenti");


  });

    
    
    
  }
}
