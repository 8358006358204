import { Injectable } from '@angular/core';
import {Inventories, InventoriesDetails, InventoriesTotal,SNAvaible} from '../db/inventories';
import {WSService} from 'projects/core/src/include/service'
import { Observable } from 'rxjs';
import {Globals} from 'projects/core/src/globals';
import {Filter,FilterAdvanced,Ordering} from 'projects/core/src/include/structures';
import {ProductsService} from './products.service';
import {LoadserialsComponent} from '../views/inventories/loadserials/loadserials.component';
import { Product } from '../db/product';
import { MoveinventoryComponent } from '../modules/moveinventory/moveinventory.component';
import { ToastMode } from 'projects/core/src/include/structures';
import { InventoriesModule } from '../views/inventories/inventories.module';


@Injectable({
  providedIn: 'root'
})



export class InventoriesDetailsService extends WSService<InventoriesDetails> {

  constructor(private productsService:ProductsService) {super("inventoriesdetails") }

  getTotals(filters:Filter[],ordering:Ordering[],advancedFilter:FilterAdvanced[]):Observable<InventoriesTotal>{
    return Globals.ws.requestUrl("inventoriesdetails","getTotals",this.getSQLWhere(filters,ordering,advancedFilter));
  }
  /*
  getInventories():Observable<Inventories[]>{
    
    return Globals.ws.requestUrl<Inventories[]>("inventories","getItems",[],true,0,100000);
  }
  */

  getProductAvaible(filters:Filter[],ordering:Ordering[],paging_start=0,paging_count=10):Observable<Product[]>{
    return Globals.ws.requestUrl<Product[]>("inventories","getProductAvaible",this.getSQLWhere(filters,ordering),false,paging_start,paging_count);
  }

  getProductInventory(id_inventory,ids_category=[],id_type="",only_in_stock=true,pagination=false,paging_count=99999999,paging_start=0):Observable<any>{
    return Globals.ws.requestUrl("inventories","getProductInventory",[id_inventory,ids_category.join(","),id_type,only_in_stock],pagination?false:true,paging_start,paging_count);
    
  }

  

  getSNAvaible(id_product:number):Observable<SNAvaible[]>{
    return Globals.ws.requestUrl<SNAvaible[]>("inventories","getSNAvaible",[id_product.toString()]);
    
  }

  getInventoryDestination(id_product_category):Observable<any>{
    return Globals.ws.requestUrl<SNAvaible[]>("inventoriesdetails","getInventoryDestination",[id_product_category]);
    
  }

  moveInventory(src_id_inventorydetail:number,dest_id_inventory:number,dest_quantity:number):Observable<boolean>{
    return Globals.ws.requestUrl<boolean>("inventoriesdetails","move",[src_id_inventorydetail.toString(),dest_id_inventory.toString(),dest_quantity.toString()]);
   
  }

  moveInventorySerial(serials:string,src_id_inventory:number,dest_id_inventory:number,):Observable<boolean>{
    return Globals.ws.requestUrl<boolean>("inventoriesdetails","moveSerials",[serials.toString(),src_id_inventory.toString(),dest_id_inventory.toString()]);
   
  }

  move(id_product,oncomplete:Function){
    Globals.modal.showModal(MoveinventoryComponent,[{"name":"id_product","value":id_product}],(instance)=>{
      if(oncomplete)
          oncomplete();
    });
  }

  checkStock(sn,id_inventory):Observable<any>{
    return Globals.ws.requestUrl<boolean>("inventoriesdetails","checkStock",[sn,id_inventory]);
  
  }
  /*
  move(src_id_record,src_quantity,serials:SNAvaible[]=[],oncomplete:Function,src_id_inventory=0){
    
    for(let i=0;i<serials.length;i++){
      if(serials[i].sn==""){
        serials.splice(i,1);
      }
    }
    
    AppComponent.app.showModal(MoveinventoryComponent,[{"name":"quantity","value":src_quantity},{"name":"serials","value":serials}],(instance)=>{
      if(instance!=null){
        let dest_id_inventory=instance['id_inventory']; //inventario di destinazione
        let dest_quantity=instance['quantity'];
        let serials:SNAvaible[]=instance['serials']; 

        let s=[];
        serials.forEach(element => {
          if(element.selected)
            s.push("'"+element.sn+"'");
        });

        if(serials.length>0){
          this.moveInventorySerial(s.join(","),src_id_inventory,dest_id_inventory).subscribe((result)=>{
            if(oncomplete)
              oncomplete();
          });
        }else{


          this.moveInventory(src_id_record,dest_id_inventory,dest_quantity).subscribe((result)=>{
            if(oncomplete)
              oncomplete();
          });
        }
        
        
      }
    });
  }*/


  recordToLoad:InventoriesDetails;

  save(item:InventoriesDetails,return_id:Function,checkdeadlinesandactions=true,id_inventory=null){
    
    if(item.id==undefined || item.id==0){

      //verifica se il prodotto richiede seriali o meno
      
      this.productsService.getItem(item.id_product).subscribe((product)=>{
        /*
        if(id_inventory==null){
          id_inventory=Globals.user['shop']?Globals.user['shop']['id_inventory']:Globals.parameters.get("inventories").getParam("default_inventory",0);
        }*/


        this.recordToLoad=new InventoriesDetails();
        this.recordToLoad.description=item.description;
        if(this.recordToLoad.description=="")
          this.recordToLoad.description=product.brand+" "+product.name;
        this.recordToLoad.id_product=item.id_product;
        this.recordToLoad.type=item.type;
        this.recordToLoad.netprice=item.netprice;
        this.recordToLoad.tax=item.tax;
        this.recordToLoad.discount1=item.discount1;
        this.recordToLoad.discount2=item.discount2;
        this.recordToLoad.discount3=item.discount3;
        this.recordToLoad.id_inventory=item.id_inventory;
        this.recordToLoad.id_address=item.id_address;
        this.recordToLoad.id_table=item.id_table;
        this.recordToLoad.table=item.table;
        
        //verifica se il prodotto deve avere dei seriali da scegliere (se questo non è definito)
     
        
        if((item.sn==undefined || item.sn=="") && product.has_serials){

        


          Globals.modal.showModal(LoadserialsComponent,[{"name":"type","value":item.type},{"name":"quantity","value":item.quantity},{"name":"id_product","value":product.id},{"name":"snpattern","value":product.snpattern},{"name":"serials","value":product.serials},{"name":"id_inventory","value":id_inventory}],(instance)=>{
            if(instance!=null){
              let records:InventoriesDetails[]=[];
              let serials:SNAvaible[]=instance['serials'];

              for(let s of serials){
                if(s.selected){
                  let r:InventoriesDetails=new InventoriesDetails();
                  r.id_product=this.recordToLoad.id_product;
                  r.description=this.recordToLoad.description;
                  r.quantity=1;
                  r.netprice=this.recordToLoad.netprice;
                  r.tax=this.recordToLoad.tax;
                  r.discount1=this.recordToLoad.discount1;
                  r.discount2=this.recordToLoad.discount2;
                  r.discount3=this.recordToLoad.discount3;
                  
                  r.id_inventory=item.id_inventory;
                  r.sn=s.sn;
                  r.type=this.recordToLoad.type;
                  r.id_address=this.recordToLoad.id_address;
                  records.push(r);
                }
                
              }
              if(records.length>0)
                this.saveMultiple(records,()=>{
                  
                  if(return_id)
                    return_id();
                });

            }else{
              return;
            }
            
          },"md");

        }else{
          this.recordToLoad.sn=item.sn;
          this.recordToLoad.quantity=item.quantity;
          
          super.save.apply(this,[this.recordToLoad,()=>{
            if(return_id)
              return_id();
          }]);
        }
      });
    }
    else{
      super.save.apply(this,[item,return_id,checkdeadlinesandactions]);

    }



  }



  saveMultiple(values:InventoriesDetails[],onComplete:Function=null,forceSubInventory=true){
    Globals.ws.send("inventoriesdetails","multiple",values,[null,forceSubInventory],(result)=>{

      InventoriesModule.savingInventoriesFastEvent.emit();

      
        Globals.message.showToaster("Record salvati correttamente",ToastMode.SUCCESS);
      if(onComplete)
        onComplete(result);
    });
  }


  updateInventoryDetailsDocument(values:InventoriesDetails[],id_document:number,onComplete:Function=null){
    Globals.ws.send("inventoriesdetails","updateInventoryDetailsDocument",values,[id_document.toString()],()=>{
      if(onComplete)
        onComplete();
    });
  }

 

  convertToList(values):InventoriesDetails[]{
    let p:InventoriesDetails=new InventoriesDetails();
    let result:InventoriesDetails[]=[];
    let keys=Object.getOwnPropertyNames(p);
    
    for(let v of values){
      for(let k of keys){
        if(v[k]!=undefined && k!="id")
          p[k]=v[k];
      }
      result.push(p);

    }

    return result;
  }

  generateSerials(id_product,pattern,quantity):Observable<string[]>{
    return Globals.ws.requestUrl<string[]>("inventoriesdetails","generateserials",[id_product,pattern,quantity]);
    
  }
  
  getStockPeriod(id_product):Observable<any>{
    return Globals.ws.requestUrl<any>("inventoriesdetails","getStockPeriod",[id_product]);
  }

  assignReference(ids,id_table,table){
    return Globals.ws.requestUrl<any>("inventoriesdetails","assignReference",[ids,id_table,table]);
 
  }

  
}

