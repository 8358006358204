import { Component, Input, OnInit } from '@angular/core';
import { Booking } from '../../../db/booking';
import { MethodpaymentsService } from 'projects/c1-backend/src/app/services/methodpayments.service';
import { DocumentDetail } from '../../../db/documents';
import { DocumentsTemplateService } from '../../../services/documents-template.service';
import { BookingService } from '../../../services/booking.service';
import { DocumentsService } from '../../../services/documents.service';

@Component({
  selector: 'app-booking-addpayment',
  templateUrl: './addpayment.component.html',
  styleUrls: ['./addpayment.component.css']
})
export class BookingAddPaymentComponent implements OnInit {

  @Input()
  booking:Booking;

  amount:number=0;
  methodpayment:string="Contanti";
  methodpayments=[];
  constructor(
    private methodpaymentsService:MethodpaymentsService,
    private documentTemplateService:DocumentsTemplateService,
    private bookingService:BookingService,
    private documentsService:DocumentsService
  ) { }

  ngOnInit(): void {
    this.methodpaymentsService.getMethods().subscribe((result)=>{
      this.methodpayments=result;
    })

    this.amount=this.booking.total-this.booking.paid;
    this.setStatus();
  }


  confirm(){

    
    let details:DocumentDetail[]=[];
    for(let d of this.booking.details){
      let dd:DocumentDetail=new DocumentDetail();
      dd.id_product=d.id_product;
      dd.product=d.product;
      dd.description=d.product.title?d.product.title:(d.product.brand?d.product.brand+" ":"")+d.product.name;
      let type=this.booking.id_tour==1?"Tour":"Noleggio";
      dd.note=type+" dal "+this.booking.dateStart+" "+this.booking.time_start+" al "+this.booking.dateEnd+" "+this.booking.time_end;
      dd.netprice=d.price*this.booking.duration;
      dd.quantity=d.quantity;
      dd.discount1=d.discount;
      dd.discount2=0;
      dd.discount3=0;
      dd.tax=d.tax;
      details.push(dd);
    }

    let drecord={};
    Object.assign(drecord,this.booking);
    drecord['details']=details;
    drecord['remain']=this.booking.total-this.booking.paid;

    this.documentTemplateService.createOpenDocument("booking",drecord,this.amount,this.methodpayment,this.booking.details,(id)=>{
      if(id){

        //se il pagamento è completato collega tutti i documenti
        if(this.booking.total-this.booking.paid-this.amount<=0){
          this.documentsService.linkDocumentByReference(id,this.booking.id,"booking").subscribe(()=>{

          });
        }
        this.bookingService.save(this.booking,()=>{
          this.close();
        });
      }
      //verifica 
      
    });

    
  }

  

  close(){
    this['modalWindow'].close("success");
  }

  setStatus(){
    if(this.booking.total-this.booking.paid-this.amount<=0){
      this.booking.state=2;
    }else{
      this.booking.state=1;
    }
  }

}
