import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ModalModule } from '../modal/modal.module';
import { QrcodereaderComponent } from './qrcodereader.component';


@NgModule({
   exports: [QrcodereaderComponent],
   declarations: [QrcodereaderComponent],
   imports:[
      CommonModule,
      FormsModule,
      ModalModule,
      ZXingScannerModule   ],
   schemas:[CUSTOM_ELEMENTS_SCHEMA],
   providers: [],
   
})
export class QrcodereaderModule{ }