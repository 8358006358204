<div class="modal-header">
    <b>Documenti presenti sullo SdI</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancel()" >
            <i class="fas fa-times"></i>
        </button>
    </div>
</div>
<div class="modal-body" style="max-height:80vh; overflow-y:auto">
    <div class="row pb-2">
        <div class="col-lg-6 text-lg-right">
            <label>Visualizza tutti i documenti a partire da 30 giorni prima rispetto alla data</label>
        </div>
        <div class="col-lg-2">
            <div class="input-group input-group-sm">
                <input type="date" class="form-control form-control-sm" [(ngModel)]="date_search" (change)="getList()">
                <div class="input-group-append">
                    <button class="btn btn-default text-primary" (click)="getList()"><i class="fa fa-search"></i></button>    
                </div>
            </div>
        </div>
        <div class="col-4">
        </div>
    </div>
    <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
        <i class="icon fas fa-info-circle mr-2"></i><i>Nessun documento trovato!</i>
    </div>
    <form *ngIf="list && list.length>0" [formGroup]="form">
        <table class="table table-sm">
            <thead>
                <th>
                    <input #checkboxselectall type="checkbox" (change)="onChangeAll($event.target.checked)" />
                </th>
                <th>ID</th>
                <th>Data</th>
                <th>Riferimento</th>
                <th>Fornitore</th>
                <th class="text-right">Importo</th>
                <th class="text-center">Stato</th>
                <th></th>
            </thead>
            <tbody>
                <tr *ngFor="let r of list">
                    <td>
                        <input type="checkbox" #checkrecord id_record="{{r.id_download}}" (change)="onChange(r.id_download, $event.target.checked)" [checked]="checkedAll"/>
                    </td>
                    <td><small>{{r.identificativoSdI}}</small></td>
                    <td>{{r.dataDocumento}}</td>
                    <td>{{r.numero}}</td>
                    <td>{{r.fornitore}}</td>
                    <td class="text-right">{{r.totaleDocumento | currency:" &euro;"}}</td>
                    <td class="text-center">
                        <span *ngIf="r.state==0" class="badge border">da scaricare</span>
                        <span *ngIf="r.state==1" class="badge badge-success">scaricato</span>
                    </td>
                    <td><button type="button" class="btn btn-primary btn-xs float-right" (click)="downloadDocument(r.id_download);" title="Scarica il documento"><i class="fa fa-file-download"></i></button></td>
                </tr>
            </tbody>
        </table>
    </form>
</div>
<div class="modal-footer">
    <button type="button" [disabled]="form.value.id.length==0" class="btn btn-primary" (click)="downloadDocumentSelected()"><i class="fas fa-cloud-download-alt mr-2"></i>Scarica i selezionati</button>
    <button type="button" class="btn btn-secondary" (click)="cancel()">Chiudi</button>
</div>