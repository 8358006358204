<div class="modal-header">
    Impostazioni
</div>
<div class="modal-body">

    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
            <a class="nav-link" [class.active]="tab==1" (click)="tab=1"  title="Fasi">
                <i class="fas fa-list-ol"></i><small class="d-none d-sm-inline-block ml-2"></small><b class="d-none d-sm-inline-block">Fasi</b></a>
        </li>

        <li class="nav-item">
            <a class="nav-link" [class.active]="tab==2" (click)="tab=2"  title="Fasi">
                <i class="fas fa-list-ol"></i><small class="d-none d-sm-inline-block ml-2"></small><b class="d-none d-sm-inline-block">Riepilogo</b></a>
        </li>
    </ul>

    <div class="tab-content" id="myTabContent" >
        <div class="tab-pane show active" *ngIf="tab==1">
            <app-dossierphases></app-dossierphases>
        </div>
        <div class="tab-pane show active" *ngIf="tab==2">
            <app-dossieroverviews></app-dossieroverviews>
        </div>
    </div>

    

</div>

<div class="modal-footer">
    <button class="btn btn-sm btn-secondary" (click)="close()">Chiudi</button>
</div>
