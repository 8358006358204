export interface Condition{
    field:string;
    value:string;
    condition:string;
}

export class NotificationMessages{
    id:number;
    message:string;
    gateway:string;
    id_type:number;
    enable:number;
}

export class Notification{
    id:number;
    name:string;
    conditions:string;
    conditionsArray:Condition[];
    gateways:NotificationMessages[];
    status:number;
    id_user:number;

}

export interface NotificationLog{
    id:number;
    message:string;
    gateway:string;
    id_address:number;
    preview:string;
    contact:string;
    username:string;
}