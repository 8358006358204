<div class="modal-header">
    <b>Criteri di ricera salvati</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="close()" >
            <i class="fas fa-times"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <div *ngIf="!templates || templates.length==0" class="m-5 text-center text-secondary">
        <i class="icon fas fa-info-circle mr-2"></i><i>Nessun criterio di ricerca salvato!</i>
    </div>
    <ul class="list-group">
        <li class="list-group-item" *ngFor="let t of templates">
            <div class="row">
                <div class="col-10">
                    {{t.name}}
                </div>
                <div class="col-2 text-right">
                    <button class="btn btn-xs btn-secondary" (click)="load(t)"><i class="fa fa-search"></i></button>
                    <button class="btn btn-xs btn-danger" (click)="delete(t)"><i class="fa fa-trash"></i></button>
                </div>
            </div>
        </li>
    </ul>   
</div>
<div class="modal-footer">
    <button class="btn btn-sm btn-success" (click)="save();"><i class="fa fa-save mr-2"></i>Salva criterio di ricerca corrente</button>
    <button class="btn btn-sm btn-secondary" (click)="close()">Chiudi</button>
</div>
