import { Component, Input, OnInit } from '@angular/core';
import { ActivitiesService } from '../../../services/activities.service';
import { TypesService } from '../../../services/types.service';
import { UsersService } from 'projects/ratingo-backend/src/app/services/users.service';

@Component({
  selector: 'app-activitiesbatch',
  templateUrl: './batch.component.html',
  styleUrl: './batch.component.css'
})
export class ActivitiesBatchComponent implements OnInit {
  
  @Input()
  list:any=[];
  types=[];
  users=[];

  constructor(
    private activitiesService:ActivitiesService,
    private typesService:TypesService,
    private usersService:UsersService,
    
    ){

  }

  ngOnInit(): void {
    this.typesService.getTypes("activities").subscribe((result)=>{
      this.types=result;
    });
    this.usersService.getUsers().subscribe((result)=>{
      this.users=result;
    });

    //verifica se le attività da creare sono state già create
    let where=[];
    for(let i=0;i<this.list.length;i++){
      where.push("(a.date='"+this.list[i].date+"' AND a.id_type="+this.list[i].id_type+" AND a.id_table="+this.list[i].id_table+" AND a.table='"+this.list[i].table+"' AND a.status=1)");
    }
    this.activitiesService.checkItems(where.join(" OR ")).subscribe((result)=>{
      for(let j=0;j<this.list.length;j++){
        let r=this.list[j];
        this.list[j]['exist']=false;
        for(let i=0;i<result.length;i++){
          let item=result[i];
          if(item.date==r.date && item.id_type==r.id_type && item.table==r.table){
            this.list[j]['exist']=true;
            this.list[j]['id']=item.id;
          }
        }

        
      }
    });


  }

  confirm(){

    //salva le attività
    this.activitiesService.saveMultiple(this.list,()=>{
      this.close();
    });

   

  }
  close(){
    this['modalWindow'].close();
  }
}
