<div class="modal-header">   
    <b>Sostituzione prodotto seriale</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancel()" >
            <i class="fas fa-times"></i>
        </button>
    </div>
</div>
<div class="modal-body">    
    <table class="table">
        <thead>
            <th>Da ritirare</th>
            <th>Sostitutivo</th>
            <th></th>
        </thead>
        <tbody *ngFor="let p of activity.user_action['products']">
            <tr>
                <td>
                    <input type="text" class="form-control form-control-sm" [(ngModel)]="p.oldproduct">
                </td>
                <td>
                    <div class="input-group input-group-sm">
                        <input type="text" class="form-control form-control-sm"  placeholder="indicare il numero seriale del nuovo prodotto..." [(ngModel)]="p.newproduct">
                        <div class="input-group-append">
                            <button class="btn btn-sm btn-default" (click)="searchInventory(p);"><i class="fa fa-search"></i>&nbsp;Cerca nell'inventario</button>
                        </div>
                    </div>       
                </td>
                <td>
                    <button class="btn btn-sm btn-danger" (click)="removeProduct(p)"><i class="fa fa-trash"></i></button>
                </td>
            </tr>
        </tbody>        
    </table>
    <button class="btn btn-sm btn-block btn-success" (click)="addProduct()"><i class="fa fa-plus mr-2"></i>Aggiungi</button>    
    <div class="form-group">
        <label>Note</label>
        <input type="text" class="form-control form-control-sm" [(ngModel)]="activity.user_action.note" >
    </div>   
</div>
<div class="modal-footer" *ngIf="mode=='modal'">
    <button class="btn btn-secondary" (click)="cancel()">Annulla</button>
    <button class="btn btn-success" (click)="confirm()">Conferma</button>
</div>


<ng-template #modalListInventory>
    <div class="modal-header">        
        <b>Magazzino</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="closeModalInventory()" >
            <i class="fas fa-times"></i>
        </button>
    </div>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="listInventoryStep==1">
            <table class="table table-sm table-striped">
                <thead>
                    <th>Marca e Modello</th>
                    <th>SN</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr *ngFor="let p of products">
                        <td>{{p.brand}} {{p.model}}</td>
                        <td>
                            <div *ngFor="let s of p.serials" class="badge badge-info">{{s.sn}}</div>
                        </td>
                        <td><button class="btn btn-info btn-sm" (click)="selectProduct(p)">Seleziona</button></td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
        <ng-container *ngIf="listInventoryStep==2">
            <ul class="list-group">
                <li class="list-group-item" *ngFor="let s of serials">
                    {{s.sn}}
                    <button class="btn btn-info btn-sm float-right" (click)="selectSn(s.sn)">Seleziona</button>
                </li>
            </ul>
        </ng-container>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary btn-sm" (click)="closeModalInventory()">Chiudi</button>
    </div>
</ng-template>