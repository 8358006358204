<app-window>
    <form role="form" id="detailForm">
        <div class="row">
            <div class="col-sm-2">
                <div class="form-group">
                    <label>Data</label>
                    <div class="input-group" >
                        <input class="form-control form-control-sm" type="date"  name="date"  [(ngModel)]="record.date"  />
                    </div>
                </div>
            </div>

            <div class="col-sm-2">
                <div class="form-group">
                    <label>SN</label>
                    <div class="input-group" >
                        <input class="form-control form-control-sm" type="text"  name="sn"  [(ngModel)]="record.sn"  />
                    </div>
                </div>
            </div>


        </div>

        <div class="row">
            <div class="col-sm-2">
                <div class="form-group">
                    <label>KM</label>
                    <div class="input-group input-group-sm" >
                        <input class="form-control form-control-sm" type="number"  name="km"  [(ngModel)]="record.km"  />
                    </div>
                </div>
            </div>

            <div class="col-sm-4">
                <div class="form-group">
                    <label>Rifornimento </label>
                    <div class="input-group input-group-sm" >
                        <input class="form-control form-control-sm" type="text"  name="refueling"  [(ngModel)]="record.refueling"  />
                        <div class="input-group-append">
                            <div class="input-group-text">Litri</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-4">
                <div class="form-group">
                    <label>Costo </label>
                    <div class="input-group input-group-sm" >
                        <input class="form-control form-control-sm" type="text"  name="price"  [(ngModel)]="record.price"  />
                        <div class="input-group-append">
                            <div class="input-group-text">&euro;</div>
                        </div>
                    </div>
                </div>
            </div>

            
        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group">
                    <label>Latitudine </label>
                    <div class="input-group input-group-sm" >
                        <input class="form-control form-control-sm" type="text"  name="latitude"  [(ngModel)]="record.latitude"  />
                       
                    </div>
                </div>
            </div>

            <div class="col-sm-4">
                <div class="form-group">
                    <label>Longitudine </label>
                    <div class="input-group input-group-sm" >
                        <input class="form-control form-control-sm" type="text"  name="longitude"  [(ngModel)]="record.longitude"  />
                       
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-sm-4">
                <div class="form-group ">
                    <label>Operatore</label>
                    <div class="input-group input-group-sm">
                        <select class="form-control form-control-sm" [(ngModel)]="record.id_user" name="id_user" #id_user="ngModel" required>
                            <option *ngFor="let t of users" [ngValue]="t.id">{{t.username}}</option>
                        </select>
                        <div *ngIf="id_user.invalid && (id_user.dirty || id_user.touched)" class="alert alert-danger">
                            <div *ngIf="id_user.errors.required">Campo obbligatorio.</div>
                        </div>
                        <div class="input-group-append">
                            <select class="form-control form-control-sm" name="created_from" #created_from="ngModel" [(ngModel)]="record.created_from">
                                <option *ngFor="let s of shops" [ngValue]="s.id">{{s.name}}</option>
                            </select>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </form>

    <app-toolbarmodel [model]="this"></app-toolbarmodel>
</app-window>