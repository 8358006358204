import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModelList } from '../../include/modellist'
import { UntypedFormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import {ModulesService} from '../../lib/modules.service';
import { OrderingMode,Filter, FilterMode, User, ToastMode, Notification } from '../../include/structures';
import { Extension } from '../../include/parameters';
import { Globals } from '../../globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TypesSettingsComponent } from '../types/types.component';
import { NotificationComponent } from '../notification/notification.component';
import { SystemService } from '../../lib/system.service';
import { TypesService } from 'projects/c1-backend/src/app/services/types.service';
import { Type } from 'projects/c1-backend/src/app/db/type';
import { NotificationService } from '../../lib/notification.service';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.sass']
})
export class ModulesComponent extends ModelList<Extension> implements OnInit {

  @ViewChild("listModules")
  listModules:TemplateRef<any>;
  listModulesRef;

  mlist=[];

  filter_search:Filter=new Filter();

  selectedModule:Extension;

  json_params=[];
  enable_edit_params=false;

  
  constructor(
    private fb1: UntypedFormBuilder,
    private moduleService: ModulesService,
    private modalService:NgbModal,
    private notificationService:NotificationService,
    private systemService:SystemService,
    private typesService:TypesService,
    private http:HttpClient
  ) {
    super(moduleService,fb1);
   }

   get user(): User {
    return Globals.user;    
   }

   types:Type[]=[];
  notifications:Notification[]=[];

  ngOnInit(): void {
    this.title='Moduli';
    super.ngOnInit.apply(this, arguments); 
    this.ordering.field="ordering";
    this.ordering.mode=OrderingMode.ascendent;

    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("m.label");
    this.filter_search.fields.push("m.modulename");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.getItems();

    
  }


  setRule(record:Extension){
    
    this.selectedModule=Object.assign({}, record);
    

    try{
      this.selectedModule.permissions=JSON.parse(this.selectedModule.permissions);
    }catch{
      this.selectedModule.permissions=[];
    }


    for(let m of Globals.parameters.modules){
      if(m.modulename==record.modulename){
        this.selectedModule.params=m.params;
      }
    }

    this.typesService.getTypes(this.selectedModule.modulename).subscribe((items)=>{
      this.types=items;
    });

    this.notificationService.getNotifications(this.selectedModule.modulename).subscribe((items)=>{
      this.notifications=items;
    })

    /*
    try{
      this.selectedModule.params=JSON.parse(this.selectedModule.params);
    }catch{
      this.selectedModule.params=[];
    }*/
    
    this.json_params=this.selectedModule.params;


  }

  
  confirmRule():void{
    
    this.selectedModule.params=JSON.stringify(this.selectedModule.params);

    this.moduleService.save(this.selectedModule,(id)=>{
      this.selectedModule={} as Extension;
      this.getItems();
      Globals.parameters.loadModules(()=>{});
    });
  }

  addModule(){
    let r:Extension={} as Extension;
    r.status=1;

    
    
    this.selectedModule=r;
  }


  addTableRecord(param){
    let r={} as Object;
    for(let f of param.fields){
      r[f.name]="";
    }
    if(!param.value)
      param.value=[];
    param.value.push(r);
    
  }

  removeTableRecord(param,value){
    for(let i=0;i<param.value.length;i++){
      if(param.value[i]==value){
        param.value.splice(i,1);
        break;
      }
    }
  }


  checkPermissions(role){

    if(this.selectedModule && this.selectedModule.permissions)
      return this.selectedModule.permissions.indexOf(role)>-1;
    
    return false;
  }


  setPermissions(role){
    let p=this.selectedModule.permissions.indexOf(role);
    if(p==-1){
      this.selectedModule.permissions.push(role);
    }else{
      this.selectedModule.permissions.splice(p,1);
    }
  }


  add(){    
    this.moduleService.getModulesAvailable().subscribe((result)=>{
      this.mlist=result;
      this.listModulesRef=this.modalService.open(this.listModules);
    });    
  }


  closeListModules(){
    this.listModulesRef.close("success");
  }


  installModule(module){
    if(module['dependencies'] && module['dependencies'].length>0){
      module['dep']=[];
      for (let modulename of module['dependencies']){
        for(let m of this.mlist){
          if(m.modulename==modulename){
            module['dep'].push(m);
          }
        }
      }
    }

    this.moduleService.install(module,()=>{
      this.getItems();
      this.closeListModules();
      Globals.parameters.loadModules(()=>{});
    })
  }


  setDefaultModule(r){
    this.moduleService.setDefaultModule(r.id).subscribe(()=>{
      this.getItems();
    });
  }


  setStatusModule(r){
    r.status=r.status==1?2:1;

    this.moduleService.setEnableModule(r.modulename,r.status).subscribe(()=>{
      this.getItems();
      Globals.parameters.loadModules(()=>{});
    });
    
  }


  searchType(field){
    Globals.modal.showModal(TypesSettingsComponent,[{"name":"mode","value":"modal"}],(instance)=>{
      if(instance){
        let record=instance['recordSelected'];
        field.value=record.id;
      }
    });
  }


  searchNotification(field){
    Globals.modal.showModal(NotificationComponent,[{"name":"mode","value":"modal"}],(instance)=>{
      if(instance){
        let record=instance['recordSelected'];
        field.value=record.id;
      }
    });
  }


  generateManifest(){
      this.systemService.updateManifest(this.selectedModule.table).subscribe(()=>{
        Globals.message.showToaster("Manifest generato",ToastMode.INFO);
      })
  }


  updateDBModule(){
    this.systemService.updateDBModule(this.selectedModule.table).subscribe(()=>{
      Globals.message.showToaster("Database aggiornato",ToastMode.INFO);
    })
  }


  addNotificationRule(param){
    if(!Array.isArray(param.value))
      param.value=[];

    param.value.push({"type":0,"notification":0,"days":0,"condition":""});
  }

  
  deleteNotificationRule(param,r){
    for(let i=0;i<param.value.length;i++){
      if(param.value[i]==r){
        param.value.splice(i,1);
        return;
      }
    }
  }

}
