import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'projects/core/src/authGuard';
import { FormsComponent } from './forms.component';

const routes: Routes = [
  { path: '', component: FormsComponent,canActivate:[AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FormsValuesRoutingModule { }
