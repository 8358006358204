import { Component, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { Model } from '../../include/model';
import { User } from '../../include/structures';
import { UserService } from '../../lib/user.service';

@Component({
  selector: 'app-toolbarmodel',
  templateUrl: './toolbarmodel.component.html',
  styleUrls: ['./toolbarmodel.component.css']
})
export class ToolbarmodelComponent implements OnInit,OnChanges {

  user_created:User;
  user_lastupdate:User;

  @HostListener('window:keydown', ['$event'])
    onKeyDown(event) {
      if(event.keyCode==119){ //F8
        if(!this.readonly)
          this.save();
      }

      
    }

  get readonly(){
    return Globals.readonly;
  }


  get info_created(){
    let message="creato";
    if(this.user_created){
      message=message+" da "+this.user_created.name;
    }
    if(this.model.record.created){
      message=message+" il "+this.model.record.created;
    }

    return message;
  }

  get is_created(){
    let result=false;
    if(this.user_created)
      result=true;

    if(this.model.record.created)
      result=true;

    return result;
  }


  get info_lastupdate(){
    let message="modificato";
    if(this.user_lastupdate){
      message=message+" da "+this.user_lastupdate.name;
    }
    if(this.model.record.lastupdate){
      message=message+" il "+this.model.record.lastupdate;
    }

    return message;
  }

  get is_lastupdate(){
    let result=false;
    if(this.user_lastupdate)
      result=true;

    if(this.model.record.lastupdate)
      result=true;

    return result;
  }

  @Input()
  model:Model<any>;

  @Input()
  showSave=true;

  @Input()
  showSaveAndClose=false;

  @Input()
  showSaveAndNew=true;

  @Input()
  showSaveAndPrint=true;

  

  
  get id(){
    return this.model.id;
  }

  get ShowSaveAndPrint(){
    if(this.model.templates!=null && this.model.templates.length>0){
       return true;
    }

    return false;
    
  }

  
  constructor(
    private userService:UserService
  ) { }

  ngOnInit(): void {
    if(this.model.mode=="modal"){
      //this.showSaveAndClose=false;
      this.showSaveAndNew=false;
    }


    if(!Globals.permissions.checkPermission(this.model.modulename,"save")){
      this.showSave=false;
      this.showSaveAndPrint=false;
      this.showSaveAndClose=false;
      
    }


  }

  ngOnChanges():void{
    
    setTimeout(()=>{
      if(this.model.record)
        this.getUsers();
    },5000)
    
  }

  get field_required(){
    return this.model.field_required;
  }

  get disable(){
    return this.model.disable();
  }

  disabledescription(text){

    const error=this.model.disabledescription();

    if(error!="")
      return error;
    else
      return text;
    
  }



  onBack(){
    //se in modalità modal chiudi la finestra
    
      this.model.onBack();
    
  }

  saveAndNew(){
    this.model.saveAndNew();
  }
  saveAndClose(){
    this.model.saveAndClose();
  }
  save(){
    if(this.showSaveAndClose){
      this.model.save();
    }else{
      this.model.saveAndClose();
    }
    
    /*if(this.model.mode=="modal")
      this.model.saveAndClose();
    else*/
     // this.model.save();
  }


  saveAndPrint(){
    this.model.afterSave=()=>{
      this.model.export();
    };

    this.save();


  }


  getUsers(){
    if(this.model.record.created_by){
      this.userService.getUserById(this.model.record.created_by).subscribe((item)=>{
        this.user_created=item;
      });


      /*this.userService.getItem(this.model.record.created_by,false).subscribe((item)=>{
        this.user_created=item;
      });*/
    }
    if(this.model.record.lastupdate_by){

      this.userService.getUserById(this.model.record.lastupdate_by).subscribe((item)=>{
        this.user_lastupdate=item;
      });
      /*
      this.userService.getItem(this.model.record.lastupdate_by,false).subscribe((item)=>{
        this.user_lastupdate=item;
      });
      */
    }
  }

}
