<div class="modal-header">
    <b>Notifica</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="close('');" >
            <i class="fas fa-times"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <!-- Seleziona del modello-->
    <ng-container *ngIf="step==0">
        <ng-container *ngIf="loading">
            <div class="m-5 text-center text-secondary">
                <i class="icon fas fa-info-circle mr-2"></i><i>Caricamento del modello in corso ...</i>                
            </div>
        </ng-container>
        <ng-container *ngIf="!loading">
            <div *ngIf="!notifications || notifications.length==0">
                <div class="m-5 text-center">
                    <i class="fas fa-exclamation-circle mr-2"></i>Nessun modello di notifica è presente per questo elemento.
                </div>
            </div>
            <div class="" *ngIf="id_notification==0">
                <ng-container *ngFor="let n of notifications">
                    <button class="btn btn-primary btn-block" (click)="selectedNotify=n; getNotification()">{{n.name}}</button>
                </ng-container>
            </div>
        </ng-container>
    </ng-container >
    <!-- Compilazione modello -->
    <ng-container *ngIf="step==1">
    </ng-container>
    <div class="row" *ngIf="notifications && notifications.length>0">
        <div class="col-12">
            <ul class="nav nav-tabs auto">
                <ng-container *ngFor="let g of gateway_notifications;let i=index">
                    <li class="nav-item">
                        <a class="nav-link pointer" [class.active]="tab==i" (click)="tab=i">{{g.gateway}}</a>
                    </li>
                </ng-container>
            </ul>
            <div class="tab-content" id="myTab2Content">
                <ng-container *ngFor="let g of gateway_notifications;let i=index">
                    <div class="tab-pane pt-3 fade " [class.active]="tab==i" [class.show]="tab==i">
                        <app-gateway [gateway]="g" [contacts]="contacts" [record]="record"></app-gateway>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-secondary" (click)="close('');">Annulla</button>
    <button *ngIf="step==1" (click)="close('success');" class="btn btn-success"><i class="far fa-paper-plane mr-2"></i>Invia</button>
</div>