<div class="modal-header">
    <b><i class="fas fa-server mr-2"></i>Seleziona server</b>
    <div class="float-right" *ngIf="timer>0">
        <small class="mr-3">(tra <b class="blink">{{timer}} sec</b> avvio automatico)</small>
        <button type="button" class="btn btn-sm btn-danger px-5" (click)="stopTimer()">STOP</button>
    </div>
</div>
<div class="modal-body pb-0">
    <ul class="list-group">
        <li class="list-group-item p-0" *ngFor="let c of configs">
            <div class="row border-bottom p-2">
                <div class="col-lg-4">
                    <span class="text-primary text-lg">{{c['name']}}</span>
                </div>
                <div class="col-lg-8">
                    <ng-container *ngIf="isArray(c['serverUrl'])">
                        <ng-container  *ngFor="let s of c['serverUrl'];let i=index">
                            <input type="radio" #serverUrl name="serverUrl" [(ngModel)]="c['selectedServerUrl']" (change)="select(c)" value="{{s}}" class="pointer mr-2"/>
                            <span>{{s}}<div class="float-right badge badge-warning" *ngIf="serverUrl.checked">automatico al prossimo avvio</div></span><br>
                        </ng-container>
                    </ng-container>
                    <small *ngIf="!isArray(c['serverUrl'])">{{c['serverUrl']}}</small>
                </div>               
            </div>
        </li>
    </ul>
</div>