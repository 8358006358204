import { Component, Input, OnInit } from '@angular/core';
import { Globals } from '../../globals';
import { CustomFunction, ToastMode } from '../../include/structures';
import { CustomfunctionsService } from '../../lib/customfunctions.service';
import { User } from 'projects/core/src/include/structures'


@Component({
  selector: 'app-windowlistbuttons',
  templateUrl: './windowlistbuttons.component.html',
  styleUrls: ['./windowlistbuttons.component.css']
})
export class WindowlistbuttonsComponent implements OnInit {

  @Input()
  model:any;

  @Input()
  table:string;

  @Input()
  addButton:boolean = true;

  @Input()
  funs:[]=[];

  @Input()
  openDetailExtra:any={};

  @Input()
  showDelete=true;

  customfunctions:CustomFunction[]=[];

  get currentUser():User{
    return Globals.user;
  }

  constructor(
    private customfunctionsService:CustomfunctionsService
  ) { }


  ngOnInit(): void {

    this.customfunctionsService.getFunctions(this.table).subscribe((items)=>{
      this.customfunctions=items;
    });

    if(!Globals.permissions.checkPermission(this.model.modulename,"delete")){
      this.showDelete=false;
    }

  }

  isModuleEnabled(modulename){      
    return Globals.parameters.get(modulename)?true:false;
  }


  executeCustomFunction(cf:CustomFunction){
    let params="";
    let ids=this.model.form.value.id;
    if(ids){
      params="$ids='"+ids+"';";
    }

    Globals.message.showToaster("Operazione iniziata...",ToastMode.WARNING);
    this.customfunctionsService.executeFunction(cf.id,params).subscribe((result)=>{
      if(result){
        Globals.message.showToaster(result,ToastMode.INFO);
      }
      
      Globals.message.showToaster("Operazione completata!",ToastMode.SUCCESS);
      console.log(result);
    });
  }

}
