import { Component, OnInit, AfterViewInit,ViewChild} from '@angular/core';
import {Modal} from '../../include/modal';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import {ModalComponent} from '../modal/modal.component';
import { BrowserMultiFormatReader, BarcodeFormat, ZXingSystem } from '@zxing/library';
@Component({
  selector: 'app-qrcodereader',
  templateUrl: './qrcodereader.component.html',
  styleUrls: ['./qrcodereader.component.sass']
})
export class QrcodereaderComponent extends Modal implements OnInit,AfterViewInit  {


  @ViewChild('scanner', { static: false })
  scanner: ZXingScannerComponent ;

  @ViewChild('modal', { static: false })
  modal: ModalComponent ;

  constructor( ) {
    super();
   }

   code:string;

  formats = [
    BarcodeFormat.QR_CODE,
    BarcodeFormat.CODE_128,
    BarcodeFormat.EAN_8,
    BarcodeFormat.EAN_13,
    BarcodeFormat.DATA_MATRIX
  ];

  ngOnInit(): void {
    this.startScan();
   
      
      
    
  }

  ngAfterViewInit(){
    
  }
  
  startScan() {
    this.scanner.scanStart();
    this.scanner.autostart=true;
    this.scanner.tryHarder=true;
    this.scanner.autofocusEnabled;
    this.scanner.updateVideoInputDevices().then(devices => {
      this.scanner.device = devices[0]; 
    });
    this.scanner.tryHarder = true;
   
    this.scanner.askForPermission().then(permission => {
      console.log('Permissions response: ' + permission);
      
    });
  }

  scanCompleteHandler(code:string){
    this.code=code;
    this.modal.activeModal.close("success");
  }

}
