import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { ModelList } from 'projects/core/src/include/modellist';
import { Filter, FilterMode } from 'projects/core/src/include/structures';
import { CashFlow } from '../../../db/cashflow';
import { CashflowService } from '../../../services/cashflow.service';
import { ShopsService } from '../../../services/shops.service';

@Component({
  selector: 'app-cash-flow-overview',
  templateUrl: './cash-flow-overview.component.html',
  styleUrls: ['./cash-flow-overview.component.css']
})
export class CashFlowOverviewComponent extends ModelList<CashFlow>  implements OnInit,AfterViewInit {
  
  list=[];
  
  shops=[];
  filter_created_from:Filter=new Filter();
  date=Helper.convertDateControl();

  constructor(
    private fb1: UntypedFormBuilder,
    private cashflowService: CashflowService,
    private shopsService:ShopsService,

  ) { 
    super(cashflowService,fb1);
    this.modulename="cash-flow";
  }

  ngOnInit(): void {

    super.ngOnInit.apply(this, arguments); 
    this.title = 'Stato attuale';

   
    

    
    
    

  }
  ngAfterViewInit(): void {

    const default_address=Globals.parameters.get("general").getParam("default_address");

    this.filter_created_from.mode=FilterMode.normal;
    this.filter_created_from.fields=[];
    this.filter_created_from.fields.push("c.created_from");
    if(!this.checkPermissions("showCreatedFrom")){
      this.filter_created_from.value="";
    }else{
      this.filter_created_from.value=this.user['shop']?this.user['shop']['id']:"";
    }

   

    this.shopsService.getItemsByAddress(default_address).subscribe((items)=>{
      this.shops=items;
      this.getItems();
      
    });
  }

  getItems(){
    this.cashflowService.getOverview(this.date,this.filter_created_from.value).subscribe((result)=>{
      this.list=result;
    });
  }

  getTypeDocument(type){
    return Helper.getTypeDocument(type);
  }
}
