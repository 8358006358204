import { Component, OnInit, Input,Output, EventEmitter } from '@angular/core';
import { ModelList } from 'projects/core/src/include/modellist'
import { UntypedFormBuilder,  } from '@angular/forms';
import { Filter, FilterMode, FormValue, OrderingMode, User } from 'projects/core/src/include/structures'

import { UserService } from 'projects/core/src/lib/user.service';

import { Helper } from 'projects/core/src/helper';
import { NotificationService } from '../../../services/notification.service';
import { FormDetailComponent } from './form-detail/form-detail.component';
import { FormsValueService } from '../../../services/formsvalue.service';

@Component({
  selector: 'app-form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.css'],
})

export class FormListComponent extends ModelList<FormValue> implements OnInit {
  
  
  
  @Input()
  id_table:number=0;

  @Input()
  table:string="";

  @Input()
  record:any;

  @Input()
  filterbox:boolean=true;
  
  @Input()
  default_filterdate=(this.mode!='embedded')?1:100;
  
  @Output()
  afterLoaded:EventEmitter<void>=new EventEmitter();

  date_from: Date=new Date();
  date_to: Date=new Date();
  today=new Date;
  date_type:number=1;


  filter_search:Filter=new Filter();
  filter_date:Filter=new Filter();  
 
  filter_status:Filter=new Filter();
  
  filter_id_user:Filter=new Filter();
  filter_id_table:Filter=new Filter();
  filter_table:Filter=new Filter();
  
  
  users:User[];
  
  isFilterSet:boolean=false;
 
  
  constructor(
    private fb1: UntypedFormBuilder,
    private formsService:FormsValueService,
    private userService:UserService,
    private notificationService:NotificationService,

    
  ) {
    super(formsService,fb1);
    this.detailViewModal=FormDetailComponent;
    this.modulename="forms";
   }


  ngOnInit(): void {

    super.ngOnInit.apply(this, arguments); 
    this.title = 'Moduli';
   
    

    this.userService.getUsers().subscribe((items)=>{
      this.users=items;
    });

   
    this.updateList();
    
   
  }

  setFilters():void{
    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("f.name");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("f.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    

    this.filter_date.mode=FilterMode.date;
    this.filter_date.fields=[];
    this.filter_date.fields.push("f.date");
    this.filter.push(this.filter_date);
    
    

    //if(this.id_table>0){
      this.filter_id_table.mode=FilterMode.normal;
      this.filter_id_table.fields=[];
      this.filter_id_table.fields.push("f.id_table");
      if(this.id_table)
        this.filter_id_table.value=this.id_table.toString();
      this.filter.push(this.filter_id_table);

      this.filter_table.mode=FilterMode.normal;
      this.filter_table.fields=[];
      this.filter_table.fields.push("f.table");
      this.filter_table.value=this.table.toString();
      this.filter.push(this.filter_table);
    //}


    
    
    this.filter_id_user.mode=FilterMode.normal;
    this.filter_id_user.fields=[];
    this.filter_id_user.fields.push("f.id_user");
    this.filter_id_user.value="";
    this.filter.push(this.filter_id_user);

    /*
    if(this.mode!="embedded")
      Globals.navigation.getStateFilters(this.constructor.name,this.filter);
    */

    this.ordering.mode=OrderingMode.discendent;
    this.ordering.field="f.date";
    
    if(this.mode=="embedded"){
      this.paging_count=10000000;
    }

   

    this.isFilterSet=true;
  }

  ngOnChanges():void{
    if(this.id_table>0) //avvia la ricerca quando il filtro è attivo
      this.updateList();
  }
  

  updateList():void{
    if(this.mode=="embedded"){ //non avviare la ricerca fino a quando non si è impostato l'id_table
      if((this.id_table==0 || this.id_table==undefined))
        return;
    }


    if(!this.isFilterSet)
      this.setFilters();
      
    

   

    if(this.id_table>0){
      this.filter_id_table.value=this.id_table.toString();
      this.filter_table.value=this.table;
    }

    this.getItems();
  }

  newForm(){
    this.formsService.showNewForm(this.record,this.table,()=>{
      this.getItems();
    })
  }

  notify(record){

    this.notificationService.showPreviewNotification(Helper.getAddressItem(this.table,this.record).contacts,record,"formsvalue",()=>{

    });
    
    
  }
  
}
