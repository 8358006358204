import { Component, OnInit, Input} from '@angular/core';


@Component({
  selector: 'app-tours',
  templateUrl: './tours.component.html',
  styleUrls: ['./tours.component.css']
})
export class ToursComponent  implements OnInit {

  @Input()
  filterbox=true;

  @Input()
  autoopen="";

  @Input()
  tailormade=true;


 ngOnInit(): void {
  
 }
  
 tab=1;


}
