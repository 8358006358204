<div class="modal-header py-1 bg-primary">
    <b><i class="fa-ticket fas mr-2"></i>Nuovo biglietto</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="close()" >
            <i class="fas fa-times"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-2 pl-0 text-left">
                <button *ngIf="status>0" class="btn btn-outline-default" title="Indietro" (click)="goToBack()"><i class="fa fa-2x fa-arrow-left"></i></button>
            </div>
            <div class="col-sm-8 px-0 text-center">
                <h4 class="header_title text-primary p-2">{{title}}</h4>
            </div>
        </div>
    </div>
    <!-- SCEGLI COMPAGNIA DI TRASPORTO -->
    <div *ngIf="status==STATUS_CHOICE_BUS_COMPANY">
        <div class="container my-3">
            <div class="row">
                <div class="col-12 text-center">

                    <button *ngFor="let c of companies" (click)="ticket.id_company=c.id;goTo(STATUS_SEARCH_TRAVEL);title=c.name" class="btn btn-outline-primary text-left btn-lg btn-block"><img *ngIf="c.logo" src="{{c.logo}}" style="max-height:40px" class="mr-2">{{c.name}}</button>
                </div>
            </div>
        </div>
    </div>
    <!-- CERCA VIAGGIO -->
    <div *ngIf="status==STATUS_SEARCH_TRAVEL">
        <section id="top">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 mt-3">
                        <div class="submit-form">
                            <div class="row">
                                <div class="col-1 p-0 text-right mb-2">
                                    <label for="from">Da:</label>
                                </div>
                                <div class="col-11 mb-2">
                                    <select [disabled]="ports_from.length==0" class="form-control" [(ngModel)]="ticket.PortFrom" (ngModelChange)="getPortsTo()" data-live-search="true" data-live-search-placeholder="Cerca opzioni">
                                        <option *ngFor="let port of ports_from" [ngValue]="port">{{port.stop}}</option> 
                                    </select>
                                    <!--
                                    <ng-container *ngIf="ticket.id_company>1">
                                        <input autofocus class="form-control mb-1" [(ngModel)]="ticket.tickets[0].port_from" placeholder="inserisci porto di partenza" />
                                        <div class="input-group input-group-sm mb-1">
                                            <input  class="form-control form-control-sm" [(ngModel)]="ticket.tickets[0].station_from" placeholder="inserisci stazione di partenza" />
                                            <div class="input-group-append input-group-append-sm">
                                                <span class="input-group-text"><i class="fas fa-map-marker-alt text-gray"></i></span>
                                            </div>
                                        </div>
                                        <div class="input-group input-group-sm mb-1">
                                            <label class="d-none d-lg-inline w-50 text-right mr-2">Orario di partenza:</label>
                                            <input class="form-control form-control-sm" type="time" [(ngModel)]="ticket.tickets[0].time_from" />
                                            <div class="input-group-append input-group-append-sm">
                                                <span class="input-group-text">ANDATA</span>
                                            </div>
                                        </div>
                                        <div class="input-group input-group-sm mb-1" *ngIf="ticket.id_type==START_AND_RETURN">
                                            <label class="d-none d-lg-inline w-50 text-right mr-2">Orario di arrivo:</label>
                                            <input class="form-control form-control-sm" type="time" [(ngModel)]="ticket.port_to" />
                                            <div class="input-group-append input-group-append-sm">
                                                <span class="input-group-text">RITORNO</span>
                                            </div>
                                        </div>
                                    </ng-container>-->
                                </div>
                                <div class="col-1 p-0 text-right mb-2">
                                    <label for="to">A:</label>
                                </div>
                                <div class="col-11 mb-2">
                                    <select [disabled]="ports_to.length==0" class="form-control" [(ngModel)]="ticket.PortTo">
                                        <option *ngFor="let port of ports_to" [ngValue]="port">{{port.stop}}</option> 
                                    </select>
                                    <!--
                                    <ng-container *ngIf="ticket.id_company>1">
                                        <input class="form-control mb-1" [(ngModel)]="ticket.tickets[0].port_to" placeholder="inserisci porto di arrivo" />
                                        <div class="input-group input-group-sm mb-1">
                                            <input class="form-control form-control-sm" [(ngModel)]="ticket.tickets[0].port_tostation_to" placeholder="inserisci stazione di arrivo" />
                                            <div class="input-group-append input-group-append-sm">
                                                <span class="input-group-text"><i class="fas fa-map-marker-alt text-gray"></i></span>
                                            </div>
                                        </div>
                                        <div class="input-group input-group-sm mb-1">
                                            <label  class="d-none d-lg-inline w-50 text-right mr-2">Orario di arrivo:</label>
                                            <input class="form-control form-control-sm" type="time" [(ngModel)]="ticket.tickets[0].time_to" />
                                            <div class="input-group-append input-group-append-sm">
                                                <span class="input-group-text">ANDATA</span>
                                            </div>
                                        </div>
                                        <div class="input-group input-group-sm mb-1" *ngIf="ticket.id_type==START_AND_RETURN">
                                            <label class="d-none d-lg-inline w-50 text-right mr-2">Orario di partenza:</label>
                                            <input class="form-control form-control-sm" type="time" [(ngModel)]="ticket.port_to" />
                                            <div class="input-group-append input-group-append-sm">
                                                <span class="input-group-text">RITORNO</span>
                                            </div>
                                        </div>
                                    </ng-container>-->
                                </div>
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-6">
                                            <label for="to">Adulti:</label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <button class="btn btn-primary" (click)="reduceQuantity('adults');"><i class="fa fa-minus"></i></button>
                                                </div>
                                                <input type="number" readonly class="form-control text-center" [(ngModel)]="ticket.adults"/>
                                                <div class="input-group-append">
                                                    <button class="btn btn-primary" (click)="addQuantity('adults');"><i class="fa fa-plus"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <label for="to">Bambini:<small> (max 12 anni)</small></label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <button class="btn btn-primary" (click)="reduceQuantity('children');"><i class="fa fa-minus"></i></button>
                                                </div>
                                                <input type="number" readonly class="form-control text-center" [(ngModel)]="ticket.children"/>
                                                <div class="input-group-append ">
                                                    <button class="btn btn-primary" (click)="addQuantity('children');"><i class="fa fa-plus"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    <div class="btn-group btn-group-toggle col-12 mt-2"    >
                                        <label ngbButtonLabel class="col-6 btn-default">
                                            <img class="my-1" src="assets/img/bus-a.png" width="60"><br>
                                            <input  type="radio" name="radioBasic" [value]="ONLY_START" [(ngModel)]="ticket.id_type">Solo ANDATA
                                        </label>
                                        <label ngbButtonLabel class="col-6 btn-default">
                                            <img class="my-1" src="assets/img/bus-ar.png" width="60"><br>
                                            <input   type="radio" name="radioBasic" [value]="START_AND_RETURN" [(ngModel)]="ticket.id_type">ANDATA e RITORNO
                                        </label>
                                    </div>
                                <div class="col-12 text-center" *ngIf="ticket.id_type==ONLY_START">
                                    <label for="date_start_selected">Data partenza:</label><br/>
                                    <ngb-datepicker #d [(ngModel)]="date_start_selected" [displayMonths]="1" ></ngb-datepicker>
                                </div>
                                <div class="col-12 text-center" *ngIf="ticket.id_type==START_AND_RETURN">
                                    <label for="date_start_selected">Date partenza e ritorno:</label><br/>
                                    <ngb-datepicker #d  (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" ></ngb-datepicker>
                                    <ng-template #t let-date let-focused="focused">
                                        <span class="custom-day"
                                                [class.focused]="focused"
                                                [class.range]="isRange(date)"
                                                [class.faded]="isHovered(date) || isInside(date)"
                                                (mouseenter)="hoveredDate = date"
                                                (mouseleave)="hoveredDate = null">
                                            {{ date.day }}
                                        </span>
                                    </ng-template>
                                </div>
                                <div class="col-12 mt-3">
                                    <button [disabled]="(ticket.port_from=='' || ticket.port_to=='')" (click)="goTo(STATUS_SELECT_RACE_START)" class="btn btn-block btn-primary"><i class="fas fa-search mr-2"></i>Cerca corsa</button>
                                    <!--<button *ngIf="ticket.id_company>1"  (click)="goTo(STATUS_SELECT_SEATS)" class="btn btn-block btn-primary">Inserisci i passeggeri<i class="fas fa-chevron-right ml-2"></i></button>-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <!-- SELEZIONA CORSA DI ANDATA -->
    <div *ngIf="status==STATUS_SELECT_RACE_START">
        <div class="container mt-3">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="text-center" *ngIf="travelsstart.length==0">
                        <img  src="assets/img/search_travel.gif" width="300">
                        <h3 class="mt-2">
                            Sto cercando tutte le corse<br>per la tratta<br>
                        <b>{{ticket.port_from}}</b> - <b>{{ticket.port_to}}</b></h3>
                    </div>
                    <span>Totale posti richiesti: <b>{{ticket.adults+ticket.children}}</b></span>
                    <div class="mt-2" *ngIf="travelsstart.length>1">
                        <ng-container *ngFor="let traveldate of travelsstart;let i=index">
                            <p class="mb-1" *ngIf="i==0">Date disponibili:</p>
                            <button (click)="travelSlidesStart.next()" class="btn btn-sm btn-block btn-outline-secondary">{{traveldate.date | date:'EEEE dd/MM/yyyy'}}</button>
                        </ng-container>
                    </div>
                    <ngb-carousel 
                        #travelSlidesStart 
                        *ngIf="travelsstart.length>0" 
                        interval="0" 
                        [wrap]="true" 
                        [showNavigationArrows]="false" 
                        [showNavigationIndicators]="false" >
                    <!--<owl-carousel-o [options]="travelsOptions" *ngIf="travelsstart.length>0">-->
                        <!--<ng-container *ngFor="let traveldate of travelsstart">-->
                            <ng-template ngbSlide *ngFor="let traveldate of travelsstart;let i=index" id="{{i}}">
                            <!--<ng-template carouselSlide [id]="traveldate.id">-->
                                <div class="card mt-2">
                                    <div class="col-12 card-header">
                                        <h4 class="card-title mb-0 text-gray">
                                            <i class="fas fa-calendar-day mr-2"></i>
                                            <b class="text-primary text-lg mr-2">{{traveldate.date | date:'EEEE dd'}}</b>
                                            <span class="">{{traveldate.date | date:'LLLL yyyy'}}</span>
                                        </h4>
                                        <small *ngIf="traveldate.travel.length>1" class="float-right text-gray">({{traveldate.travel.length}} orari)</small>
                                    </div>
                                    <div class="card-body border text-center" *ngFor="let travel of traveldate.travel">
                                        <div class="mb-3">
                                            Partenza da {{ticket.port_from}} alle ore <b>{{travel.START_TIME}}</b><br/>
                                            Arrivo a {{ticket.port_to}} alle ore <b>{{travel.STOP_TIME}}</b><br/>
                                            <ng-container *ngIf="travel.cost>0">
                                                <small class="mt-4">Costo totale</small>
                                                <h3 class="text-success"><b>{{travel.cost | currency:'EUR'}}</b></h3>
                                                <div class="border d-inline-block p-2 mb-2 text-gray">
                                                    <i class="fas fa-male mr-2"></i>{{travel.PRICES[0].ADULT*(1-travel.discount/100) | currency:'EUR'}}<br>
                                                    <i class="fas fa-child text-xs mr-2"></i>{{travel.PRICES[1].CHILDREN | currency:'EUR'}}
                                                </div>
                                            </ng-container>
                                            <br>
                                            <i>(<b>{{travel.COUNT_SEATS}}</b> posti disponibili)</i>
                                        </div>
                                        <div class="col-12">
                                            <button class="btn btn-block btn-primary" [disabled]="travel.COUNT_SEATS < (ticket.adults+ticket.children)" (click)="onSelectTravel(travel)">Seleziona<i class="fas fa-chevron-right ml-2"></i></button>
                                            <label class="text-danger" *ngIf="travel.COUNT_SEATS>0 && travel.COUNT_SEATS < (ticket.adults+ticket.children)">
                                                Ci sono solo {{travel.COUNT_SEATS}} posti disponibili.
                                            </label>
                                            <label class="text-danger" *ngIf="travel.COUNT_SEATS==0">
                                                Non ci sono più posti disponibili!
                                            </label>
                                        </div>
                                    </div>
                                </div>   
                            </ng-template>
                        <!--</ng-container>  -->
                    <!--</owl-carousel-o>-->
                    </ngb-carousel>
                </div>
            </div>
        </div>
    </div>
    <!-- SELEZIONA CORSA DI RITORNO -->
    <div *ngIf="status==STATUS_SELECT_RACE_RETURN">
        <div class="container mt-3">
            <div class="row">
                <div class="col-md-12 text-center">
                    <div class="text-center" *ngIf="travelsreturn.length==0">
                        <img  src="assets/img/search_travel.gif" width="300">
                        <h3 class="mt-2">Attendi.<br>
                            Sto cercando tutte le corse per la tratta<br>
                        <b>{{ticket.port_to}}</b> - <b>{{ticket.port_from}}</b></h3>
                    </div>
                    <span>Totale posti richiesti: <b>{{ticket.adults+ticket.children}}</b></span>
                    <div class="mt-2" *ngIf="travelsreturn.length>1">
                        <ng-container *ngFor="let traveldate of travelsreturn;let i=index">
                            <p class="mb-1" *ngIf="i==0">Date disponibili:</p>
                            <button (click)="travelSlidesReturn.next()" class="btn btn-sm btn-block btn-outline-secondary">{{traveldate.date | date:'EEEE dd/MM/yyyy'}}</button>
                        </ng-container>
                    </div>
                    <ngb-carousel 
                        #travelSlidesReturn 
                        *ngIf="travelsreturn.length>0" 
                        interval="0" 
                        [wrap]="true" 
                        [showNavigationArrows]="false" 
                        [showNavigationIndicators]="false" >
                    <ng-template ngbSlide *ngFor="let traveldate of travelsreturn;let i=index" id="{{i}}">
                    <!--<owl-carousel-o [options]="travelsOptions" *ngIf="travelsreturn.length>0">-->
                       <!-- <ng-container *ngFor="let traveldate of travelsreturn">
                            <ng-template carouselSlide [id]="traveldate.id">-->
                                <div class="card mt-2">
                                    <div class="col-12 card-header ">
                                        <h4 class="card-title mb-0 text-gray">
                                            <i class="fas fa-calendar-day mr-2"></i>
                                            <b class="text-primary text-lg mr-2">{{traveldate.date | date:'EEEE dd'}}</b>
                                            <span class="">{{traveldate.date | date:'LLLL yyyy'}}</span>
                                        </h4>
                                    </div>
                                   <div class="card-body">
                                        <div class="row text-center" *ngFor="let travel of traveldate.travel">
                                            <div class="col-12 mb-3">
                                                Partenza da {{ticket.port_to}} alle ore <b>{{travel.START_TIME}}</b><br/>
                                                Arrivo a {{ticket.port_from}} alle ore <b>{{travel.STOP_TIME}}</b><br/>
                                                <small class="mt-4">Costo totale</small>
                                                <h3 class="text-success"><b>{{travel.cost | currency:'EUR'}}</b></h3>
                                                <div class="border d-inline-block p-2 mb-2 text-gray">
                                                    <i class="fas fa-male mr-2"></i>{{travel.PRICES[0].ADULT*(1-travel.discount/100) | currency:'EUR'}}<br>
                                                    <i class="fas fa-child text-xs mr-2"></i>{{travel.PRICES[1].CHILDREN | currency:'EUR'}}
                                                </div><br>
                                                <i>(<b>{{travel.COUNT_SEATS}}</b> posti disponibili)</i>
                                            </div>
                                            <div class="col-12">
                                                <button class="btn btn-block btn-primary" [disabled]="travel.COUNT_SEATS < (ticket.adults+ticket.children)" (click)="onSelectTravel(travel,'Ritorno')">Seleziona<i class="fas fa-chevron-right ml-2"></i></button>
                                                <label class="text-danger" *ngIf="travel.COUNT_SEATS>0 && travel.COUNT_SEATS < (ticket.adults+ticket.children)">
                                                    Ci sono solo {{travel.COUNT_SEATS}} posti disponibili.
                                                </label>
                                                <label class="text-danger" *ngIf="travel.COUNT_SEATS==0">
                                                    Non ci sono più posti disponibili!
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>   
                            </ng-template>
                        <!--</ng-container>  -->
                    <!--</owl-carousel-o>-->
                    </ngb-carousel>
                </div>
            </div>
        </div>
    </div>
    <!-- SELEZIONA POSTI -->
    <div *ngIf="status==STATUS_SELECT_SEATS">
        <div class="mt-3">
            <table class="table table-sm">
                <tr *ngFor="let passenger of passengers_start;let i=index" >
                    <td>
                        <small>
                            <i *ngIf="passenger.type=='ADULT'" class="fas fa-2x fa-male mr-2"></i>
                            <i *ngIf="passenger.type=='CHILDREN'" class="fas pt-3 fa-child text-xs mr-2"></i>
                            <span>{{passenger.id}}° </span>
                            <span *ngIf="passenger.type=='ADULT'">adulto</span>
                            <span *ngIf="passenger.type=='CHILDREN'">bambino</span>
                        </small>
                    </td>
                    <td>
                        <input type="text" class="form-control form-control-sm" placeholder="Inserisci il Nome" name="passengername{{i}}" [(ngModel)]="passenger.name" />
                    </td>
                    <td>
                        <input type="text" class="form-control form-control-sm" placeholder="Inserisci il Cognome" name="passengerlastname{{i}}" [(ngModel)]="passenger.lastname" />
                    </td>
                    <td>
                        <input type="number" class="form-control form-control-sm" placeholder="Inserisci il Costo" name="passengercoststart{{i}}" [(ngModel)]="passenger.cost" />
                    </td>
                    <td *ngIf="ticket.id_type==START_AND_RETURN">
                        <input type="number" class="form-control form-control-sm" placeholder="Inserisci il Costo" name="passengercostreturn{{i}}" [(ngModel)]="passengers_return[i].cost" />
                    </td>


                </tr>
            </table>
            <!--
            <owl-carousel-o [options]="passengersOptions" id="passengers">
                <ng-container *ngFor="let passenger of passengers">
                    <ng-template carouselSlide [id]="passenger.id">


                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">

                                    <span class="fa-stack" *ngIf="passenger.type=='ADULT'" style="vertical-align: middle;">
                                        <i class=" text-primary fas fa-circle fa-stack-2x"></i>
                                        <i class="fas fa-male fa-stack-1x fa-inverse"></i>
                                    </span>

                                    <span class="fa-stack" *ngIf="passenger.type=='CHILDREN'" style="vertical-align: middle;">
                                        <i class=" text-primary fas fa-circle fa-stack-2x"></i>
                                        <i class="fas fa-child fa-stack-1x fa-inverse"></i>
                                    </span>
                                    
                                        {{passenger.id}}° passeggero 
                                        <b>
                                        <span *ngIf="passenger.type=='ADULT'">adulto</span>
                                        <span *ngIf="passenger.type=='CHILDREN'">bambino</span>
                                    </b>
                                </h4>
                            </div>
                            <div class="card-body">
                                
                                <label for="to">Nome:</label>
                                <input type="text" class="form-control" [(ngModel)]="passenger.name" />
                                <br>
                                <label for="to">Cognome:</label>
                                <input type="text" class="form-control" [(ngModel)]="passenger.lastname" />
                                
                            </div>
                                
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o> -->

        </div>
        <div class="text-center mt-5">
            <h3 class="mb-2">Totale da pagare <span class="text-success text-left"><strong>{{totalTicket | currency:'EUR'}}</strong></span></h3>
        </div>
        <div class="text-center mt-5">
            <button class="btn btn-block btn-primary" (click)="confirmPassengers();">Conferma i passeggeri<i class="fas fa-chevron-right ml-2"></i></button>
        </div>
    </div>
    <!-- RIEPILOGO BIGLIETTI -->
    <div *ngIf="status==STATUS_CONFIRM_TICKET">
        <div class="container text-center">
            <div class="card mt-2" >
                <div class="card-header">
                    <h4>{{ticket.port_from}} - {{ticket.port_to}}</h4>
                    <span class="text-sm">{{travel_selected_start.TRIP_DATE | date:'EEEE dd LLLL yyyy'}}</span>
                </div>
                <div class="card-body">
                    <div class="row text-center mt-2">
                        <div class="col-5">
                            <h3><strong>{{travel_selected_start.START_TIME}}</strong></h3>
                        </div>
                        <div class="col-2 p-0 mb-2">
                            <img *ngIf="true" src="assets/img/bus-a.png" style="width:100%;">
                        </div>
                        <div class="col-5">
                            <h3><strong>{{travel_selected_start.STOP_TIME}}</strong></h3>
                        </div>
                    </div>
                    <div class="row text-center mb-2">
                        <div class="col-5">
                            <a target="_blank" href="https://www.google.it/maps/place/{{travel_selected_start.START_ADDRESS}}, {{ticket.port_from}}" class="text-uppercase"><i class="fas fa-map-marker-alt"></i> {{travel_selected_start.START_ADDRESS}}</a>
                        </div>
                        <div class="col-2">
                        </div>
                        <div class="col-5">
                            <a target="_blank" href="https://www.google.it/maps/place/{{travel_selected_start.STOP_ADDRESS}}, {{ticket.port_to}}" class="text-uppercase"><i class="fas fa-map-marker-alt"></i> {{travel_selected_start.STOP_ADDRESS}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-2" *ngIf="ticket.id_type==START_AND_RETURN">
                <div class="card-header">
                    <h4>{{ticket.port_to}} - {{ticket.port_from}}</h4>
                    <span class="text-sm">{{travel_selected_return.TRIP_DATE | date:'EEEE dd LLLL yyyy'}}</span>
                </div>
                <div class="card-body">
                    <div class="row text-center mt-2">
                        <div class="col-5">
                            <h3><strong>{{travel_selected_return.START_TIME}}</strong></h3>
                        </div>
                        <div class="col-2 p-0 mb-2">
                            <img *ngIf="true" src="assets/img/bus-r.png" style="width:100%;">
                        </div>
                        <div class="col-5">
                            <h3><strong>{{travel_selected_return.STOP_TIME}}</strong></h3>
                        </div>
                    </div>
                    <div class="row text-center mb-2">
                        <div class="col-5">
                            <a target="_blank" href="https://www.google.it/maps/place/{{travel_selected_start.STOP_ADDRESS}}, {{ticket.port_to}}" class="text-uppercase"><i class="fas fa-map-marker-alt"></i> {{travel_selected_start.STOP_ADDRESS}}</a>
                        </div>
                        <div class="col-2">
                        </div>
                        <div class="col-5">
                            <a target="_blank" href="https://www.google.it/maps/place/{{travel_selected_start.START_ADDRESS}}, {{ticket.port_from}}" class="text-uppercase"><i class="fas fa-map-marker-alt"></i> {{travel_selected_start.START_ADDRESS}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center mt-2">
                <h3 class="mt-2">Totale da pagare <span class="text-success text-left"><strong>{{ticket.cost | currency:'EUR'}}</strong></span></h3>
            </div>
            <div class="text-center mt-2 p-2 mx-2">
                <button class="btn btn-block btn-primary" (click)="goTo(STATUS_PAY);">Continua<i class="fas fa-chevron-right ml-2"></i></button>
            </div>
        </div>
    </div>
    <!-- DATI INTESTATARIO -->
    <div *ngIf="status==STATUS_PAY">
        <div class="container mt-3">
            <div class="text-center" *ngIf="onSaving">
                <img  src="assets/img/search_travel.gif" width="300">
                <h3 class="mt-2">Attendi.<br>
                    Sto salvando la prenotazione<br>
                </h3>
            </div>
            <div class="card" *ngIf="!onSaving">
               <div class="card-body text-center">
                    <div class="row">
                        <div class="col-md-12">
                                <label>Telefono per ricevere il biglietto via SMS:</label>
                                <input type="tel" class="form-control text-center" style="font-size:22pt;font-weight: bolder;" [(ngModel)]="client['telephone']" />                                        <div class="input-group-prepend ">
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-12">
                            <label for="to">Cognome e Nome:</label>
                            <div class="input-group input-group-sm m-1" >
                                <input type="text" class="form-control" [(ngModel)]="client['name']" />
                                <div class="input-group-prepend input-group-prepend-sm">
                                    <button class="btn btn-primary" [disabled]="!client['name']" (click)="searchClientByName()"><i class="fa fa-search"></i></button>
                                </div>
                            </div>
                            <!--<input type="text" class="form-control" [(ngModel)]="client['lastname']" />-->
                        </div>
                        
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">
                            <label for="to">Indirizzo email su cui ricevere il biglietto (facoltativo):</label>
                            <input type="email" class="form-control" [(ngModel)]="client['email']" />
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12">  
                            <label>Ricarica postepay</label>
                            <select class="form-control" name="id_card" [(ngModel)]="ticket.id_card">
                                <option *ngFor="let c of cards;let i = index" [(ngValue)]="c.id">{{c.name}} {{c.cardnumber}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center mt-5  p-2" *ngIf="!onSaving">
                <button class="btn btn-block btn-primary" (click)="confirmTicket();">
                    <span *ngIf="ticket.id_address==0 || ticket.id_address==undefined">Registra</span>
                    <span *ngIf="ticket.id_address>0">Conferma</span>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="status==ORDER_CONFIRMED">
        <div class="container mt-5">
            <h2>I biglietti sono in stato di attesa.</h2>
            <p>Ora sarai reindirizzato sulla pagina di pagamento.</p>
            <div class="text-center p-2">
                <button class="btn btn-primary" (click)="openPayMethod();">Effettua il pagamento</button>
            </div>
        </div>
    </div>
    <div *ngIf="status==ORDER_COMPLETE">
        <div class="container mt-5">
            <div class="row">
                <div class="col-12 text-center">
                    <h2 class="mt-2"><span class="text-success text-center"><i class="fa fa-lg fa-check mr-2"></i></span>Grazie per aver acquistato i tuoi biglietti su Prontoevai.</h2>
                    <p>A breve saranno inviati i biglietti via SMS e sull'indirizzo email che indicato.</p>
                </div>
                <div class="col-4 mt-5">
                    <img src="assets/img/your_tickets.jpg" style="width:100%;" />
                </div>
                <div class="col-8">
                    <h2>I biglietti sono sempre a portata di mano!</h2>
                    <p>Su prontoevai.it, è possibile visionare i biglietti entrando all'area riservata.<br>Prontoevai suggerirà il prossimo viaggio da effettuare e visualizzerà il biglietto così da mostrarlo rapidamente ai controllori </p>
                    <button class="btn btn-sm btn-primary heartbeat" (click)="openYourTickets();">Vai all'area riservata</button>
                    <button class="btn btn-sm btn-primary heartbeat" (click)="goTo(STATUS_SEARCH_TRAVEL);">Prenota un nuovo biglietto</button>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #modalPassword let-modal>
    <div class="modal-header bg-light">
      <span class="modal-title">Seleziona Cliente</span>
    </div> 
    <div class="modal-body">
      <table class="table">
          <tr *ngFor="let c of listClientsSearched">
              <td class="pl-0">
                <small>
                    <b>{{c.name}}</b><br>
                    <ng-container *ngFor="let v of c.contacts">{{v.value}}<br></ng-container>
                </small>
            </td>
            <td class="pr-0">
                <button class="btn btn-sm btn-primary float-right" (click)="selectClient(c)">Seleziona<i class="fas fa-chevron-right ml-2"></i></button>
            </td>
          </tr>
      </table>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-xs btn-secondary" (click)="modal.close('cancel')">Annulla</button>
    </div>
  </ng-template>


  <ng-template #modalPay let-modal>
    <div class="modal-header bg-light">
        <h4 class="modal-title">Paga online</h4>
      </div> 
    <div class="modal-body">
        <div *ngIf="onloadPayment" class="text-center mt-5">
            <img  src="assets/img/search_travel.gif" width="300">
            <h3 class="mt-2">Attendi.<br>
                Sto caricando il sistema di pagamento online<br>
            </h3>
        </div>
        <iframe [style.display]="!onloadPayment ? 'block' : 'none'" #paygateway (load)="onLoadIframePay(paygateway)" style="border:none;" width="100%" height="500" [src]="paygatewayurl | safe"></iframe>
    </div>
  </ng-template>