import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TakephotoComponent } from './takephoto.component';
import { WebcamModule } from 'ngx-webcam';



@NgModule({
  declarations: [TakephotoComponent],
  exports:[TakephotoComponent],
  imports: [
    CommonModule,
    WebcamModule
  ]
})
export class TakephotoModule { }
