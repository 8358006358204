<app-modal title="Seriali del prodotto" [showConfirmButton]="false">    
    <div class="row">
        <div class="col-8">
            <i class="fa-2x {{product.icon}}"></i>
            <h4>{{product.brand}} {{product.name}}</h4>
            <ng-container *ngIf="!product['inventoryname']">
                <span>Lista dei seriali del prodotto</span>
            </ng-container>
            <ng-container *ngIf="product['inventoryname']">
                <h6  class="text-gray">Magazzino <b>{{product['inventoryname']}}</b></h6>
                <span>Lista dei seriali del prodotto disponibili in questo magazzino</span>
            </ng-container>           
        </div>
        <div class="col-4 border-left">
            <ul class="list-group" *ngFor="let s of product.serials">
                <ng-container *ngIf="s.avaible>0"><li class="list-group-item">{{s.sn}}</li></ng-container>                
            </ul>
        </div>        
    </div>
</app-modal>