import { Injectable } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { WSService } from 'projects/core/src/include/service';
import { Observable } from 'rxjs';
import { DocumentTemplate } from '../db/documenttemplate';
import { DocumentsDetailsComponent } from '../views/documents/documents-details/documents-details.component';
import { Documents } from '../db/documents';

@Injectable({
  providedIn: 'root'
})
export class DocumentsTemplateService extends WSService<DocumentTemplate> {

  constructor() {super("documentstemplate") }

  createDocument(table,record,document:Function,paid=0,method_payment="",detailsdocument=[],autosave=true){
    Globals.ws.send("documentstemplate","createDocument",record,[table,"",paid,method_payment,detailsdocument,autosave],document);

  }


  createOpenDocument(table,record,paid=0,method_payment="",detailsdocument=[],oncomplete=null){
    
    this.createDocument(table,record,(document)=>{
      if(document==false){
          document=new Documents();
          document.id=0;
          if(record.id_address)
            document.id_address=record.id_address;
          document.details=detailsdocument;
          document.id_table=record.id;
          document.table=table;
          document.paid=paid;
          document.methodpayment=method_payment;

      }
      
      Globals.modal.showModal(DocumentsDetailsComponent,[
        {"name":"mode","value":"modal"},
        {"name":"document_precompiled","value":document}
      ],(instance)=>{
        let id=instance['id'];
        if(oncomplete)
          oncomplete(id);
      });
      
    },paid,method_payment,detailsdocument,false);
    
  }



}
