<app-modal title="Gestione marche">
    <!-- <div class="row">
        <div class="col-12 mb-1">
            <button class="btn btn-xs btn-success" (click)="getItems()">Aggiorna</button>                          
        </div>
    </div> -->
    <div class="row" style="overflow-y: scroll;max-height: 400px;">
        <div class="col-12">
            <table class="table table-sm">
                <tbody>
                    <tr *ngFor="let r of list">
                        <td>{{r.name}}</td>
                        <td>
                            <button class="btn btn-xs btn-danger float-right ml-1" (click)="deleteBrand(r);"><i class="fa fa-trash"></i></button>
                            <button class="btn btn-xs btn-secondary float-right ml-1" (click)="editBrand(r)"><i class="fa fa-pencil-alt"></i></button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <button class="btn btn-sm btn-outline-success btn-block" (click)="addBrand();"><i class="fa fa-plus mr-1"></i>Aggiungi</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</app-modal>