import { Component, Input, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { DocumentsService } from '../../../services/documents.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { UntypedFormBuilder} from '@angular/forms';
import { ModelList } from 'projects/core/src/include/modellist'
import { Globals } from 'projects/core/src/globals';

@Component({
  selector: 'app-fatturaelettronica',
  templateUrl: './fatturaelettronica.component.html',
  styleUrl: './fatturaelettronica.component.css'
})

export class FatturaelettronicaComponent extends ModelList<any> implements OnInit {
  
  
  @ViewChild("frame")
  frame;

  @Input()
  id_document:number=0;

  url:string="";
  url_xml:string="";

  message=[];

  style1="Foglio_di_stile_fatturaordinaria_v1.2.1.xsl.xml";
  style2="FoglioStileAssoSoftware.xsl";
  style = this.style1;


  constructor(
    private fb1: UntypedFormBuilder,
    private documentsService:DocumentsService,
    public sanitizer:DomSanitizer,
    private httpClient:HttpClient
  ) {
    super(documentsService,fb1)
   }

  
  ngOnInit(): void {    

    if (Globals.parameters.get("documents").getParam("envoicestylesheet")==1) this.style=this.style1;
    if (Globals.parameters.get("documents").getParam("envoicestylesheet")==2) this.style=this.style2;
    
    this.updateStyle(this.style);  
    this.createXML();

    

  }


  updateStyle(s){
    this.style=s;
    this.documentsService.createXSL(this.id_document,s).subscribe((uri)=>{
      this.url=uri;
    });
  }

  close(){
    this['modalWindow'].close();
  }

  checkXML(){
    this.documentsService.checkXMLEInvoice([this.id_document]).subscribe((result)=>{
      if(result){
        try{
        this.message=JSON.parse(result[0]['sdi_message']);
        }
        catch{
          this.message=[];
        }
      }
    });
  }

  createXML(){
    this.documentsService.createXML(this.id_document).subscribe((uri)=>{
      this.url_xml=uri;
      this.checkXML();
    })
  }

  downloadPreview(){
    
    window.open(this.url,"_blank");
   
   
   
  }
  

}

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}
  transform(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }
} 