import { Injectable,Input } from '@angular/core';
import { Globals } from '../globals';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ToastMode } from '../include/structures';

@Injectable({
  providedIn: 'root'
})
export class UploadService  {

 

  constructor() { }

  uploadFile(file,subdirectory,oncomplete,hashfile=false,default_filename="",onProgress=null) {
  
    if(Globals.ws.api_version=="v2"){
      Globals.ws.sendfile("upload","upload",file,[subdirectory,hashfile,default_filename]).subscribe(
        {
          next: (event: any) => {
            
            if (event.type === HttpEventType.UploadProgress) {
              if(onProgress)
                onProgress(Math.round((100 * event.loaded) / event.total));
            } else if (event instanceof HttpResponse) {
              console.log(event.body);
              oncomplete(event.body);
            }
          },
          error: (err: any) => {
            Globals.message.showToaster("Errore nel salvataggio",ToastMode.DANGER);
            console.log(err);
          },
          complete: () => {
            //let id:number=(res as SaveResult).value
            console.log("complete");
            
          }
        }
      )
    }else{

      Globals.ws.send("upload","upload",null,[subdirectory,hashfile,default_filename],(result)=>{
        
        if(oncomplete)  
          oncomplete(result);
      },"File caricato correttamente!",file,(progress)=>{
        if(onProgress)
          onProgress(progress);
      }); 
    }
}

uploadImage64(img64,subdirectory,oncomplete,hashfile=false) {
  
  let image:any={};
  image.b64=img64;
    
  Globals.ws.send("upload","uploadImg",image,[subdirectory,hashfile],(result)=>{
    
    if(oncomplete)  
      oncomplete(result);
  },"File caricato correttamente!");
  
}




}
