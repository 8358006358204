import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormBuilder} from '@angular/forms';
import { Type } from '../../../db/type';
import { Filter,FilterMode } from 'projects/core/src/include/structures'
import { InventoriesDetailsService } from '../../../services/inventoriesdetails.service';
import { Inventories, InventoriesTotal } from '../../../db/inventories';
import {ProductsService} from '../../../services/products.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Product } from '../../../db/product';
import { ModelList } from 'projects/core/src/include/modellist';
import { Globals } from 'projects/core/src/globals';
import { InventoriesDetailsComponent } from '../inventories-details/inventories-details.component';
import { InventoriesService } from '../../../services/inventories.service';



@Component({
  selector: 'app-stateinventories',
  templateUrl: './stateinventories.component.html',
  styleUrls: ['./stateinventories.component.css']
})
export class StateinventoriesComponent extends ModelList<Product> implements OnInit {

  

  


  @Input()
  id_product:number=0;

  @Input()
  sn:string="";
  
  

  inventories:Inventories[];
  status_inventories:Type[];

 

  filter_search:Filter=new Filter();
  filter_date:Filter=new Filter();  
  filter_type:Filter=new Filter();
  filter_id_inventory:Filter=new Filter();
  filter_status:Filter=new Filter();
  filter_id_product:Filter=new Filter();
  
  total_amount:number=0;

  total:InventoriesTotal;
  
  

  isFilterSet:boolean=false;

  /* FAST LOAD/ULOAD */

  fastLUquantity:number=1;
  fastLUinventory:number=Globals.user.getParam("id_inventory",0);
  @Input()
  afterSave:Function;
  

  constructor( private fb1: UntypedFormBuilder,
    private InventoriesDetailsService: InventoriesDetailsService,
    private InventoriesService: InventoriesService,
    
    private productsService: ProductsService,
    private modalService: NgbModal
    
  ) {
    super(productsService,fb1);
    this.detailViewModal=InventoriesDetailsComponent;
   }

  ngOnInit(): void {
    this.title = 'Magazzini';
    super.ngOnInit.apply(this, arguments); 
    this.paging_count=25;
    this.InventoriesService.getInventories().subscribe((items)=>{
      this.inventories=items;
    });
   
    if(this.mode!="embedded") //se incoroporato in una finestra non avviare subito la ricerca, ma aspetta che il filtro sia attivo
      this.updateList(this.id_product);

    
     

  }

  setFilters():void{
    this.filter_id_inventory.mode=FilterMode.normal;
    this.filter_id_inventory.fields=[];
    this.filter_id_inventory.fields.push("ps.id_inventory");
    this.filter_id_inventory.value="";
    this.filter.push(this.filter_id_inventory);

    
    this.filter_id_product.mode=FilterMode.normal;
    this.filter_id_product.fields=[];
    this.filter_id_product.fields.push("pm.id");
    if(this.id_product)
      this.filter_id_product.value=this.id_product.toString();
    this.filter.push(this.filter_id_product);
    
    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("pm.name");
    this.filter_search.fields.push("pb.name"); //brand
    this.filter_search.fields.push("pm.codeinternal");
    this.filter_search.fields.push("pm.codeexternal");
    this.filter_search.fields.push("pm.barcode");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.ordering.field="pm.name";
    this.isFilterSet=true;
    
  }

ngOnChanges():void{
  if(this.id_product>0) //avvia la ricerca quando il filtro è attivo
    this.updateList(this.id_product);
}

updateList(id_product:number):void{
  if(!this.isFilterSet)
      this.setFilters();

  this.total_amount=0;
  
  if(id_product>0){
    this.filter_id_product.value=id_product.toString();
  }

  this.getItems();
}

 

  

}
