import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit,AfterViewInit,Input,Output,EventEmitter } from '@angular/core';
import * as L from 'leaflet';

import 'leaflet-bing-layer';
import { Globals } from 'projects/core/src/globals';
import { ToastMode } from 'projects/core/src/include/structures';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';


@Component({
  selector: 'app-searchgps',
  templateUrl: './searchgps.component.html',
  styleUrls: ['./searchgps.component.sass']
})
export class SearchgpsComponent implements OnInit,AfterViewInit  {

  @Input()
  latitude:number=0;

  @Input()
  longitude:number=0;

  

  address:string;
  city:string;
  zip:string;
  country:string;
  loading=false;

  private map:L.Map;
  pin:L.Marker;

  @Input()
  search:string;
  
  btn_search_gps="Trova coordinate";
  constructor(private http:HttpClient) { }

  ngOnInit(): void {
    if(this.latitude==undefined){
      this.latitude=0;
      this.longitude=0;
    }
    if(this.address==undefined){
      this.address="";
    }

      if(this.latitude==0 && this.address!=""){
        this.geocoding();
      }
  }

  ngAfterViewInit(): void{
    this.initMap();
    if(this.search!="")
      this.geocoding();
  }

  

  private initMap(): void {
    this.map = L.map('map_container', {
      center: [ this.latitude, this.longitude ],
      zoom: 15
    });

    /*
    let options = {
      bingMapsKey: Globals.config.config['bingKey'],
      imagerySet: 'AerialWithLabels'
    };*/

    //const tiles = L.tileLayer.bing(options);
    const tiles = L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: 19,
      subdomains:['mt0','mt1','mt2','mt3']
    });


    tiles.addTo(this.map);

    let icon=L.icon({
      iconUrl:"assets/poi.png",
      iconSize:[64,64],
      iconAnchor:[32,32]
    })
    /*
    let icon=L.ExtraMarkers.icon({
      icon: 'fa-user',
      markerColor: 'blue',
      shape: 'square',
      prefix: 'fa'
      
    });
    */
  

    this.pin=L.marker(L.latLng(this.latitude, this.longitude ),{icon:icon,draggable:true });
    this.pin.addTo(this.map);
    
    this.pin.on("drag",this.onMarkerDragged.bind(this));
    this.pin.on("dragend",this.onMarkerDraggedEnd.bind(this));
    this.getCoordinate();
    
  }

  onMarkerDraggedEnd(event):void{
    let position=event.target.getLatLng();
    this.latitude=position.lat;
    this.longitude=position.lng;
    this.loading=true;
    //ricerca l'indirizzo
    let url="https://dev.virtualearth.net/REST/v1/Locations/"+this.latitude+","+this.longitude+"?key="+Globals.config.config['bingKey'];
    this.http.get<string>(url).toPromise<string>().then((value)=>{
      
      const r=value['resourceSets'][0]['resources'][0];
      this.search=r['name'];
      this.address=r.address.addressLine;
      this.city=r.address.locality;
      this.zip=r.address.postalCode;
      this.loading=false;
    }).catch(catchError(this.handleError));
  }

  onMarkerDragged(event):void{
    let position=event.target.getLatLng();
    this.latitude=position.lat;
    this.longitude=position.lng;
    
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    Globals.message.showToaster("Errore durante la connessione al server",ToastMode.DANGER);
    
    this.loading=false;

    return throwError(
      'Something bad happened; please try again later.');
    
  }

  getCoordinate(){
    this.btn_search_gps="Ricerca in corso..."
    this.getPosition().then(pos=>
      {
        this.btn_search_gps="Trova coordinate"
        this.latitude=pos.lat;
        this.longitude=pos.lng;
        let coord=L.latLng(this.latitude,this.longitude);
        this.map.setView(coord,18);
        this.pin.setLatLng(coord);
     });
  }

  getPosition(): Promise<any>
  {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });

  }

  private resetMap():void{
    this.map.invalidateSize();
  }

  geocoding():void{
    this.loading=true;
    let url="https://dev.virtualearth.net/REST/v1/Locations?query="+encodeURI(this.search)+"&key="+Globals.config.config['bingKey'];
    this.http.get<string>(url).toPromise<string>().then((value)=>{
      this.loading=false;
      if(value['errorDetails']){
        for(let e of value['errorDetails'])
          Globals.message.showToaster(e,ToastMode.DANGER);
        return;
      }
      const r=value['resourceSets'][0]['resources'][0];
      const c=r['point']['coordinates'];

        this.latitude=c[0];
        this.longitude=c[1];
        this.address=r.address.addressLine;
        this.city=r.address.locality;
        this.zip=r.address.postalCode;
        
        
        
        let coord=L.latLng(this.latitude,this.longitude);
        this.map.setView(coord,18);
        this.pin.setLatLng(coord);
    });
    

  }

}
