import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Globals } from 'projects/core/src/globals';
import { Type } from '../db/type';
import {WSService} from 'projects/core/src/include/service'

@Injectable({
  providedIn: 'root'
})

export class TypesService extends WSService<Type> {

  constructor() { super("types")}

  static types=[];

  
  getTypes(table:string):Observable<Type[]>{

    let types=[];
    for(let t of TypesService.types ){
      if(t.table==table){
        types=t.types;
      }
    }

    if(types.length==0){
      Globals.ws.requestUrl<Type[]>("types","getTypes",[table]).subscribe((items:any)=>{
        
        let found=false;
        for(let i=0;i<TypesService.types.length;i++ ){
          if(TypesService.types[i].table==table){
            TypesService.types[i].types=items;
            found=true;
          }
        }

        if(!found){
          let t:any={};
          t.table=table;
          t.types=items;
          TypesService.types.push(t);

        }
        
        
      });
      return Globals.ws.requestUrl<Type[]>("types","getTypes",[table]);

    }else{

      function sequenceSubscriber(observer: Observer<any[]>) {

        for(let i=0;i<TypesService.types.length;i++ ){
          if(TypesService.types[i].table==table){
            observer.next(TypesService.types[i].types);
          }
        }

       
        observer.complete();
        return {unsubscribe() {}};
      }
      
        return new Observable(sequenceSubscriber);
    }


   
    
  }

  delete(ids: string[]): Observable<Type> {
      TypesService.types=[];
      return super.delete(ids);
  }

  save(item: Type, return_id: Function, checkdeadlinesandactions?: boolean, showAfterMessage?: boolean): void {
      TypesService.types=[];  
      return super.save(item,return_id,checkdeadlinesandactions,showAfterMessage);
  }

}
