import { Component, OnInit } from '@angular/core';
import { Modal } from 'projects/core/src/include/modal';
import { ProductBrandsService } from '../../../services/productbrands.service';
import { Brand } from '../../../db/brand';
import { Globals } from 'projects/core/src/globals';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.sass']
})
export class BrandsComponent extends Modal implements OnInit {

  constructor(private productBrandsService:ProductBrandsService) {super() }

  list:Brand[];

  ngOnInit(): void {
   this.getItems();
  }

  getItems(){
    this.productBrandsService.getBrands(0).subscribe((items)=>{
      this.list=items;
    });
  }

  addBrand():void{
    let name=prompt("Inserisci il nome della marca");
    if(name!="" && name!=undefined){
      let r:Brand={} as Brand;
      r.name=name;
      this.productBrandsService.save(r,()=>{
        this.getItems();
      });      
    }
  }

  editBrand(record:Brand){
    let newName:string;
    newName=prompt("Nome della marca",record.name);
    if(newName!=record.name){
      record.name=newName;
      this.productBrandsService.save(record,(id)=>{
        this.getItems();
      });
    }
  }

  deleteBrand(record:Brand){
    Globals.modal.showConfirm("","Vuoi eliminare la marca "+record.name+" e tutti i modelli associati?",()=>{        
      this.productBrandsService.delete([record.id.toString()]).subscribe(()=>{
        this.getItems();
      });  
    },"Sì, elimina","No. Annulla","danger");
  }  

}
