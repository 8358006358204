import { formatDate } from '@angular/common';
import * as math from 'mathjs';
import { CustomField } from "./include/structures";


export class Helper{

  

  static getElementAttribute(name:string,attribute:string,defaultvalue:any=""){
    let result:any=defaultvalue;
    let searchtag=window.document.getElementsByTagName(name)[0];
    let query;
    if(searchtag){
      query=searchtag.attributes.getNamedItem(attribute)?.value.toString();
      if(query)
        result=query;
    }

    return result;
  }   

    //#region FIELD TOOLS

    public static getFieldbyString(fieldname:string,record:any){
      let value;
       let field_option=fieldname.split("|");
       fieldname=field_option[0].trim();

        if(fieldname=="now"){
          value= this.convertDateControl(null,true);
        }

        if(fieldname=="time"){
          value= this.currentTime();
        }

        if(fieldname=="now_only_date"){
          value= this.convertDateControl(null,false);
        }

        

        //prendi tutti i field che compongono la struttura
        let fields:string[]=fieldname.split(".");
        
        
        let r=record;
        
        for(let i=0;i<fields.length;i++){
    
          if(fields[i].includes("customfields")){
            //prendi il valore del campo
            let fieldnamecustom=fields[i].replace("customfields['","").replace("']","");
            fieldnamecustom=fieldnamecustom.replace("{{","");
            fieldnamecustom=fieldnamecustom.replace("}}","");
            
            //trova il campo
            for(let i=0;i<r['customfields'].length;i++){
              if(r['customfields'][i].type!="separator"){
                if(r['customfields'][i].label==fieldnamecustom){
                  value= r['customfields'][i].value;
                }
              }
            }
          }else{
            if(fields[i] in r)
              value= r[fields[i]];
          }
          if(fields[i] in r)
            r=r[fields[i]];
    
        }


        if(field_option.length>1){
          let format=field_option[1].split(":");
          if(format.length>1){
            switch(format[0].trim()){
              case "date":
                let d=Helper.convertString2Date(value);
                value = formatDate(value,format[1],"it-IT");
                break;
              case "uppercase":
                value=value.toUpperCase();
                break;
              case "lowecase":
                value=value.toLowerCase();
                break;
              
            }
          }


        }
    
        return value;
    
    }

    
    /**
     * Sostituisce le keyword presenti in "field" con i valori contenuti in "record"
     * @param {string} field
     * @param {any} record
     * @returns {string}
     */
     public static replaceKeyword(field:string,record:any):any{
      
      try{
        if(record==undefined)
          return field;



        //prendi tutti le keyword
        let keywords=field.match(/(?={{)(.+?)(?=}})/g);
        


        if(keywords!=null){
          for(let k=0;k<keywords.length;k++){
            keywords[k]=keywords[k].replace("{{","").replace("}}","");

            let v=Helper.getFieldbyString(keywords[k],record);
            field=field.replaceAll("{{"+keywords[k]+"}}",v);
          }
        }

        //verifica se ci sono campi con le formule
        let formule=field.match(/(?=\[\[)(.+?)(?=\]\])/g);
        if(formule!=null){
          for(let k=0;k<formule.length;k++){
            //calcola la formula e sostiusci i valori
            formule[k]=formule[k].replaceAll("[[","").replaceAll("]]","");
            
            //let calculated=DLMathEval.evaluateExpression(formule[k]==''?"":formule[k],null);
            let calculated=math.evaluate(formule[k]==''?"":formule[k]);
            
            field=field.replaceAll("[["+formule[k]+"]]",calculated.toString());
          }
        }


      }catch(e){
        return field;
      }
  
      return field;
    }


    /**
     * Sostituisce le keyword presenti in "field" con i valori contenuti in "record"
     * @param {string} field
     * @param {any} record
     * @returns {string}
     */
 /*
    public static replaceKeyword(field:string,record:any,idx:number=1,replaceKeyword:boolean=true){
    
      try{
          if(!record)
              return field;
          
          if(field=="")
              return field;

          field=field.replaceAll("\t","");


           //verifica se ci for
           const re_for = /{{for (.*)}}((.|\n)*){{\/for}}(\S|)/U;
           field=field.replaceAll("\r\n","\n");
           //$a=json_encode($field);

           var forArea;  
           while(forArea!=null){
               //cerca l'inizio del repeat
               const start_repeat=field.indexOf("{{for");
               const end_repeat=field.indexOf("/for}}")+7;
               const fieldfor= field.substring(start_repeat,end_repeat-start_repeat);
              
               forArea=fieldfor.matchAll(re_for);
               
               if(forArea!=null){
                   for(let k=0;k<forArea.length;k++){
                       const count=forArea[k][1].trim(); //origine per la ripetizione
                       const content=forArea[k][2];//prendi il contenuto
                       
                       var new_content="";
                       
                       
                       
                       for(let i=0;i<count.length;i++){
                               
                               new_content=new_content+this.replaceKeyword(content, record,idx+1,replaceKeyword);
                           
                       }
   
                       field= field.replace(forArea[k][0], new_content);
                      

                   }
               }
                   
           }

          //verifica se ci sono parti ripetibili nel template
          var re = /{{repeat (.*)}}((.|\n)*){{\/repeat}}(\S|)/U;
           
          var repeatArea; 
          while(repeatArea!=null){
              //cerca l'inizio del repeat
              const start_repeat=field.indexOf("{{repeat");
              const end_repeat=field.indexOf("/repeat}}")+10;
              const fieldrepeat=field.substring(start_repeat,end_repeat-start_repeat);
              
              
              repeatArea=fieldrepeat.matchAll(re);
              
              if(repeatArea!=null){
                  for(let k=0;k<repeatArea.length;k++){
                      const source=repeatArea[k][1].trim(); //origine per la ripetizione
                      const content=repeatArea[k][2];//prendi il contenuto
                      var new_content="";
                      
                      
                      var record_source=null;
                      
                      var fieldsource=this.getFieldbyString(source,record);
      
                      if(Array.isArray(fieldsource)){
                          record_source=fieldsource;
                      }else{
                          //prova a parserizzarla in JSON
                          try{
                              record_source=  JSON.parse(fieldsource);
                              if(!Array.isArray(record_source)){
                                  record_source=null;
                              }
                          }catch{
                              record_source=null;
                          }
                          
                      }
                      
                      
                      
                      if(record_source!=null){
                          for(let i=0;i<record_source.length;i++){
                              record_source[i]['idx']=i+1;
                              var next_idx=idx+1;
                              if(new_content==""){
                                var content_conditional;
                                  //var content_conditional=this.verifyConditional(content,record_source[i]);

                                  content_conditional=content_conditional.replace("{{repeat"+next_idx,"{{repeat");
                                  content_conditional=content_conditional.replace("{{/repeat"+next_idx+"}}","{{/repeat}}");
                                  
                                  new_content=this.replaceKeyword(content_conditional, record_source[i],idx+1,replaceKeyword);
                              }
                              else{
                                  //content_conditional=this.verifyConditional(content,record_source[i]);
                                  content_conditional=content_conditional.replace("{{repeat"+next_idx,"{{repeat");
                                  content_conditional=content_conditional.replace("{{/repeat"+next_idx+"}}","{{/repeat}}");
                                  new_content=new_content+"\n"+this.replaceKeyword(content_conditional, record_source[i],idx+1,replaceKeyword);
                              }
                          }
      
                          field=  field.replace(repeatArea[k][0], new_content);
                      }else{
                          field=  field.replace(repeatArea[k][0], "", field);
                      }

                  }
              }
                  
          }
          

           
         

              
          //verifica le condizionali
          field=this.verifyConditional(field,record);
                                   
          
          //prendi tutti le keyword
          re = /(?<={{)(.+?)(?=}})/;
          var keywords=field.matchAll(re);

          


          if(keywords!=null){
              for(let k=0;k<keywords;k++){
                  var key=keywords[k][0];
                  var v=this.getFieldbyString(key,record);
                  if($replaceKeyword){
                      $field=  str_replace("{{".$key."}}", $v, $field);
                  }else{
                      if($v!==""){
                          $field=  str_replace("{{".$key."}}", $v, $field);
                      }
                  }
                 
                  
              }
          }

      }catch(Exception $e){
          return $field;
      }

      return $field;
      
  }
*/
    /**
     * Restituisce il valore di una key all'interno di un record (ricerca del valore in profondità)
     * @param {string} key
     * @param {any} record
     * @returns {any}
     */
     public static searchKey(key:string,record:any){
    
      if(record==null)
        return null;
      //prendi tutte le keys
      let keys=Object.keys(record);
      
      //verifica se c'è la key ricercata
      for(let k of keys){
        if(k==key)
          return record[k];
        else{
          let t=typeof(record[k]);
          let value;
          if(t=="object"){
            value= this.searchKey(key,record[k]);
            if(value!=null)
              return value;
          }
        }
      }
      return null;


      
    }


    public static getCustomField(customfields:CustomField[],value:any):CustomField{
          
      if(typeof(value)=="string"){
      
        for(let c of customfields){
            if(c.label==value){
                return c;
            }
        }
      }
      else{
        for(let c of customfields){
          if(c.id==value){
              return c;
          }
        }
      }
      
      return null;
    }

    public static convertString2Date(d:string,sep:string="-"):Date{
      let date:Date=new Date();
      let arrayDateTime=d.split(" ");

      let array=arrayDateTime[0].split(sep);
      date.setDate(parseInt(array[2]));
      date.setMonth(parseInt(array[1])-1);
      date.setFullYear(parseInt(array[0]));
      
      if(arrayDateTime.length>1){
        let time=arrayDateTime[1].split(":");
        if(time.length>0) date.setHours(parseInt(time[0]));
        if(time.length>1) date.setMinutes(parseInt(time[1]));
        if(time.length>2) date.setSeconds(parseInt(time[2]));
      }
      return date;
  
    }

    public static convertDateControl<T>(d:Date=null,isFull=false):T {
    
      if(d==null)
        d=new Date();

      if(typeof(d) =="string")
          return d;
      
      let result:any=d.getFullYear().toString()+"-"+(d.getMonth()+1).toString().padStart(2, '0')+"-"+d.getDate().toString().padStart(2, '0');
  
      if(isFull)
        result=result+" "+d.getHours().toString()+":"+d.getMinutes().toString()+":"+d.getSeconds().toString();
      return result;
  

    }

    public static currentTime():string {
    
     
      let  d=new Date();
      return d.getHours()+":"+d.getMinutes()+":"+d.getSeconds();
   
    }
    //#endregion

    public static getTypeDocument(type){
      switch (type){
          case 1: return "Fattura";
          case 2: return "Fattura accompagnatoria";
          case 3: return "Nota di credito";
          case 4: return "Preventivo";
          case 5: return "DDT";
          case 6: return "Scontrino";
          case 7: return "Ordine cliente";
          case 8: return "Ordine fornitore";
          case 9: return "Non fiscale";
          case 10: return "Trasferimento";
          case 11: return "Documento amministrativo";
          case 12: return "Fattura di acconto";
          case 13: return "Inventario"
      }
  }


  public static getAddressItem(table,reference){
    switch(table){
      case "installations":
        return reference.shop.addressItem;
      case "addresses":
         return reference;
      case "contracts":
        return reference.shop.addressItem;
      case "shops":
        return reference.addressItem;
      case "documents":
        return reference.addressItem;
      case "activities":
        return this.getAddressItem(reference.table,reference.reference);
    }

    return 0;
  }


  public static getEquivalentDiscount(discount1,discount2,discount3){
    let s1=discount1/100;
    let s2=discount2/100;
    let s3=discount3/100;
    let s=s1+s2+s3-s1*s2-s2*s3-s1*s3+s1*s2*s3;

    return s*100;
  }


  public static setValueToRecord(record:any,fieldname:string,value:any){
    

    if(fieldname.includes("customfields")){
      //prendi il valore del campo
      let fieldnamecustom=fieldname.replace("customfields[","").replace("]","");
      fieldnamecustom=fieldnamecustom.replace("{{","");
      fieldnamecustom=fieldnamecustom.replace("}}","");
      fieldnamecustom=fieldnamecustom.replace("'","");
      if(parseInt(fieldnamecustom)>0){ //se è indicato l'id del customfield

        //cerca il customfield all'interno del record
        for(let i=0;i<record['customfields'].length;i++){
          if(record['customfields'][i]['id']==fieldnamecustom){
            record['customfields'][i]['value']=value;
          }
        }

      }else{ //se è indicato l'etichetta
        for(let i=0;i<record['customfields'].length;i++){
          if(record['customfields'][i]['label']==fieldnamecustom){
            record['customfields'][i]['value']=value;
          }
        }
      }

    }else{
      if(record[fieldname])
        record[fieldname]=value;
    }

  }
}