import { Component, OnInit,Input } from '@angular/core';
import { QrcodereaderComponent } from 'projects/core/src/common/qrcodereader/qrcodereader.component';
import { Globals } from 'projects/core/src/globals';
import { ToastMode } from 'projects/core/src/include/structures';

@Component({
  selector: 'app-missing-values',
  templateUrl: './missing-values.component.html',
  styleUrls: ['./missing-values.component.css']
})
export class MissingValuesComponent implements OnInit {

  @Input()
  list:any[]=[];

  constructor() { }

  

  ngOnInit(): void {
      
  }

  ngOnChanges(): void {

      if(this.list){
        for(let i=0;i<this.list.length;i++){
          if(this.list[i].type=="contact"){
            this.list[i].value1="";
            this.list[i].value2="";
            
            let s=this.list[i].value.split(";");
            
            if(s.length>1){
              this.list[i].value1=s[0];
              this.list[i].value2=s[1];
            }
          }
        }
      }


  }

  convertTableToName(table:string){
    switch(table){
      case "addresses":
        return "Anagrafica";
      case "installations":
        return "Prodotto";
      case "shops":
        return "Sede";
      case "contracts":
        return "Contratto";

    }
  }

  getFieldName(field){
    return "missingValues_"+field;
  }


  getCoordinate(){
    Globals.message.showToaster("Ricerca in coordinate in  corso...",ToastMode.INFO);
    this.getPosition().then(pos=>
      {
        for(let m of this.list){
            if(m['field']=="latitude")
              m['value']=pos.lat;
            if(m['field']=="longitude")
              m['value']=pos.lng;
        }

      
        
     });
  }

  getPosition(): Promise<any>
  {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

          resolve({lng: resp.coords.longitude, lat: resp.coords.latitude});
        },
        err => {
          reject(err);
        });
    });

  }

  scan(field){
   
    Globals.modal.showModal(QrcodereaderComponent,[],(instance)=>{
      if(instance!=null){
        for(let f of this.list){
          if(f['field']==field){
            f['value']=instance['code'];
            return;
          }
        }
       
      }
    });
  }

  editContact(record){
    
    record.value=record.value1+";"+record.value2;

  }


}
