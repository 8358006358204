import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Globals } from 'projects/core/src/globals';

const routes: Routes = [];





routes.push({ path: '', loadChildren: () => import('projects/core/src/common/login/login.module').then(m => m['LoginModule']) });
routes.push({ path: 'dashboard', loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule) });
routes.push({ path: 'alarms', loadChildren: () => import('./views/alarms/alarms.module').then(m => m.AlarmsModule) });

routes.push({ path: 'address', loadChildren: () => import('./views/address/address.module').then(m => m.AddressModule) });
routes.push({ path: 'cash-flow', loadChildren: () => import('./views/cash-flow/cash-flow.module').then(m => m.CashFlowModule) });
routes.push({ path: 'inventories', loadChildren: () => import('./views/inventories/inventories.module').then(m => m.InventoriesModule) });
routes.push({ path: 'products', loadChildren: () => import('./views/products/products.module').then(m => m.ProductsModule) });
routes.push({ path: 'shops', loadChildren: () => import('./views/shops/shops.module').then(m => m.ShopsModule) });
routes.push({ path: 'documents', loadChildren: () => import('./views/documents/documents.module').then(m => m.DocumentsModule) });
routes.push({ path: 'contracts', loadChildren: () => import('./views/contracts/contracts.module').then(m => m.ContractsModule) });
routes.push({ path: 'monitoring', loadChildren: () => import('./views/monitoring/monitoring.module').then(m => m.MonitoringModule) });
routes.push({ path: 'deadlines', loadChildren: () => import('./views/deadlines/deadlines.module').then(m => m.DeadlinesModule) });
routes.push({ path: 'activities', loadChildren: () => import('./views/activities/activities.module').then(m => m.ActivitiesModule) });

routes.push({ path: 'settings', loadChildren: () => import('projects/core/src/settings/settings.module').then(m => m.SettingsModule) });
routes.push({ path: 'settings', loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule) });
routes.push({ path: 'booking', loadChildren: () => import('./views/booking/booking.module').then(m => m.BookingModule) });
routes.push({ path: 'assistances', loadChildren: () => import('./views/assistances/assistances.module').then(m => m.AssistancesModule) });
routes.push({ path: 'bustickets', loadChildren: () => import('projects/modules/src/app/bustickets/bustickets.module').then(m => m.BusticketsModule) });
routes.push({ path: 'events', loadChildren: () => import('./views/events/events.module').then(m => m.EventsModule) });
routes.push({ path: 'forms', loadChildren: () => import('./views/forms/forms.module').then(m => m.FormsValuesModule) });
routes.push({ path: 'project', loadChildren: () => import('./views/projects/projects.module').then(m => m.ProjectsModule) });
routes.push({ path: 'financialaccounts', loadChildren: () => import('./views/financialaccounts/financialaccounts.module').then(m => m.FinancialaccountsModule) });
routes.push({ path: 'ads', loadChildren: () => import('projects/modules/src/app/ads/ads.module').then(m => m.AdsModule) });
routes.push({ path: 'dossier', loadChildren: () => import('projects/modules/src/app/dossier/dossier.module').then(m => m.DossierModule) });
routes.push({ path: 'repository', loadChildren: () => import('./views/repository/repository.module').then(m => m.RepositoryModule) });
routes.push({ path: 'map', loadChildren: () => import('projects/modules/src/app/map/map.module').then(m => m.MapModule) });



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 

  constructor(){
    
  }

}
