import { NgModule } from '@angular/core';
import { UploadFileComponent } from './upload-file.component';
import {ModalModule} from '../modal/modal.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';


@NgModule({
   imports:[FormsModule,CommonModule,ModalModule],
   exports: [UploadFileComponent],
   declarations: [UploadFileComponent],
   providers: [],
})
export class UploadFileModule{ }