<div class="modal-header">
    <b>Crea nuove attività</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="close()" >
          <i class="fas fa-times"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            <table class="table">
                <thead>
                    <th>Tipo</th>
                    <th>Data</th>
                    <th>Utente</th>
                    <th>Note</th>
                </thead>
                <tbody>
                    <tr *ngFor="let r of list">
                       
                        <td>
                            <select class="form-control form-control-sm" [(ngModel)]="r.id_type">
                                <option *ngFor="let t of types" value="{{t.id}}">{{t.name}}</option>
                            </select>
                        </td>
                        <td>
                            <input type="date" class="form-control form-control-sm" [(ngModel)]="r.date">
                        </td>
                        <td>
                            <select class="form-control form-control-sm" [(ngModel)]="r.id_user">
                                <option *ngFor="let u of users" [ngValue]="u.id">{{u.username}}</option>
                            </select>
                        </td>
                        <td>
                            <input type="text" class="form-control form-control-sm" [(ngModel)]="r.note">
                        </td>
                        <td>
                            <span *ngIf="!r.exist" class="badge badge-success">Crea nuova attività</span>
                            <span *ngIf="r.exist" class="badge badge-warning">Aggiorna attività esistente</span>
                            
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-success" (click)="confirm()">Crea</button>
    <button class="btn btn-secondary" (click)="close()">Annulla</button>
</div>