import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { ConfirmmodalComponent } from "./common/confirmmodal/confirmmodal.component";


@Injectable()
export class ModalWindow{

    

    currentModal:string="";
    selectedRecordFromModal:Function;
    titleModal:string="";
    tableModal:string="";

    

    constructor(
        private modalService: NgbModal,
    ){}
    
   

    showModal(component:any,variables:any[],onComplete,size="xl",windowClass=""){
        
       
        let m=this.modalService.open(component,{size:size,backdrop:'static',windowClass:windowClass});

        for(let i=0;i<variables.length;i++){
            m.componentInstance[variables[i]['name']]=variables[i]['value'];
        }
        if(m.componentInstance){
         m.componentInstance['modalWindow']=m;
         m.componentInstance['isModal']=true;
        }
        
        m.result.then((r)=>{
        //quando si chiude la modal
        if(r=="success"){
            onComplete(m.componentInstance);
        }
            
        else{
            onComplete(null);
        }
            
        
        }).catch((r)=>{
            if(r=="success"){
                onComplete(m.componentInstance);
            }
                
            else{
                onComplete(null);
            }
        });
        //this.modalRef=m;
        return m;
    }


    showConfirm(title,description,callback,lblConfirmButton="Ok",lblCancelButton="Annulla",confirmButtonType="success",callback_cancel=null){
        
        // lblConfirmButton = '' per non visualizzarlo
        // lblCancelButton = '' per non visualizzarlo
        // confirmButtonType = [primary, secondary, info, warning, danger]

       this.showModal(ConfirmmodalComponent,[
        {"name":"title","value":title},
        {"name":"description","value":description},
        {"name":"lblConfirmButton","value":lblConfirmButton},
        {"name":"lblCancelButton","value":lblCancelButton},
        {"name":"confirmButtonType","value":confirmButtonType}

       ],(instance)=>{
            if(instance!=null){
                callback();
            }else{
                if(callback_cancel)
                    callback_cancel();
            }
            
        
       },"sm")
    
    }

    // deprecabile?
    showAlert(title,description,callback){
        
        this.showModal(ConfirmmodalComponent,[
         {"name":"title","value":title},
         {"name":"description","value":description},
         {"name":"lblConfirmButton","value":''},
         {"name":"lblCancelButton","value":"Ok"}
         
        ],(instance)=>{
                 callback();   
        },"sm")
     
     }
    

}