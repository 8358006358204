import { Component, OnInit } from '@angular/core';
import { DocumentsService } from '../../../services/documents.service';
import { Globals } from 'projects/core/src/globals';

@Component({
  selector: 'app-allinvoices',
  templateUrl: './allinvoices.component.html',
  styleUrls: ['./allinvoices.component.css']
})

export class AllinvoicesComponent implements OnInit {
  list=[];
  loading=true;
  date_search=new Date().toMysqlDate();
  constructor(
    private documentsService:DocumentsService
  ) { }

  ngOnInit(): void {
    this.getList();
  }

  getList(){
    this.loading=true;
    this.documentsService.verifyAllInvoice(this.date_search).subscribe((items)=>{
      this.list=items;
      this.loading=false;
    });
  }


  cancel(){
    this['modalWindow'].close();
  }

}
