import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ModelList } from '../../../../../../core/src/include/modellist';
import { Filter, FilterMode, Form } from '../../../../../../core/src/include/structures';
import { FormsService } from '../../../../../../core/src/lib/forms.service';
import { FormSettingDetailComponent } from './form-detail/form-detail.component';

@Component({
  selector: 'app-form-settings',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.css']
})
export class FormsSettingsComponent extends ModelList<Form>implements OnInit {

  filter_search:Filter=new Filter();
  filter_status:Filter=new Filter();

  constructor(
    private formsService: FormsService,
    private fb1: UntypedFormBuilder,
  
  ) {
    super(formsService,fb1);
    this.detailViewModal=FormSettingDetailComponent;
   }

  ngOnInit(): void {

    this.title='Form';
    super.ngOnInit.apply(this, arguments); 


    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("f.name");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("f.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    this.getItems();
  }

}
