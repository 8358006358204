import { Component, OnInit, Input,AfterViewInit } from '@angular/core';
import { ModelList } from 'projects/core/src/include/modellist'
import { UntypedFormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { TypesService } from '../../../services/types.service';
import { Type } from '../../../db/type';
import { Globals } from 'projects/core/src/globals';

import { ShopsService } from '../../../services/shops.service';
import { Shops } from '../../../db/shops';
import { NotificationService } from '../../../services/notification.service';
import { ShopsDetailsComponent } from '../shops-details/shops-details.component';
import { Filter, FilterMode } from 'projects/core/src/include/structures';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent extends ModelList<Shops> implements OnInit {

 
  @Input()
  id_address:number=0;

  

  view:number=1;

  type_shops:Type[];
  status_shops:Type[];
  filter_search:Filter=new Filter();
  filter_city:Filter=new Filter();
  filter_status:Filter=new Filter();
  filter_id_address:Filter=new Filter();
  

  
  
  private map:L.Map;
  markerLayer:L.LayerGroup;
   
  isFilterSet=false;

  constructor(
    private fb1: UntypedFormBuilder,
    private shopsService: ShopsService,
    private typesService: TypesService,
    private notificationService:NotificationService,
    
  ) {
    super(shopsService,fb1);
    this.detailViewModal=ShopsDetailsComponent;
   }



  ngOnInit(): void {
    this.title = 'Sede';

    super.ngOnInit.apply(this, arguments);

  
  
    this.typesService.getTypes("shops").subscribe((items)=>{
      this.type_shops=items;
    });

    
    

    
    if(this.mode!="embedded") //se incoroporato in una finestra non avviare subito la ricerca, ma aspetta che il filtro sia attivo
      this.updateList(this.id_address);

    

  }

  setFilters():void{
    this.filter_id_address.mode=FilterMode.normal;
    this.filter_id_address.fields=[];
    this.filter_id_address.fields.push("s.id_address");
    this.filter_id_address.value="";
    this.filter.push(this.filter_id_address);
    

    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("s.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    

    this.filter_city.mode=FilterMode.normal;
    this.filter_city.fields=[];
    this.filter_city.fields.push("s.city");
    this.filter_city.value="";
    this.filter.push(this.filter_city);

    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("s.name");
    this.filter_search.fields.push("s.address");
    this.filter_search.fields.push("s.city");
    this.filter_search.value="";
    this.filter.push(this.filter_search);
  

    Globals.navigation.getStateFilters(this.constructor.name,this.filter);

    this.ordering.field="s.name";

    this.isFilterSet=true;
  }

  ngOnChanges():void{
    if(this.id_address>0) //avvia la ricerca quando il filtro è attivo
      this.updateList(this.id_address);
  }

  
  updateList(id_address:number):void{
    if(!this.isFilterSet)
      this.setFilters();

    if(id_address>0){
      this.filter_id_address.value=id_address.toString();
    }

    
    this.getItems();
  }


  // switch lista/mappa
  setView(e):void{
    
    this.view=e;
    
      

  }




  notify(record:Shops){

    this.notificationService.showPreviewNotification(record.contacts,record,"shops",()=>{

    });
    
    
    
    
  }

}
