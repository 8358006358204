import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ToastMode,Notification } from '../include/structures'
import { WSService } from '../include/service'
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends WSService<Notification> {

  constructor() { super("notifications") };

  getNotifications(table:string):Observable<Notification[]>{
    return  Globals.ws.requestUrl<Notification[]>("notifications","getItems",["n.table='"+table+"'"]);
    
  }

  sendNotify(item:Notification,id_address:number,onComplete:Function){
    
    item['id_address']=id_address;
    var item2send:any=item;
    if(Globals.ws.api_version=="v2")
      item2send={"values":item2send};

    return Globals.ws.send(this.table,"send",item2send,[id_address.toString()],(result:string)=>{
      let obj=JSON.parse(result);
      let messages:string[]=[];

      obj['email']==true?messages.push("Email: Ok"):messages.push("Email: Error");
      obj['sms']==true?messages.push("SMS: Ok"):messages.push("SMS: Error");
      obj['whatsapp']==true?messages.push("whatsapp: Ok"):messages.push("whatsapp: Error");
      
      


      Globals.message.showToaster("Invio effettuato correttamente\n"+messages.join("\n"),ToastMode.SUCCESS);
    });
  }

}
