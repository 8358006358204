
<div class="row">
    <div class="col-12">
        <ng-container *ngIf="user_created">
            <label>Creato da {{user_created.name}} <span *ngIf="record.created">il {{record.created}}</span> </label>
        </ng-container>
    </div>
    <div class="col-12">
        <ng-container *ngIf="user_lastupdate">
            <label>Modificato da {{user_lastupdate.name}}  <span *ngIf="record.lastupdate">il {{record.lastupdate}}</span></label>
        </ng-container>
    </div>

</div>
<ng-container *ngIf="record">
    
    {{updateUser()}}
</ng-container>