import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Globals } from 'projects/core/src/globals';
import { Brand } from '../db/brand';

import { WSService } from 'projects/core/src/include/service'
@Injectable({
  providedIn: 'root'
})
export class ProductBrandsService extends WSService<Brand> {

  static brands:Brand[]=[];
  constructor() { super("productbrands")};

  getBrands(id_category:number=0):Observable<Brand[]>{

    let brands:Brand[]=[];
    for(let b of ProductBrandsService.brands){
      
      if(id_category>0){
        if(b.id_category==id_category){
          brands.push(b);
        }
      }else{
          brands=ProductBrandsService.brands;
        }
    }

    if(brands.length>0){
        function sequenceSubscriber(observer: Observer<Brand[]>) {
          observer.next(brands);
          observer.complete();
          return {unsubscribe() {}
        };
      }
      return new Observable(sequenceSubscriber);
    }else{
      Globals.ws.requestUrl<Brand[]>("productbrands","getItems",[]).subscribe((items)=>{
        ProductBrandsService.brands=items;
      });

      return Globals.ws.requestUrl<Brand[]>("productbrands","getItems",[id_category.toString()]);
       
      
    }




   
    
    
    
  }
}
