import { Output,EventEmitter, Injectable } from '@angular/core';
import {Paho} from 'ng2-mqtt/mqttws31';
import { Globals } from 'projects/core/src/globals';


import {
  IMqttMessage,
  IMqttServiceOptions,
  MqttService,
  IPublishOptions,
 } from 'ngx-mqtt';
 import { IClientSubscribeOptions } from 'mqtt-browser';
 import { Subscription } from 'rxjs';


@Injectable()
export class C1Connector{

    public connected:boolean=false;
    public autoreconnect:boolean=true;

    @Output()
    onMessageReceived:EventEmitter<any>=new EventEmitter<any>();

    @Output()
    onConnectedSuccefully:EventEmitter<any>=new EventEmitter<any>();

    @Output()
    onDisconnected:EventEmitter<any>=new EventEmitter<any>();

    public c1connectorid:string="";
    //client;

    client: MqttService | undefined;

    constructor(){
      
    }

    
    
    public connectMqtt(){
      let clientid=btoa(Math.random().toString()+Date.now.toString());
      this.c1connectorid=Globals.user['shop']?Globals.user['shop'].getParam("c1connectorid"):Globals.config.config['c1connectorid'];
      
      let connection = {
        hostname: Globals.config.config['c1connector_serverurl'],
        port: Globals.config.config['c1connector_serverport'],
        path: '/mqtt',
        clean: true, // Retain session
        connectTimeout: 4000, // Timeout period
        reconnectPeriod: 4000, // Reconnect period
        // Authentication information
        clientId: clientid,
        username: Globals.config.config['c1connector_username'],
        password: Globals.config.config['c1connector_password'],
        protocol: 'wss',
       }


      try {
        this.client=new MqttService(connection as IMqttServiceOptions);
        this.client?.connect(connection as IMqttServiceOptions)
     } catch (error) {
        console.log('mqtt.connect error', error);
     }


    this.client?.onConnect.subscribe(() => {
      
      this.connected=true;
      this.onConnectedSuccefully.emit();
      console.log('Connection succeeded!');
   });
    this.client?.onError.subscribe((error: any) => {
      console.log('Connection failed', error);
   });

   this.client?.onPacketreceive.subscribe((packet:any)=>{
    //console.log(packet);
   });

   let subscription = this.client.observe(this.c1connectorid).subscribe((message: IMqttMessage) => {
    //console.log(message.payload.toString());
  });


    this.client?.onMessage.subscribe((packet: any) => {
      console.log(packet);
      let msg=JSON.parse(packet.payload.toString());
          
      this.onMessageReceived.emit(msg);
      
     

      console.log(`Received message ${packet.payload.toString()} from topic ${packet.topic}`)
   })

   
    }


    sendMessage(message: any) {
      
      this.client.publish(this.c1connectorid,JSON.stringify(message)).subscribe((result)=>{
        
      });
      
    }

    
   /*
    public connectMqtt(){
      let clientid=btoa(Math.random().toString()+Date.now.toString());
      this.c1connectorid=Globals.user['shop']?Globals.user['shop'].getParam("c1connectorid"):Globals.config.config['c1connectorid'];
      this.client = new Paho.MQTT.Client(Globals.config.config['c1connector_serverurl'], Globals.config.config['c1connector_serverport'],clientid);
      
        this.onMessage();
        this.onConnectionLost();
        this.client.connect({
          onSuccess: this.onConnected.bind(this),
          useSSL:true,
          userName:Globals.config.config['c1connector_username'],
          password:Globals.config.config['c1connector_password']
        });
        
    
    }

    onConnected() {
    
        console.log("Connected");
        this.client.subscribe(this.c1connectorid);
        this.connected=true;
        this.onConnectedSuccefully.emit();

    }

    onError(){
      console.log("Error");
    }

    sendMessage(message: any) {
        let packet = new Paho.MQTT.Message(JSON.stringify(message));
        packet.destinationName = this.c1connectorid;
        this.client.send(packet);
        
    }

    onMessage() {
        this.client.onMessageArrived = (message: Paho.MQTT.Message) => {
          let msg=JSON.parse(message.payloadString);
          
          this.onMessageReceived.emit(msg);
          
          console.log('Message arrived : ' + message.payloadString);
        };
    }

    onConnectionLost() {
        this.client.onConnectionLost = (responseObject: Object) => {
          this.connected=false;
          this.onDisconnected.emit();
          //  console.log('Connection lost : ' + JSON.stringify(responseObject));
          if(this.autoreconnect){
            this.connectMqtt();
          }

        };
    }

    */
    
}