<div class="modal-header">    
    <b>Carica file</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="close()" >
            <i class="fas fa-times"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <form (ngSubmit)="OnSubmit()">
        <p>{{description}}</p>        
        <div class="row">
            <div class="alert alert-info" *ngIf="progress>0">
                Caricamento in corso ({{progress}} %)
            </div>
            <div class="col-12 text-center" *ngIf="!readonly">
                <div class="m-auto text-center p-2 pointer" style="width:90%; height:200px; color:#ccc; border: 1px dashed #ccc; border-radius:10px">
                    <i class="fas fa-2x fa-bullseye mt-5"></i><br>
                    <i>Trascina e rilascia <b>qui</b>.</i><br>
                    <button  class="btn btn-secondary btn-sm mt-4" type="submit"><i class="fas fa-upload mr-2"></i>Carica file</button>
                </div>
                <input style="display: block; opacity: 0; width: 90%; height: 200px; margin: -200px auto 0;" id="file-input" accept="{{extensions}}" #fileToUpload name="fileToUpload"  (change)="handleFileInput($event.target.files)" type="file" />
            </div>
        </div>        
    </form>
</div>
<div class="modal-footer">
    <button class="btn btn-secondary btn-sm" (click)="close()">Chiudi</button>
</div>

