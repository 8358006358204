<div class="modal-header">
    <b>Conferma azione operatore</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="close()" >
            <i class="fas fa-times"></i>
        </button>
    </div>    
</div>
<div class="modal-body">
    <ng-container *ngIf="record.user_action.action=='substitution'">
        <div class="row">
            <div class="col-12">
                <user-action-substitution [mode]="'embedded'" [activity]="record"  [item]="record.reference"></user-action-substitution>          
                Magazzino di destinazione
                <div class="input-group">
                    <input type="text" class="form-control form-control-sm" readonly="readonly" [(ngModel)]="shopname">
                    <div class="input-group-append">
                        <button class="btn btn-default" (click)="selectIDShop()">Scegli</button>
                    </div>
                </div>
                Modifica la tipologia in
                <div class="input-group">
                    <select class="form-control form-control-sm" [(ngModel)]="id_product_type">
                        <option *ngFor="let t of product_types" value="t.id">{{t.label}}</option>
                    </select>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="record.user_action.action=='retired'">
        <div class="row">
            <div class="col-12">
                <user-action-retired [mode]="'embedded'" [activity]="record" [item]="record.reference"></user-action-retired>
                Magazzino di destinazione
                <div class="input-group">
                    <input type="text" class="form-control form-control-sm" readonly="readonly" [(ngModel)]="shopname">
                    <div class="input-group-append">
                        <button class="btn btn-default" (click)="selectIDShop()">Scegli</button>
                    </div>
                </div>                
                Modifica la tipologia in
                <div class="input-group">
                    <select class="form-control form-control-sm" [(ngModel)]="record.user_action.id_product_type_retired">
                        <option *ngFor="let t of product_types" [value]="t.id">{{t.name}}</option>
                    </select>
                </div>
            </div>            
        </div>
    </ng-container>
</div>

<div class="modal-footer">
    <button class="btn btn-sm btn-success" (click)="confirm()">Conferma</button>
    <button class="btn btn-sm btn-secondary" (click)="close()">Annulla</button>
</div>
