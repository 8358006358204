import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Activities } from 'projects/c1-backend/src/app/db/activities';
import { Installation } from 'projects/c1-backend/src/app/db/installation';
import { InstallationsService } from 'projects/c1-backend/src/app/services/installations.service';
import { QrcodereaderComponent } from 'projects/core/src/common/qrcodereader/qrcodereader.component';
import { Globals } from 'projects/core/src/globals';

@Component({
  selector: 'user-action-retired',
  templateUrl: './retired.component.html',
  styleUrls: ['./retired.component.css']
})
export class RetiredComponent implements OnInit {

  @Input()
  mode="modal";

  @Input()
  item:Installation=new Installation();

  @Input()
  activity:Activities=new Activities();
  
  products=[];
  serials=[];

  listInventoryStep=1;

  constructor(    
    private installationsService:InstallationsService
    ) { }

  ngOnInit(): void {

    
    if(this.activity.user_action==null || this.activity.user_action=='null' || !this.activity.user_action['action']){
      this.activity.user_action={};
      this.activity.user_action['table']="installations";
      this.activity.user_action['action']="retired";

      this.activity.user_action['products']=[];

      this.addProduct(this.item.sn);

      this.activity.user_action['id_shop_to']=this.item.id_shop;
    }
    
    
  }

  addProduct(sn:string=""){
    
    if(sn=="")
      sn=prompt("Inserire il numero seriale del prodotto");

    if(sn!=null){
    
      //verifica se il prodotto è presente 
      this.checkProduct(sn,(result)=>{
        let p={};
        p['oldproduct']=sn;
        p['isexist']=result[0];
        p['product']={
          "brand":result[1].brand,
          "model":result[1].model,
          "category":result[1].category
        };


        if(!this.activity.user_action['products'])
          this.activity.user_action['products']=[];
        this.activity.user_action['products'].push(p);
      })
      
    }
  }

  checkProduct(sn:string,oncomplete){
    return this.installationsService.getSN(sn).subscribe((result)=>{
      if(result && result.length>0)
        oncomplete([true,result[0]]);
      else
        oncomplete([false,null]);
    });
   }

  addProductQrCode(){
    Globals.modal.showModal(QrcodereaderComponent,[
      {"name":"mode","value":"modal"}
    ],(instance)=>{
      if(instance!=null){
        this.addProduct(instance['code']);
      }
    });
  }

  removeProduct(p){
    for(let i=0;i<this.activity.user_action['products'].length;i++){
      if(this.activity.user_action['products'][i]==p){
        this.activity.user_action['products'].splice(i,1);
        return;
      }
    }
  }
  

  cancel(){
    this['modalWindow'].close();
  }

  confirm(){
    this.cancel();
  }

  

}
