import { NgModule } from '@angular/core';
import { CashFlowContainerComponent } from './cash-flow.component';
import { CashFlowDetailsComponent } from './cash-flow-details/cash-flow-details.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PreviewnotificationModule } from '../../modules/previewnotification/previewnotification.module';
import { WindowlistModule } from 'projects/core/src/common/windowlist/windowlist.module';
import { CitiesModule } from '../../modules/cities/cities.module';
import { PaginationModule } from 'projects/core/src/common/pagination/pagination.module';
import { AdvancedsearchModule } from '../../modules/advancedsearch/advancedsearch.module';
import { CashFlowRoutingModule } from './cash-flow-routing.module';
import { ContactModule } from '../../modules/contact/contact.module';
import { AddressModule } from '../address/address.module';
import { ToolbarmodelModule } from 'projects/core/src/common/toolbarmodel/toolbarmodel.module';
import { SelectfieldcustomModule } from 'projects/core/src/common/selectfieldcustom/selectfieldcustom.module';
import { NoteboxModule } from '../../modules/notebox/notebox.module';
import { WindowModule } from 'projects/core/src/common/window/window.module';
import { FilterdateModule } from '../../modules/filterdate/filterdate.module';
import { ConfirmcashflowComponent } from './confirmcashflow/confirmcashflow.component';
import { ModalModule } from 'projects/core/src/common/modal/modal.module';
import { ReferenceModule } from '../../modules/reference/reference.module';
import { CashFlowOverviewComponent } from './cash-flow-overview/cash-flow-overview.component';
import { CashFlowComponent } from './cash-flow-list/cash-flow-list.component';
import { CashregisterComponent } from './cashregister/cashregister.component';
import { CashregisterDetailComponent } from './cashregister/cashregister-detail/cashregister-detail.component';
import { CustomfieldsModule } from 'projects/core/src/common/customfields/customfields.module';
import { FinancialaccountModule } from '../../modules/financialaccount/financialaccount.module';
import { Globals } from 'projects/core/src/globals';
import { FiltercontainerModule } from '../../modules/filtercontainer/filtercontainer.module';
import { WindowlistbuttonsModule } from 'projects/core/src/common/windowlistbuttons/windowlistbuttons.module';
import { CashflowAnalyticsComponent } from './analytics/analytics.component';
import { NgChartsModule } from 'ng2-charts';


@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        PreviewnotificationModule,
        CustomfieldsModule,
        WindowlistModule,
        WindowModule,
        CitiesModule,
        PaginationModule,
        AdvancedsearchModule,
        CashFlowRoutingModule,
        ContactModule,
        ToolbarmodelModule,
        SelectfieldcustomModule,
        ContactModule,
        NoteboxModule,
        FilterdateModule,
        ModalModule,
        ReferenceModule,
        FinancialaccountModule,
        FiltercontainerModule,
        WindowlistbuttonsModule,
        NgChartsModule

    ],
   exports: [CashFlowComponent,CashFlowDetailsComponent,ConfirmcashflowComponent,CashFlowOverviewComponent,CashflowAnalyticsComponent],
   declarations: [CashFlowComponent,CashFlowDetailsComponent,ConfirmcashflowComponent, CashFlowOverviewComponent, CashFlowContainerComponent, CashregisterComponent, CashregisterDetailComponent,CashflowAnalyticsComponent],
   providers: [],
})
export class CashFlowModule{ 


    constructor(){
        Globals.parameters.loadModulesEvent.subscribe((result)=>{
            Globals.parameters.createParam(
                "cash-flow",
                "sources",
                "Casse / Banche",
                "table",
                [
                    {"name":"description","label":"Conto"},
                ]
            );

            Globals.parameters.createParam(
                "cash-flow",
                "sourcesmethodpayment",
                "Associazione automatica metodo di pagamento con casse / banche",
                "table",
                [
                    {"name":"source","label":"Cassa / Banca"},
                    {"name":"methodpayment","label":"Metodo di pagamento"}
                ]
            );

            Globals.parameters.createParam(
                "cash-flow",
                "show_paid",
                "Mostra il campo 'versato'",
                "boolean"
            );

            Globals.parameters.createParam(
                "cash-flow",
                "id_product_document",
                "ID prodotto per la creazione del documento",
                "number"
            );
            
            Globals.parameters.createParam(
                "cash-flow",
                "default_modeview",
                "Modalità di visualizzazione di default",
                "select",
                [
                    {"label":"Tutti","value":0},
                    {"label":"Solo quelli dell'anno corrente","value":1},
                    {"label":"Solo gli ultimi 365 giorni","value":2}
                ]
            );

            Globals.permissions.addPermissionRule("cash-flow","save","Salvataggio");
            Globals.permissions.addPermissionRule("cash-flow","delete","Eliminazione");
            Globals.permissions.addPermissionRule("cash-flow","showCreatedFrom","Mostra sedi");
            Globals.permissions.addPermissionRule("cash-flow","showFinancialAccount","Mostra conti contabili");
            Globals.permissions.addPermissionRule("cash-flow","showUsers","Modifica assegnazione utente");

        });


        
        
    }
}