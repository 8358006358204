import { Component, OnInit } from '@angular/core';
import { ModelList } from '../../include/modellist';
import { Tax } from 'projects/c1-backend/src/app/db/tax';
import { UntypedFormBuilder } from '@angular/forms';
import { TaxesService } from 'projects/c1-backend/src/app/services/taxes.service';
import { Filter, FilterMode } from '../../include/structures';

@Component({
  selector: 'lib-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.css']
})
export class TaxesComponent extends ModelList<Tax> implements OnInit {

  filter_search:Filter=new Filter();

  selectedTax:Tax={} as Tax;

  constructor(
    private fb1: UntypedFormBuilder,
    private taxService: TaxesService,
  ) { 
    super(taxService,fb1);
  }

  ngOnInit(): void {
    this.title='Gestione IVA';
    super.ngOnInit.apply(this, arguments); 
    
    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("t.label");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.ordering.field="t.id";

    this.getItems();
 
    
  }

  setRule(record:Tax){
    
    this.selectedTax=Object.assign({}, record);

    
  }

  confirmRule():void{
    
    this.taxService.save(this.selectedTax,(id)=>{
      this.getItems();
    });
  }

  addType(){
    let r:Tax={} as Tax;
    

    
    
    this.selectedTax=r;
  }

  confirm_delete(id){
    confirm("Eliminare l'elemento?")?this.delete(id):false;
  }

}
