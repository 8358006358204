import { Component, OnInit,Input } from '@angular/core';
import {Note} from '../../db/note';
import { Globals } from 'projects/core/src/globals';

@Component({
  selector: 'app-notebox',
  templateUrl: './notebox.component.html',
  styleUrls: ['./notebox.component.sass']
})

export class NoteboxComponent implements OnInit {

  @Input()
  id_address:number;

  @Input()
  table:string;

  @Input()
  id_table:number;  

  @Input()
  list:Note[];

  mode="embedded";

  constructor() { }

  ngOnInit(): void {
  }

  newRecord():void{
    let r:Note={} as Note;
    r.id_address=this.id_address;
    r.table=this.table;
    r.id_table=this.id_table;
    this.list.push(r);
  }


  deleteRecord(c:Note):void{
    Globals.modal.showConfirm("","Confermi di voler eliminare la nota selezionata?",()=>{  
      const index: number = this.list.indexOf(c);
      this.list.splice(index,1);
    },"Sì. Elimina","No. Annulla","danger");
  }

}
