import { Component, OnInit } from '@angular/core';
import { Filter, FilterMode, User } from 'projects/core/src/include/structures';
import { UsersService } from 'projects/ratingo-backend/src/app/services/users.service';
import { ActivitiesService } from '../../../services/activities.service';

@Component({
  selector: 'app-activities-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent implements OnInit {

  constructor(
    private activitiesService:ActivitiesService,
    private usersService:UsersService
  ) {
    
   }
   users:User[];
  id_phase=0;
   list=[];
  filter_date:Filter=new Filter();
   total_user=[];
   total_paid=0;
   total_amount=0;
   

   chartData=[];
  chartLabels=[];
  chartOptions = {
    responsive: true
  };

  ngOnInit(): void {
    this.filter_date.mode=FilterMode.date;

    this.usersService.getUsers().subscribe((items)=>{
      this.users=items;
    });
    
  }

  getItems(){
    this.activitiesService.getAnalytics(this.filter_date.value,this.filter_date.value2,this.id_phase).subscribe((items)=>{
      


      this.list=items;
      this.calculateTotalByUser();
    });
  }

  calculateTotalByUser(){
    this.total_user=[];
    this.total_paid=0;
    this.total_amount=0;
    
    for(let u of this.users){
      let tuser=[];
      tuser['id_user']=u.id;
      tuser['paid']=0;
      tuser['amount']=0;
      this.total_user.push(tuser);
    }

    let data_paid=[];
    let data_amount=[];
    this.chartLabels=[];

    for(let r of this.list){
      data_paid.push(r.total_paid);
      data_amount.push(r.total_amount);
      this.chartLabels.push(r.date);
      this.total_paid=this.total_paid+parseFloat(r.total_paid);
      this.total_amount=this.total_amount+parseFloat(r.total_amount);
      
      for(let u of this.total_user){
        for(let u2 of r.users){
          if(u['id_user']==u2.id_user){
            u['paid']=u['paid']+parseFloat(u2.paid);
            u['amount']=u['amount']+parseFloat(u2.amount);
            
          }
        }
        
      }
    }

    
    this.chartData.push({"data":data_paid,"label":"Incassato"});
    this.chartData.push({"data":data_amount,"label":"Attività"});
    
  }

}
