import { Component, OnInit} from '@angular/core';
import { ModelList } from '../../include/modellist'
import { UntypedFormBuilder } from '@angular/forms';
import { Filter,FilterMode,Action, Condition,ActionDetail, Field } from '../../include/structures'
import { ActionsService } from '../../lib/actions.service';


@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.sass']
})
export class ActionsComponent extends ModelList<Action> implements OnInit {

  
 
  filter_type:Filter=new Filter();
  selectedAction:Action={} as Action;
  


  constructor(
    private fb1: UntypedFormBuilder,
    private actionsService: ActionsService,
    
  ) {
    super(actionsService,fb1);
   }

   ngOnInit(): void {
    this.title='Azioni';
    super.ngOnInit.apply(this, arguments); 
    
    this.filter_type.mode=FilterMode.normal;
    this.filter_type.fields=[];
    this.filter_type.fields.push("a.table");
    this.filter_type.value="";
    this.filter.push(this.filter_type);

    this.ordering.field="a.id";

    this.getItems();
 
    
  }


  setRule(record:Action){
    
    this.selectedAction=Object.assign({}, record);;

    this.selectedAction.conditionsArray=[];
    if(this.selectedAction.conditions!="")
      this.selectedAction.conditionsArray=JSON.parse(this.selectedAction.conditions);
      this.selectedAction.actionDetail={} as ActionDetail;
    if(this.selectedAction.action!="")
      this.selectedAction.actionDetail=JSON.parse(this.selectedAction.action);

  }

  confirmRule():void{
    this.selectedAction.conditions=JSON.stringify(this.selectedAction.conditionsArray);
    this.selectedAction.action=JSON.stringify(this.selectedAction.actionDetail);
    
    this.actionsService.save(this.selectedAction,(id)=>{
      this.getItems();
    });
  }

  addAction(){
    let r:Action={} as Action;
    r.status=1;

    
    if(this.filter_type.value!="")
      r.table=this.filter_type.value;
    this.selectedAction=r;
  }

  addCondition():void{
    let c:Condition={} as Condition;
    if(this.selectedAction.conditionsArray==undefined)
      this.selectedAction.conditionsArray=[];

    if(this.selectedAction.actionDetail==undefined)
      this.selectedAction.actionDetail={} as ActionDetail;

    this.selectedAction.conditionsArray.push(c);
  }

  removeCondition(c:Condition):void{
    for(let i=0;i<this.selectedAction.conditionsArray.length;i++)
      if(this.selectedAction.conditionsArray[i]==c)
        this.selectedAction.conditionsArray.splice(i,1);
  }

  addActionDetail():void{
    let f:Field={} as Field;
    if(this.selectedAction.actionDetail.fields==undefined)
      this.selectedAction.actionDetail.fields=[];

    
    this.selectedAction.actionDetail.fields.push(f);
  }

  removeActionDetail(c:Field):void{
    for(let i=0;i<this.selectedAction.actionDetail.fields.length;i++)
      if(this.selectedAction.actionDetail.fields[i]==c)
        this.selectedAction.actionDetail.fields.splice(i,1);
  }
  
  confirm_delete(id){
    confirm("Eliminare l'elemento?")?this.delete(id):false;
  }


}
