<app-windowlist [model]="this">
    <div class="row">
        <div class="col-lg-3">
        <app-filtercontainer [title]="'Filtra per magazzini, categorie, ...'" >
            <ul class="card small-box list-group p-1 mt-2">
                <li class="list-group-item text-truncate pointer" [class.active]="filter_id_shop.value==''" (click)="filter_id_shop.value='';getItems()" >Tutti i magazzini</li>
                <li class="list-group-item text-truncate pointer" [class.active]="filter_id_shop.value==i.id" *ngFor="let i of shops" (click)="filter_id_shop.value=i.id;getItems()">{{i.name}}</li>
                <div *ngIf="shops.length>2" class="icon"><i class="fas fa-boxes"></i></div>
            </ul>
            <app-treecategories [class]="'card p-1 mt-2'" [multiple]="true" (id_selected)="filter_category.value=$event;getItems()" [showAll]="true" [id]="filter_category.value" [editButton]="false" #tree [list]="categories_product"></app-treecategories>
            <ul class="card small-box list-group p-1 d-none d-lg-block mt-2 mb-4" *ngIf="type_product">
                <li class="list-group-item pointer" [class.active]="filter_id_type.value==''" (click)="filter_id_type.value='';getItems()">Tutte le tipologie</li>
                <li *ngFor="let i of type_product" class="list-group-item pointer"  [class.active]="checkFilterTypeValue('filter_id_type',i.id)" (click)="setFilterTypeValue('filter_id_type',i.id);getItems()">{{i.name}}</li>
                <div *ngIf="type_product.length>2" class="icon"><i class="fas fa-stream"></i></div>
            </ul>
        </app-filtercontainer>
        </div>
        <div class="col-lg-9 pl-lg-0">
            <div class="card card-outline mt-2">
                <div class="p-0">
                    <div class="row">
                        <div class="col-lg-5 pr-lg-0">
                            <div class="input-group input-group-sm p-1" >
                                <input type="text" name="table_search" class="form-control float-right" placeholder="cerca..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                <div class="input-group-append ">
                                    <button type="submit" class="btn btn-default text-primary" title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger" *ngIf="filter_search.value!=''"  title="Annulla ricerca" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                                    <button class="btn btn-default"   title="Ricerca avanzata" data-toggle="collapse" data-target="#advancedSearchPanel"  aria-expanded="false" aria-controls="advancedSearchPanel" ><span id="searchclear" class="fas fa-sliders-h"></span></button>
                                    <button class="btn btn-default"   title="Ricerche salvate" (click)="openTemplateSearch()" ><span id="searchclear" class="far fa-save"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 pl-lg-0" *ngIf="isModuleEnabled('shops')">
                            <div class="input-group input-group-sm p-1" >
                                <select class="custom-select" [(ngModel)]="filter_shop_id_type.value" (ngModelChange)="getItems()" [class.alert-info]="filter_shop_id_type.value">
                                    <option value="">Tutte le sedi</option>
                                    <option *ngFor="let t of type_shops" [ngValue]="t.id">{{t.name}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6 col-lg-2 pl-lg-0">
                            <div class="input-group input-group-sm p-1" >
                                <select class="custom-select" [(ngModel)]="filter_id_reseller.value" (ngModelChange)="getItems()" [class.alert-info]="filter_id_reseller.value">
                                    <option value="">Tutti</option>
                                    <option value="1">Solo gestiti</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3 pl-lg-0 py-1" *ngIf="mode!='modal'">
                            <!--
                            <div class="dropdown dropleft show">
                                <a class="btn btn-xs float-right btn-default m-1" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v"></i>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <button class="dropdown-item" (click)="showUploadFile()">Importa QR Code</button>
                                    <button class="dropdown-item" (click)="createDDT()">Crea DDT</button>
                                    <button class="dropdown-item" (click)="openModalImportValue()">Aggiorna tutti i seriali</button>
                                </div>
                            </div>
                            <button  title="Archivia"  (click)="archive()" [disabled]="form.value.id.length == 0" class="btn btn-primary btn-xs float-right m-1 "><i class="fas fa-archive"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button  title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="far fa-trash-alt"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-undo"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button  title="Esporta" (click)="export()" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-file-export"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
                            <button  title="Aggiungi" (click)="openDetail(null,{'name':'id_shop','value':id_shop})" class="btn btn-success btn-xs float-right m-1"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block">&ensp;</span></button> 
                            -->
                            <app-windowlistbuttons 
                            [model]="this" 
                            [table]="'installations'" 
                            [openDetailExtra]="{'name':'id_shop','value':id_shop}" 
                            [funs]="[
                                {'name':'Altri comandi','value':''},
                                {'name':'Importa QR Code','value':'showUploadFile'},
                                {'name':'Crea DDT','value':'createDDT'},
                                {'name':'Aggiorna tutti i seriali','value':'openModalImportValue'}
                            ]"
                            ></app-windowlistbuttons>
                        </div>
                        <div class="col-12">
                            <div class="small-box bg-light collapse p-1 m-1" id="advancedSearchPanel">
                                <div class="form-group row mb-0 text-gray">
                                    <div class="col-lg-2"><i class="fas fa-qrcode text-purple mx-2"></i><small>QrCode</small></div>
                                    <div class="col-lg-10">
                                        <select class="form-control form-control-sm" name="filter_qrcode" [(ngModel)]="filter_qrcode.enabled" (change)="getItems()">
                                            <option [ngValue]="false">Nessun filtro</option>
                                            <option [ngValue]="true">Cerca tutti i seriali con QRCode mancante</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row mb-0 etxt-gray">
                                    <div class="col-lg-2"><i class="fas fa-file-signature text-gray mx-2"></i><small>Contratti</small></div>
                                    <div class="col-lg-10">
                                        <select class="form-control form-control-sm" name="filter_contracts_type" [(ngModel)]="filter_contracts_type" (change)="getItems()">
                                            <option [ngValue]="0">Nessun filtro</option>
                                            <option [ngValue]="1">Cerca tutti i seriali con contratto</option>
                                            <option [ngValue]="2">Cerca tutti i seriali senza contratto</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row mb-0 text-gray">
                                    <div class="col-lg-2"><i class="far fa-calendar-alt text-gray mx-2"></i><small>Agenda</small></div>
                                    <div class="col-lg-10">
                                        <div class="row">
                                            <div class="col-lg-7 pr-lg-0">
                                                <select class="form-control form-control-sm" name="filter_deadline_type" [(ngModel)]="filter_deadline_type" (change)="getItems()">
                                                    <option [ngValue]="0">Nessun filtro</option>
                                                    <option [ngValue]="1">Seriali con almeno una voce di agenda assegnata successiva ad oggi</option>
                                                    <option [ngValue]="2">Seriali senza una voce di agenda assegnata successiva ad oggi</option>
                                                </select>
                                            </div>
                                            <div class="col-lg-5 pl-lg-0">
                                                <select class="form-control form-control-sm" name="filter_deadline_id_type_deadline" [(ngModel)]="filter_deadline_id_type_deadline" (change)="getItems()" >
                                                    <option *ngFor="let t of type_deadlines" [ngValue]="t.id">{{t.name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row border-top my-1">
                                    <div class="col-xs-12 col-lg-11">
                                        <app-advancedsearch [id_field]="'p.id'" [table]="'installations'" [filters]="advancedFilter"></app-advancedsearch>
                                    </div>
                                    <div class="col-xs-12 col-lg-1 pl-1">
                                        <button title="Filtra" class="btn btn-secondary btn-block btn-sm" (click)="getItems()"><i class="fa fa-filter"></i><small class="ml-1 d-none d-lg-inline">Filtra</small></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                        <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>
    
                    </div>
                    <form *ngIf="list && list.length>0" [formGroup]="form">
                        <table class="table table-nowrap table-striped table-sm table-head-fixed table-hover m-0 text-sm">
                            <thead class="bg-light">
                                <th>
                                    <div #checkboxselectall class="icheck-primary d-inline mr-3">
                                        <input type="checkbox" title="Seleziona tutti"
                                        (change)="onChangeAll($event.target.checked)" />
                                    </div>
                                    Modello
                                    <button class="btn btn-tool" (click)="switchOrdering('p.brand')">
                                        <i class="fas fa-sort {{classOrdering('p.brand')}}"  ></i>
                                    </button>
                                </th>
                                <th>Numero Seriale</th>
                                <th *ngIf="isModuleEnabled('contracts')">Contratto</th>
                                <th *ngIf="isModuleEnabled('shops')">Ubicazione attuale</th>
                                <th *ngFor="let t of tableColumns">
                                    {{t.label}}
                                </th>
                                <th *ngIf="isModuleEnabled('forms')">Censimento</th>
                                <th class="d-none d-lg-table-cell">Note</th>
                                <th class="text-right"><button  title="Crea / Modifica campi" type="button" class="btn btn-xs btn-light" (click)="editTableHeader()"><i class="fas fa-cog"></i></button></th>
                            </thead>
                            <tbody>
                            
                                <!-- elemento ripetuto -->
                                <tr *ngFor="let record of list">
                                    <td>
                                        <div class="icheck-primary d-inline mr-2">
                                            <input type="checkbox" #checkrecord id_record="{{record.id}}" title="{{record.id}}"
                                            (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll"
                                            />
                                        </div>
                                        <small class="mr-2">({{record.id}})</small>&ensp;
                                        <small class="text-info"><i class="{{record.icon}}"></i> {{record.category}}</small><br/>
                                        <span class="ml-4 pointer" [class.trashed]="record.status == 2" [class.disabled]="mode=='modal'" (click)="openDetail(record)"><span><b>{{record.brand}}</b> {{record.model}}</span></span><br/>
                                        <small>
                                            <div *ngIf="record.id_reseller>0" class="ml-4">Rivenditore: <b>{{record.reseller.name}}</b></div>
                                            <span class="badge badge-warning ml-4" *ngIf="record.id_category==id_category_log && !record.last_log.id" date-toggle="tooltip" title="Nessun monitoraggio chilometri rilevato!">
                                                <i class="fa fa-exclamation-triangle"></i>
                                            </span>
                                            <span *ngIf="record.id_category==id_category_log && record.last_log.id" class="badge badge-danger ml-4" date-toggle="tooltip" title="Ultimo chilometraggio">
                                                <i class="fas fa-tachometer-alt mr-1"></i>
                                                {{record.last_log.km}}
                                            </span>
                                        </small>
                                    </td>
                                    <td>
                                        <b>{{ record.sn }}</b>
                                        <br/>{{ record.matricola }}
                                        &nbsp;<i *ngIf="record.loguseralert>0"  title="Ci sono alcune avvertenze da visionare" class="text-warning text-warning fas fa-exclamation-triangle"><sup><span class="badge badge-danger">{{record.loguseralert}}</span></sup></i>
                                    </td>
                                    <td *ngIf="isModuleEnabled('contracts')">
                                        <ng-container *ngIf="record.contracts">
                                            {{ record.type | uppercase }}
                                            <ng-container *ngIf="record.contracts.length>0">
                                                <ng-container *ngFor="let c of record.contracts">
                                                    <small>
                                                        <div class="alert alert-info p-1 text-center">
                                                            <i  (click)="openContract(c);" title="Visualizza contratto" class="fa-file-signature fas mr-2 pointer"></i>
                                                            Contratto {{c.type}} "{{c.description}}"<br>
                                                            <span class="badge" [class.badge-warning]="verifyDate(c.end)" [class.badge-danger]="!verifyDate(c.end)">Scadenza {{c.end | date:"dd-MM-Y"}}</span>
                                                        </div>
                                                    </small>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td *ngIf="isModuleEnabled('shops')">
                                        <ng-container *ngIf="true">
                                            <i class="fas fa-store-alt pointer" (dblclick)="moveInstallation(record)" title="Doppio Clik per spostamento rapido"></i>
                                            <span class="ml-1 text-truncate w-100" [class.trashed]="record.shopstatus==2">{{record.shopname}}
                                                <div *ngIf="record.shopstatus==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle"></i>&nbsp;cestinato</div>
                                            </span>
                                            <span *ngIf="record.shoptypeparams!='default'"><small><i>&nbsp;({{record.shoptypename}})</i></small></span>
                                            <br/>
                                            <ng-container *ngIf="record.addressname">
                                                <small [class.trashed]="record.addressstatus==2">
                                                    <i class="fa fa-user mx-1"></i>&nbsp;{{record.addressname}} / {{record.addressmaincontact}}
                                                    <div *ngIf="record.addressstatus==2" class="badge badge-warning"><i class="fas fa-exclamation-triangle"></i>&nbsp;cestinato</div>
                                                </small>
                                            </ng-container>
                                            <ng-container *ngIf="record.shopid_inventory>0">
                                                <hr class="m-0"/>
                                                <small>Prodotto in magazzino?
                                                    <span class="badge badge-success ml-1" *ngIf="getStock(record)==1">presente</span>
                                                    <span class="badge badge-warning ml-1" *ngIf="getStock(record)==0">non presente</span>
                                                    <span class="badge badge-danger ml-1" *ngIf="getStock(record)>1 || getStock(record)<0">in errore</span>
                                                </small>
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td *ngFor="let t of tableColumns">
                                        {{t.getValue(record)}}
                                    </td>
                                    <td *ngIf="isModuleEnabled('forms')">
                                        <small *ngIf="record.lastfieldvalues">
                                            <ul class="list-group">
                                                <li class="list-group-item p-0 bg-gray text-center">{{record.lastfieldvalues.date | date:"dd/MM/Y"}}</li>
                                                <li class="list-group-item p-0 bg-light" *ngFor="let f of record.lastfieldvalues.values"><small>{{f.label}}: </small><b>{{f.value}}</b></li>
                                            </ul>
                                        </small>
                                    </td>
                                    <td class="d-none d-lg-table-cell">
                                        <small *ngFor="let n of record.notes"><i class="fas fa-thumbtack text-gray mr-2"></i><i>{{n.value}}</i></small>
                                    </td>
                                    <td>
                                        <button type="button" *ngIf="mode!='modal'" (click)="openDetail(record)"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right mt-1 ml-1">
                                            <i class="fas fa-pencil-alt"></i></button>
                                        <button type="button" *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right m-1" (click)="selectRecord(record)">
                                            <span class="d-none d-sm-inline-block">Seleziona&ensp;</span><i class="fas fa-chevron-right"></i></button>
                                        <button type="button" *ngIf="mode!='modal' && record.shop"  (click)="notify(record)" title="Notifica" class="btn btn-primary btn-xs float-right mt-1 ml-1">
                                            <i class="far fa-paper-plane"></i></button>
                                        <button type="button" *ngIf="mode!='modal' && isModuleEnabled('forms')"  (click)="newform(record)" title="Modulo" class="btn btn-outline-secondary btn-xs float-right mt-1 ml-1">
                                            <i class="fab fa-wpforms"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td><b class="ml-4">Prodotti in magazzino: {{instock}}</b></td>
                                    <td></td>
                                    <td *ngIf="isModuleEnabled('contracts')"></td>
                                    <td *ngIf="isModuleEnabled('shops')"></td>
                                    <td  *ngFor="let t of tableColumns"></td>
                                    <td  *ngIf="isModuleEnabled('forms')"></td>
                                    <td class="d-none d-lg-table-cell"></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </form>
                </div>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>


<ng-template #modalImportValue >
    <div class="modal-header bg-light">
        <b>Procedura guidata aggiornamento campi aggiuntivi</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModalImportValue()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <ng-container *ngIf="modalImportValueStep==1">
            <div class="form-group">
                <label>Campo da aggiornare</label>
                <select class="form-control form-control-sm" [(ngModel)]="id_customfield_to_update">
                    <option  *ngFor="let c of customfields" [ngValue]="c.id">{{c.label}}</option>
                </select>
            </div>
            <div class="form-group">
                <label>Valori da aggiornare</label>
                <textarea class="form-control form-control-sm" [(ngModel)]="value_customfield_to_update"></textarea>
                <small>inserire una lista composta ogni riga da "sn;valore" </small>
            </div>
            <div style="overflow: scroll;max-height: 400px;">
                <table class="table table-sm">
                    <thead>
                        <th>SN</th>
                        <th>Valore</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let t of table_value_customfield">
                            <td>{{t.sn}}</td>
                            <td>{{t.value}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button class="btn btn-info btn-block mt-3" (click)="prepareImportvalue()">Verifica</button>
        </ng-container>
        <ng-container *ngIf="modalImportValueStep==2">
            <div style="overflow: scroll;max-height: 400px;">
                <table class="table table-sm">
                    <thead>
                        <th>ID</th>
                        <th>SN</th>
                        <th>Corrente</th>
                        <th>Futuro</th>
                        <th></th>
                    </thead>
                    <tbody >
                        <tr *ngFor="let t of value_customfield_updated" [class.bg-warning]="t.update">
                            <td>{{t.id_installation}}</td>
                            <td>{{t.sn}}</td>
                            <td>{{t.current}}</td>
                            <td>{{t.value}}</td>
                            <td>
                                <span class="badge badge-info" *ngIf="t.update && t.id_installation>0">da aggiornare</span>
                                <span class="badge badge-danger" *ngIf="t.id_installation==0">seriale non presente</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <button class="btn btn-success btn-block mt-3" (click)="updateImportvalue()">Conferma aggiornamento</button>
        </ng-container> 
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="closeModalImportValue()">Chiudi</button>       
    </div>
</ng-template>