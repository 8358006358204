import { Component, Input, OnInit,Output,EventEmitter, ViewChild, TemplateRef} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Type } from '../../db/type';
import { TypesService } from '../../services/types.service';

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.sass']
})
export class TypeComponent implements OnInit {

  @ViewChild("modalListTypes")
  modalListTypes:TemplateRef<any>;
  modalListTypesRef;

  constructor(
    private typesService: TypesService,
    private modalService:NgbModal
    
    ) { }
  @Input()
  id_type:number=0;

  @Input()
  table:string;

  @Input()
  record:any;

  @Input()
  mode=1;

  @Input()
  classname="form-control form-control-sm";

  list:Type[];

  @Input()
  showAll:boolean=false;

  @Output() onEntrySelected = new EventEmitter();

  @Output() onTypeSelected = new EventEmitter();


  ngOnInit(): void {
    this.typesService.getTypes(this.table).subscribe((items)=>{
      this.list=[];
      
      if(this.showAll){
        let all=new Type();
        all.id=0;
        all.name="Tutti";
        all.table=this.table;

        this.list.push(all)
      }

      for(let i of items){
        this.list.push(i);
      }
      
      
 


      if(this.id_type==0 && this.record && this.record['type']){
        for(let t of this.list){
          if(t.name==this.record['type']){
            this.id_type=t.id;
            return;
          }
        }
      }
    });
  }

  ngOnChanges():void{
    
  }


  OnTypeChange(event){
    let c:Object={} as Object;
    c['id_type']=this.id_type;
    c['name']=this.list[event.target.selectedIndex].name;

    this.onEntrySelected.emit(c);

    if(this.record){

      this.record['id_type']=this.id_type;
      this.record['type']=this.list[event.target.selectedIndex].name;
      //cerca la tipologia
      for(let t of this.list){
        if(t.id==this.id_type){
          //verifica se ci sono parametri

          this.onTypeSelected.emit(t);
          if(t.params!=null){
            try{
              let params=JSON.parse(t.params);
              let keys=Object.keys(params);

              for(let k of keys){
                switch(k){
                  case "default":
                    let field=params['default']['field'];
                    let value=params['default']['value'];
                    this.record[field]=value;
                    break;
                }
              
              }
            }catch{
              continue;
            }
          }
        }
      }
    }
    
    
  }

  openModalListTypes(){
    this.modalListTypesRef=this.modalService.open(this.modalListTypes);

  }

  closeModalListTypes(){
    this.modalListTypesRef.close("success");
  }

  addType(){
    let typename=prompt("Inserisci il titolo della tipologia","");
    if(typename!=""){
      let t:Type=new Type();
      t.id=0;
      t.name=typename;
      t.table=this.table;
      t.published=true;
      this.typesService.save(t,(id)=>{
        t.id=id;
        this.list.push(t);
      });
    }
  }

  removeType(t){
    for(let i=0;i<this.list.length;i++){
      if(this.list[i]==t){
        this.typesService.delete([this.list[i].id.toString()]).subscribe((result)=>{
          this.list.splice(i,1);
          return;
        })
      }
    }
  }


  editType(t){
    let typename=prompt("Modifica il titolo della tipologia",t.name);
    if (typename==null) return;
    for(let i=0;i<this.list.length;i++){
      if(this.list[i]==t){
        t.name=typename;
        this.typesService.updateAny(t,[t.id.toString()],()=>{
          
        });
      }
    }
  }

}
