import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { CashflowService } from '../../../services/cashflow.service';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'app-cash-flow-analytics',
  templateUrl: './analytics.component.html',
  styleUrl: './analytics.component.css'
})

export class CashflowAnalyticsComponent implements OnInit {

  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>;

  today:Date;
  year:string;
  months=["Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre"];    
  years=[];
  list=[];
  
  constructor(
    private cashflowService:CashflowService,
  ) {
    
   }

  chartData=[];
  chartLabels=[];
  chartOptions = {
    responsive: true
  };  

  total_in=0;
  total_out=0;

  ngOnInit(): void {   
    
    this.today=new Date();
    this.year=this.today.getFullYear().toString();
    
    for(let i=this.today.getFullYear();i>this.today.getFullYear()-10;i--){
      this.years.push(i.toString());
    }
   
    this.getItems();
  }

  getItems(){
    this.chartLabels=[];
    let data_in=[];
    let data_out=[];
    let data_cumulative_prev=0;
    let data_cumulative=[];
    this.chartData=[];
    this.total_in=0;
    this.total_out=0;

    this.cashflowService.getAnalytics(this.year).subscribe((items)=>{

      this.list=items;

      for(let i=0;i<this.list.length;i++){
        var r=this.list[i];
        data_in.push(r.total_in);
        data_out.push(r.total_out);
        data_cumulative.push(data_cumulative_prev + (r.total_in - r.total_out));

        this.chartLabels.push(this.months[r.month-1]);
        this.total_in=this.total_in+parseFloat(r.total_in);
        this.total_out=this.total_out+parseFloat(r.total_out);        
        
        data_cumulative_prev += r.total_in - r.total_out;
      } 
      
      this.chartData.push({"data":data_in,"label":"Entrate","backgroundColor":"rgba(0,128,0,0.8)","hoverBackgroundColor":"rgba(0,128,0,0.8)","borderColor":"white"});
      this.chartData.push({"data":data_out,"label":"Uscite","backgroundColor":"rgba(255,0,0,0.8)","hoverBackgroundColor":"rgba(255,0,0,0.8)","borderColor":"white"});
    
      this.chartData.push({"type":"line","data":data_cumulative,"label":"Cassa","tension":"0.3","pointBackgroundColor":"rgba(0,0,0,0.5)","pointHoverBackgroundColor":"rgba(0,0,0,0.5)","borderColor":"rgba(0,0,0,0.5)"});
      
     
     this.charts.forEach((child) => {
        child.chart.update()
      });
      
    });
  }

}
