<div class="row">
    <ng-container *ngIf="model">
        <div class="w-100" *ngIf="model.pages.length>1">
            <ul class="pagination m-1 text-center">
                <li class="btn-xs px-0 page-item {{p.class}}"  *ngFor="let p of model.pages">
                    <button class="page-link" (click)="model.goToPage(p)">{{p.label}}</button>
                </li>
            </ul>
        </div>
    </ng-container>
    <div id="pagination_desc" class="text-xs">
        <ng-container *ngIf="model.recordcount || model.recordcount>0">
            <span class="mx-1" >
                <span *ngIf="model.recordcount==1" >Un elemento</span>
                <span *ngIf="model.recordcount>1" ><b class="border px-1 text-primary">{{model.recordcount}}</b> elementi</span>
            </span>
            <ng-container *ngIf="model.recordcount>1">
                <span>| mostra </span>
                <select class="text-primary" [(ngModel)]="model.paging_count" (ngModelChange)="getItems()">
                    <option value="12">12</option>
                    <option value="24">24</option>
                    <option value="48">48</option>
                    <option value="96">96</option>
                </select>
                <span class="d-none d-sm-inline-block mx-1">per pagina</span>
            </ng-container>|
        </ng-container>
        <span *ngIf="showStatus">
            <span> solo </span>
            <select [(ngModel)]="model.filter_status.value" (ngModelChange)="getItems()"
                [class.text-primary]="model.filter_status.value==1"
                [class.bg-danger]="model.filter_status.value==2"
                [class.bg-warning]="model.filter_status.value==3"
                >
                <option value="1">Utilizzati</option>
                <option value="2">Cestinati</option>
                <option value="3">Archiviati</option>
            </select>
        </span>
    </div>
</div>