import { Injectable } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { WSService } from 'projects/core/src/include/service';
import { Observable, Observer } from 'rxjs';
import { Tax } from '../db/tax';

@Injectable({
  providedIn: 'root'
})
export class TaxesService extends WSService<Tax> {


  public static taxes=[];
  constructor() { super("taxes")}


  
  getTaxes():Observable<Tax[]>{

    if(TaxesService.taxes.length==0){
      Globals.ws.requestUrl<Tax[]>("taxes","getTaxes",[]).subscribe((items:any)=>{
        TaxesService.taxes=items;
      });
      return Globals.ws.requestUrl<Tax[]>("taxes","getTaxes",[]);

    }else{

      function sequenceSubscriber(observer: Observer<any[]>) {
        observer.next(TaxesService.taxes);
        observer.complete();
        return {unsubscribe() {}};
      }
      
        return new Observable(sequenceSubscriber);
    }


   
    
  }
}
