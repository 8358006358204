import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-tree',
  templateUrl: './tree.component.html',
  styleUrl: './tree.component.css'
})
export class TreeComponent implements OnInit {

  @Input()
  tree=[];

  @Output()
  onSelect:EventEmitter<any>=new EventEmitter<any>();

  constructor(
 
  ) { }

  ngOnInit(): void {
    
  }

  select(data){
    //azzera la seleziona a tutti gli elementi
    for(let t of this.tree){
      this.deselectItem(t);
    }

    data.selected=true;
    this.onSelect.emit(data);
  }

  deselectItem(item){
    if(item.children.length>0){
      if(item.selected){
        delete(item.selected);
      }
      for(let i of item.children){
        this.deselectItem(i);
      }
    }else{
      if(item.selected){
        delete(item.selected);
      }
    }
    
  }

}
