<app-windowlist [model]="this">
    <div>
        <div class="row">
            <div class="col-12">
                <div class="card card-outline">
                    <div class="p-0">
                        <div class="row">
                            <div class="col-6 col-9">
                                <div class="input-group input-group-sm m-1" >
                                    <input type="text" name="table_search" class="form-control float-right" placeholder="cerca..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                    <div class="input-group-append ">
                                        <button type="submit" class="btn btn-default text-primary"  title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                        <button class="btn btn-default text-danger"   title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                                        <button class="btn btn-default" title="Regole" data-toggle="collapse" data-target="#advancedSearchPanel"  aria-expanded="false" aria-controls="advancedSearchPanel" ><span id="searchclear" class="fas fa-sliders-h text-info"></span></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 col-lg-3" *ngIf="mode!='modal'">
                                <app-windowlistbuttons 
                                [model]="this" 
                                [addButton]="false" 
                                [table]="'shops'" 
                                [funs]="[]"
                                ></app-windowlistbuttons>
                                <!-- 
                                <button  title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-sm float-right m-1 "><i class="far fa-trash-alt"></i></button>
                                <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-sm float-right m-1 "><i class="fas fa-trash"></i></button>
                                <button  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-sm float-right m-1 "><i class="fas fa-undo"></i></button>
                                <button  title="Esporta" (click)="export()" class="btn btn-secondary btn-sm float-right m-1 "><i class="fas fa-file-export"></i></button> -->
                                <button  title="Aggiungi" [routerLink]="['/shops/shops-details', 0,id_address ]" class="btn btn-success btn-sm float-right ml-1 mt-1"><i class="fas fa-plus"></i></button>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                        <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>
    
                    </div>
                    <form *ngIf="list && list.length>0" [formGroup]="form">
                        <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                            <thead class="bg-light">
                                <th>
                                    <div #checkboxselectall class="icheck-primary d-inline mr-2">
                                        <input type="checkbox"
                                        (change)="onChangeAll($event.target.checked)" />
                                    </div>
                                    Ragione sociale
                                    <button class="btn btn-tool" (click)="switchOrdering('s.name')">
                                        <i class="fas fa-sort {{classOrdering('s.name')}}"  ></i>
                                    </button>
                                </th>
                                <th class="d-none d-sm-table-cell">
                                    Indirizzo
                                    <button class="btn btn-tool" (click)="switchOrdering('s.address')">
                                        <i class="fas fa-sort {{classOrdering('s.address')}}"  ></i>
                                    </button>
                                </th>
                                <th></th>                                
                            </thead>
                            <tbody>
                            
                                <!-- elemento ripetuto -->
                                <tr *ngFor="let record of list">
                                    <td>
                                        <div class="icheck-primary d-inline mr-3">
                                            <input type="checkbox" #checkrecord id_record="{{record.id}}"  title="{{record.id}}"
                                            (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll" />
                                        </div>
                                        <a [class.trashed]="record.status == 2" [class.disabled]="mode=='modal'" (click)="openDetail(record)" ><b>{{ record.name }}</b></a> <span class="badge badge-info">{{record.type}}</span> <i *ngIf="record.id_inventory>0" class="fa fa-boxes"></i><br>
                                        <span class="pl-2 mr-4"></span><small class="text-uppercase">{{ record.activity }}</small>
                                        <div class="d-lg-none">
                                            <span class="pl-2 mr-4"></span><small>{{ record.address }}</small><br/>
                                            <span class="pl-2 mr-4"></span><small class="font-weight-bold" >{{record.city | uppercase}} {{record.country | uppercase}}</small>
                                        </div>
                                    </td>
                                    <td class="d-none d-sm-table-cell">
                                        <span>{{ record.address }}</span><br>
                                        <span class="font-weight-bold" >{{record.city | uppercase}} {{record.country | uppercase}}</span>
                                        <sup *ngIf="record.latitude==0"><div class="badge badge-warning">NO GPS</div></sup>
                                    </td>
                                    <td>
                                        <!-- <button type="button" *ngIf="mode!='modal'"  (click)="notify(record)" title="Notifica" class="btn btn-primary btn-sm float-right m-1">
                                            <i class="far fa-paper-plane"></i></button> -->
                                        <button type="button" *ngIf="mode!='modal'" (click)="openDetail(record)"  title="Modifica / Vedi" class="btn btn-secondary btn-sm float-right m-1">
                                            <i class="fas fa-pencil-alt"></i></button>
                                        <button type="button" *ngIf="mode=='modal'" class="btn btn-secondary btn-sm float-right m-1" (click)="selectRecord(record)">
                                            <span class="d-none d-sm-inline-block">Seleziona&ensp;</span><i class="fas fa-chevron-right"></i></button>
                                    </td>
                                </tr>
    
                            </tbody>
                        </table>
                    </form>
                    <div class="card-footer clearfix py-1" *ngIf="mode!='embedded'">
                        <app-pagination [model]="this" ></app-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>