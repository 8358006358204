import { Component, Input, OnInit } from '@angular/core';
import { Type } from 'projects/c1-backend/src/app/db/type';
import { TypesService } from 'projects/c1-backend/src/app/services/types.service';
import { Globals } from 'projects/core/src/globals';
import { SettingsComponent } from '../settings/settings.component';
import { DossierOverviewsService } from '../../services/dossieroverviews.service';

@Component({
  selector: 'app-dossier',
  templateUrl: './dossier.component.html',
  styleUrls: ['./dossier.component.css']
})
export class DossierComponent implements OnInit {
  tab=1;

  types:Type[]=[];

  @Input()
  mode:string;

  @Input()
  modalWindow:any={};

  @Input()
  recordSelected:any={};

  constructor(
    
    private typesService:TypesService
  ) { }

  ngOnInit(): void {

    this.typesService.getTypes("dossier").subscribe((items)=>{
      this.types=items;
      if(this.types.length>0)
        this.tab=this.types[0].id;
    })

  }


  openSettings(){
    Globals.modal.showModal(SettingsComponent,[],()=>{},'xl');
  }


  
}
