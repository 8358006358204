
<ng-container *ngIf="field.type=='separator'">
    <div class="form-group">
        <h5 *ngIf="field.label" class="text-info">{{field.label}}</h5>
        <hr/>
    </div>
</ng-container>

<div class="form-group">
    <i *ngIf="field.value && field.value!=''" class="text-success fa fa-check mr-2"></i>
    <label>
        <span *ngIf="field.label==''">{{field.name}}</span>
        <span *ngIf="field.label!=''">{{field.label}}</span>
        <span *ngIf="field.required==true" class="text-danger">&nbsp;*</span>
    </label>
    
    
    <ng-container *ngIf="field.description">
        <br/>
        <i   title="{{field.description}}" class="fas fa-info-circle"><span [innerHTML]="field.description"></span></i>
    
    </ng-container>
    
    
    <ng-container *ngIf="field.type=='text'">
        <input type="text" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
    </ng-container>

    <ng-container *ngIf="field.type=='number'">
        <input type="number" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
    </ng-container>

    <ng-container *ngIf="field.type=='currency'">
        <div class="input-group input-group-sm">
            <input type="number" min="0.00" max="10000000.00" step="0.01" [disabled]="disabled" class="form-control form-control-sm text-right" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
            <div class="input-group-append">
                <span class="input-group-text">&euro;</span>
            </div>
            
                
        </div>
    </ng-container>

    <ng-container *ngIf="field.type=='textarea'">
        <textarea type="text" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value"></textarea>
    </ng-container>

    <ng-container *ngIf="field.type=='date'">
        <div class="input-group">
            <input type="date" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
   
            
            <div class="input-group-append" *ngIf="field.defaultvalue">
                <button class="btn btn-sm btn-default" (click)="DateDefaultValue(field)">Calcola data</button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="field.type=='time'">
        <input type="time" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
    </ng-container>

    <ng-container *ngIf="field.type=='boolean'">
        <select [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
            <option value="0">No</option>
            <option value="1">Si</option>
        </select>
    </ng-container>

    <ng-container *ngIf="field.type=='checkbox'">
        <input type="checkbox" [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
    </ng-container>

    <ng-container *ngIf="field.type=='select'">
        <select [disabled]="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
            <option value="{{o}}" *ngFor="let o of field.params">{{o}}</option>
        </select>
    </ng-container>

    <ng-container *ngIf="field.type=='file'">
        
        <ng-container *ngIf="isFileMultiple(field)">
            <ul class="list-group">
                <ng-container *ngFor="let f of parseMultipleFile(field.value)">
                    
                    
                    
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-9">
                                <i class="fa {{getFileTypeIcon(getExtension(f))}}"></i>&nbsp;{{getFilename(f)}}
                            </div>
                            <div class="col-3 text-right">
                                <button class="btn btn-info btn-sm mr-1" *ngIf="field.value" (click)="downloadFile(f)"><i class="far fa-eye"></i></button>
                                <button *ngIf="!disabled" class="btn btn-danger btn-sm" (click)="deleteFile(field,f)"><i class="fa fa-times"></i></button>
                            </div>
                        </div>
                    </li>
                </ng-container>
            </ul>
            <button *ngIf="!disabled" class="mt-2 btn btn-block btn-success btn-sm" (click)="addFile(field)"><i class="fa fa-upload"></i>&nbsp;Aggiungi File</button>
        </ng-container>
        <ng-container *ngIf="!isFileMultiple(field)">
            <div class="input-group input-group-sm">
                
                <input type="text" [readonly]="true" class="form-control form-control-sm" name="{{i}}_{{field.name}}" [(ngModel)]="field.value">
                <div class="input-group-append">
                    <button class="btn btn-secondary btn-sm" *ngIf="!disabled" (click)="addFile()">Sfoglia</button>
                    <button class="btn btn-info btn-sm" *ngIf="field.value" (click)="downloadFile(field.value)"><i class="far fa-eye"></i></button>
                </div>
            </div>
        </ng-container>
    </ng-container>
    
    <ng-container *ngIf="field.type=='photo'">
        <br/>
        <ng-container *ngIf="isPhotoMultiple(field)">
            <div class="row">
                <ng-container *ngFor="let i of parseMultiplePhoto(field.value)">
                    <div class="col-3">
                        <img class="img-thumbnail" style="max-width: 100px" src="{{pathImage(i)}}">
                        <button *ngIf="!disabled" style="position: absolute;left: 5px;top: -7px;"class="btn btn-danger btn-sm" (click)="deletePhoto(field,i)"><i class="fa fa-times"></i></button>
                    </div>
                </ng-container>
            </div>
            <button *ngIf="!disabled" class="mt-2 btn btn-block btn-success" (click)="addPhoto(field)"><i class="fa fa-camera"></i>&nbsp;Aggiungi foto</button>
        </ng-container>

        <ng-container *ngIf="!isPhotoMultiple(field)">
            <div class="row">
                <div class="col-3">
                    <img *ngIf="field.value!=''" class="img-thumbnail" style="max-width: 100px" src="{{pathImage(field.value)}}" >
                    <button *ngIf="field.value!='' && !disabled" style="position: absolute;left: 5px;top: -7px;"class="btn btn-danger btn-sm" (click)="field.value=''"><i class="fa fa-times"></i></button>
           
                </div>
            </div>
            <button *ngIf="field.value!='' && !disabled" class="mt-2 btn btn-block btn-info" (click)="takePhoto(field);"><i class="fa fa-camera"></i>&nbsp;Cambia foto</button>
            <button *ngIf="field.value=='' && !disabled" class="mt-2 btn btn-block btn-success" (click)="takePhoto(field);"><i class="fa fa-camera"></i>&nbsp;Scatta 1 foto</button>
            
        </ng-container>
       
    </ng-container>

    <ng-container *ngIf="field.type=='formula'">
        <input class="form-control form-control-sm" type="number" readonly="true" name="{{i}}_{{field.name}}" [(ngModel)]="field.value" value="{{calculateFormula(field.defaultvalue,field)}}">
    </ng-container>

    <ng-container *ngIf="field.type=='address'">
        <div class="input-group input-group-sm">
            <input type="text" disabled="disabled" class="form-control form-control-sm" name="{{i}}_{{field.name}}" value="{{field.value.name}}">
            <div class="input-group-append">
                <button class="btn btn-secondary" *ngIf="!disabled" (click)="selectAddress(field)">Seleziona</button>
                <button class="btn btn-secondary" *ngIf="!disabled && field.value.id" (click)="openAddress(field.value.id)">Visualizza</button>
            
            </div>
        </div>
       
    </ng-container>

    <ng-container *ngIf="field.type=='table'">

        <ng-container *ngFor="let section of field.params">
            
            <table class="table">
                <thead>
                    <th *ngFor="let f of section.fields">
                        {{f.name}}
                    </th>
                </thead>
                <tbody>
                    <tr *ngFor="let v of field.value">
                        
                        <td *ngFor="let f of v.fields">
                            <app-field [field]="f"></app-field>
                        </td>
                        <td>
                            <button class="btn btn-sm btn-danger" (click)="deleteRowTable(field.value,v)"><i class="fa fa-trash"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>

            <button class="btn btn-success" (click)="addRowTable(field.value,section.fields)"><i class="fa fa-plus"></i>&nbsp;Aggiungi</button>
        </ng-container>
    </ng-container>

</div>




