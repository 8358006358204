import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FinancialaccountComponent } from './financialaccount.component';
import { FormsModule } from '@angular/forms';
import { TreeModule } from '../tree/tree.module';
//import { TreeModule } from '@circlon/angular-tree-component';


@NgModule({
  declarations: [
    FinancialaccountComponent  ],
  imports: [
    CommonModule,
    FormsModule,
    TreeModule
    //TreeModule
  ],
  exports:[
    FinancialaccountComponent
  ]
})
export class FinancialaccountModule { }
