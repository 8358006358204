<app-window [model]="this" [title]="record.name">
    <form role="form" id="detailForm">
        <div class="form-group row">
            <div class="col-2">
                <label>Nome</label>
            </div>
            <div class="col-4">
                <input type="text" class="form-control form-control-sm"  name="name" [(ngModel)]="record.name">
            </div>
            <div class="col-2 text-right">
                <label>Tabella</label>
            </div>
            <div class="col-4">
                <app-selecttables [name]="'table'" [value]="record.table" [show_all]="false" (onchange)="record.table=$event"></app-selecttables>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-2">
                <label>Tipo</label>
            </div>
            <div class="col-4">
                <select class="form-control form-control-sm" name="id_type" [(ngModel)]="record.id_type">
                    <option value="1">in Uscita</option>
                    <option value="2">in Ingresso</option>
                </select> 
            </div>
            <div class="col-2 text-right">
                <label>Tipologia di documento</label>
            </div>
            <div class="col-4">
                <select class="form-control form-control-sm" name="typedocument" [(ngModel)]="record.typedocument">
                    <option value="1"                               >Fattura</option>
                    <option value="2"                               >Fattura accompagnatoria</option>
                    <option value="12"                              >Fattura acconto</option>
                    <option value="3" *ngIf="record.id_type==1"    >Nota di credito</option>
                    <option value="3" *ngIf="record.id_type==2"    >Nota di debito</option>
                    <option value="4"                               >Preventivo</option>
                    <option value="5"                               >DDT</option>
                    <option value="10"                              >Trasferimento</option>
                    <option value="6" *ngIf="record.id_type==1"    >Scontrino</option>
                    <option value="7" *ngIf="record.id_type==1"    >Ordine cliente</option>
                    <option value="8" *ngIf="record.id_type==2"    >Ordine fornitore</option>
                    <option value="9"                               >Non fiscale</option>
                    <option value="11"                              >Documento amministrativo</option>
                    <option value="13"                              >Inventario</option>
                </select>
            </div>
        </div>
        <h5 class="mt-3">Condizioni</h5>
        <small>Indicare le condizioni che si devono verificare per applicare la regola</small>
        <table class="table">
            <thead>
                <th>Campo del record di origine</th>
                <th></th>
                <th>Valore</th>
                <th></th>
            </thead>
            <tbody>
                <tr *ngFor="let c of record.conditions;let i=index">
                    <td>
                        <input type="text" class="form-control form-control-sm" name="{{i}}_field" [(ngModel)]="c.field"/>
                    </td>
                    <td>
                        <select class="custom-select custom-select-sm" name="{{i}}_condition" [(ngModel)]="c.condition">
                            <option value="ugual"> = </option>
                            <option value="major"> maggiore </option>
                            <option value="minor"> minore </option>
                            <option value="minorugual"> minore uguale</option>
                            <option value="majorugual"> maggiore uguale</option>
                            

                        </select>
                    </td>
                    <td>
                        <input type="text" class="form-control form-control-sm" name="{{i}}_value" [(ngModel)]="c.value"/>
                    </td>
                    <td>
                        <button type="button" class="btn btn-sm btn-danger float-right" (click)="removeCondition(c)"><i class="fa fa-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
        <button type="button" class="btn btn-sm btn-primary mb-2" (click)="addCondition()"><i class="fa fa-plus"></i> Aggiungi</button>
        <h5>Dati da inserire nel documento</h5>
        <div class="form-group row">
            <div class="col-2">
                <label>Sezionale</label>
            </div>
            <div class="col-2">
                <input type="text" class="form-control form-control-sm"  name="referenceprefix" [(ngModel)]="record.referenceprefix">
            </div>
            <div class="col-2 text-right">
                <label>Causale</label>
            </div>
            <div class="col-6">
                <input type="text" class="form-control form-control-sm"  name="description" [(ngModel)]="record.description">
            </div>
        </div>
        <div class="form-group row">
            <div class="col-2">
                <label>Descrizione</label>
            </div>
            <div class="col-10">
                <input type="text" class="form-control form-control-sm"  name="description2" [(ngModel)]="record.description2">
            </div>
        </div>
        <div class="form-group row">
            <div class="col-2">
                <label>Regime</label>
            </div>
            <div class="col-4">
                <select class="form-control form-control-sm" [(ngModel)]="record.id_documentsrule" name="id_documentsrule" #type="ngModel" (change)="setRule($event)">
                    <option *ngFor="let r of rules" [ngValue]="r.id">{{r.name}}</option>
                </select>
            </div>
            <div class="col-2 text-right">
                <label>Movimenti</label>
            </div>
            <div class="col-4">
                <select class="form-control form-control-sm" [(ngModel)]="record.enable_cashflow" name="enable_cashflow" #type="ngModel">
                    <option value="0">Non effettuare movimenti di cassa</option>
                    <option value="1">Effettua movimenti di cassa</option>
                </select>
            </div>
        </div>
        <h5>Beni da inserire nel documento</h5>
        <div class="form-group row">
            <div class="col-2">
                <label>Modalità di inserimento beni</label>
            </div>
            <div class="col-10">
                <select class="form-control form-control-sm" [(ngModel)]="record.mode_details" name="mode_details" #type="ngModel">
                    <option value="0">Inserisci i beni della lista e quelli che provengono dall'esterno</option>
                    <option value="1">Inserisci solo i beni della lista</option>
                    <option value="2">Inserisci solo i beni che provengono dall'esterno</option>
                </select>
            </div>
        </div>
        <table class="table" *ngIf="record.mode_details!=2">
            <thead>
                <th>ID Prodotto</th>
                <th>Descrizione</th>
                <th>Note</th>
                <th>Qt</th>
                <th>UM</th>
                <th>Prezz. Unitario</th>
                <th>IVA</th>
                <th>Sconto %</th>
                <th>Totale</th>
                <th></th>
            </thead>
            <tbody>
                <tr *ngFor="let c of record.details;let i=index">
                    <td>
                        <input type="text" class="form-control form-control-sm" name="{{i}}_id_product" [(ngModel)]="c.id_product"/>
                    </td>
                    <td>
                        <input type="text" class="form-control form-control-sm" name="{{i}}_description" [(ngModel)]="c.description"/>
                    </td>
                    <td>
                        <input type="text" class="form-control form-control-sm" name="{{i}}_note" [(ngModel)]="c.note"/>
                    </td>
                    <td>
                        <input type="text" class="form-control form-control-sm" name="{{i}}_quantity" [(ngModel)]="c.quantity"/>
                    </td>
                    <td>
                        <input type="text" class="form-control form-control-sm" name="{{i}}_unit" [(ngModel)]="c.unit"/>
                    </td>
                    <td>
                        <input type="text" class="form-control form-control-sm" name="{{i}}_netprice" [(ngModel)]="c.netprice"/>
                    </td>
                    <td>
                        <select class="form-control form-control-sm" name="{{i}}_tax" [(ngModel)]="c.id_tax">
                            <option *ngFor="let t of taxes" value="{{t.id}}">{{t.tax}} {{t.label}}</option>
                        </select>
                    </td>
                    <td>
                        <input type="text" class="form-control form-control-sm" name="{{i}}_discount1" [(ngModel)]="c.discount1"/>
                    </td>
                    <td>
                        <input type="text" class="form-control form-control-sm" name="{{i}}_total" [(ngModel)]="c.total"/>
                    </td>
                    <td>
                        <button type="button" class="btn btn-sm btn-danger" (click)="removeDetail(c)"><i class="fa fa-trash"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
        <button type="button" class="btn btn-sm btn-primary" (click)="addDetail()"><i class="fa fa-plus"></i> Aggiungi</button>
    </form>
</app-window> 
<app-toolbarmodel [model]="this"></app-toolbarmodel>
