<app-window [title]="title">
    <form role="form" id="detailForm">
        
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link"  [class.active]="tab==1" (click)="tab=1">
                    <i class="fas fa-clipboard"></i><span class="d-none d-sm-inline-block ml-2">Generale</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==2" (click)="tab=2;customfields.list=record.customfieldsList">
                    <i class="fab fa-diaspora"></i><span class="d-none d-sm-inline-block ml-2">Caratteristiche</span></a>
            </li>
            <li class="nav-item" *ngIf="record.id>0 && isModuleEnabled('inventories') && record.stockable">
                <a class="nav-link" [class.active]="tab==3" (click)="tab=3">
                    <i class="fa-boxes fas"></i><span class="d-none d-sm-inline-block ml-2">Magazzino</span></a>
            </li>
            <li class="nav-item" *ngIf="record.id>0 && isModuleEnabled('documents')">
                <a class="nav-link" id="profile-tab" [class.active]="tab==6" (click)="tab=6;getDocuments()">
                    <i class="fas fa-file-alt"></i><span class="d-none d-sm-inline-block ml-2">Documenti</span></a>
            </li>
            <li class="nav-item" *ngIf="record.id>0 && isModuleEnabled('documents')">
                <a class="nav-link" id="profile-tab" [class.active]="tab==8" (click)="tab=8;getAnalytic()">
                    <i class="fas fa-chart-bar"></i><span class="d-none d-sm-inline-block ml-2">Statistiche</span></a>
            </li>
            <li class="nav-item" *ngIf="record.id>0 && record.has_serials && isModuleEnabled('installations')">
                <a class="nav-link" id="profile-tab" [class.active]="tab==4" (click)="tab=4">
                    <i class="fas fa-barcode"></i><span class="d-none d-sm-inline-block ml-2">Seriali</span></a>
            </li>            
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" [class.active]="tab==5" (click)="tab=5">
                    <i class="fas fa-desktop"></i><span class="d-none d-sm-inline-block ml-2">Scheda web</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" [class.active]="tab==7" (click)="tab=7">
                    <i class="fas fa-sitemap"></i><span class="d-none d-sm-inline-block ml-2">Correlati</span></a>
            </li>
            
        </ul>
        

        <!--
        <div class=" float-right" *ngIf="parent.isEditing">
            <a class="btn btn-sm btn-success float-right m-1" [disabled]="disable()" (click)="save();" ><i class="fas fa-save"></i><span class="d-none d-sm-inline-block">&ensp;Salva</span></a>
            <a class="btn btn-sm btn-secondary float-right m-1" (click)="onBack()" ><i class="fas fa-times"></i><span class="d-none d-sm-inline-block">&ensp;Annulla</span></a>
        </div>-->


        <div class="pt-2"> 
            <div class="tab-content" id="myTabContent" >
                
                <!-- GENERALE-->
                <div class="tab-pane fade show active" *ngIf="tab==1">
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>ID</label>
                        </div>
                        <div class="col-lg-2">
                            <input readonly type="text" class="form-control form-control-sm alert-info" name="id" placeholder="..." #id="ngModel" [(ngModel)]="record.id">
                        </div>
                        <div class="col-lg-4 text-right">
                            <label>Tipologia</label>
                        </div>
                        <div class="col-lg-4">

                            <app-type [mode]="2" [id_type]="record.id_type" [table]="'products'" [record]="record" ></app-type>
                            <!--
                            <select class="form-control form-control-sm" [(ngModel)]="record.id_type" name="id_type" #id_type="ngModel" required>
                                <option *ngFor="let t of type_products" [ngValue]="t.id">{{t.name}}</option>
                            </select>
                            <div *ngIf="id_type.invalid && (id_type.dirty || id_type.touched)" class="alert alert-danger">
                                <div *ngIf="id_type.errors.required">Campo obbligatorio.</div>
                            </div>
                        -->
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Descrizione</label>
                        </div>
                        <div class="col-lg-10">
                            <input autofocus required type="text" class="form-control form-control-sm" name="name" placeholder="..." #name="ngModel" [(ngModel)]="record.name">
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Cod. Art.</label>
                        </div>
                        <div class="col-lg-2 pr-lg-right">
                            <input autofocus required type="text" class="form-control form-control-sm" name="codeinternal" placeholder="..." #codeinternal="ngModel" [(ngModel)]="record.codeinternal">
                        </div>
                        <div class="col-lg-2 pr-lg-0 text-lg-right">
                            <label>Cod. Forn.</label>
                        </div>
                        <div class="col-lg-2 pr-lg-right text-right">
                            <input type="text" class="form-control form-control-sm" name="codeexternal" placeholder="..." #codeexternal="ngModel" [(ngModel)]="record.codeexternal">
                        </div>
                        
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>EAN (singolo)</label>
                        </div>
                        <div class="col-lg-2">
                            <input type="text" class="form-control form-control-sm" name="barcode" placeholder="..." #barcode="ngModel" [(ngModel)]="record.barcode">
                        </div>

                        <div class="col-lg-2 pr-lg-0 text-lg-right">
                            <label>EAN <small>(pacco)</small></label>
                        </div>
                        <div class="col-lg-2">
                            <input type="text" class="form-control form-control-sm" name="barcode_pack" placeholder="..." #barcode_pack="ngModel" [(ngModel)]="record.barcode_pack">
                        </div>

                        <div class="col-lg-2 text-lg-right">
                            <label>Quantita EAN <small>(pacco)</small></label>
                        </div>
                        <div class="col-lg-2">
                            <input type="number" class="form-control form-control-sm" name="barcode_quantity" placeholder="..." #barcode_quantity="ngModel" [(ngModel)]="record.barcode_quantity">
                        </div>
                    </div>                    
                    <div class="form-group row mt-3 mb-1" *ngIf="isModuleEnabled('inventories')">
                        <div class="col-lg-2 pr-0">
                            <label>Prodotto a magazzino</label>
                        </div>
                        <div class="col-lg-6">
                            <input class="mt-2" type="checkbox" name="stockable" #stockable="ngModel" [(ngModel)]="record.stockable" >
                            <!-- <i class="fas fa-info-circle mr-1 ml-3 text-gray"></i> -->
                            <small class="ml-2" *ngIf="record.stockable">Il bene ha giacenza e può essere movimentato tra i magazzini</small>
                            <small class="ml-2" *ngIf="!record.stockable">Il prodotto non ha giacenza e non può essere movimentato tra i magazzini (servizio ?)</small>
                        </div>
                        <div class="col-lg-2 text-right">
                            <label>UM <small>(Unità di Misura)</small></label>
                        </div>
                        <div class="col-lg-2">
                            <input type="text" class="form-control form-control-sm" name="unit" placeholder="..." #unit="ngModel" [(ngModel)]="record.unit">
                        </div>
                    </div>
                    <ng-container *ngIf="isModuleEnabled('installations')">
                        <hr/>
                        <div class="form-group row mb-1">
                            <div class="col-lg-2 pr-0">
                                <label>Prodotto noleggiabile</label>
                            </div>
                            <div class="col-lg-10">
                                <input class="mt-2" type="checkbox" name="rentable" placeholder="..." #rentable="ngModel" [(ngModel)]="record.rentable" >
                                <ng-container *ngIf="record.rentable">
                                    <!-- <i class="fas fa-info-circle mr-1 ml-3 text-gray"></i> -->
                                    <small class="ml-2">Il prodotto appare nelle liste dei beni noleggiabili</small>
                                </ng-container>
                            </div>                    
                            <div class="col-lg-2 pr-0">
                                <label>Prodotto con seriali</label>
                            </div>
                            <div class="col-lg-1">
                                <input class="mt-2" type="checkbox" name="has_serials" placeholder="..." #has_serials="ngModel" [(ngModel)]="record.has_serials" >
                            </div>
                            <div class="col-lg-3 pr-lg-0 text-lg-right" *ngIf="record.has_serials">
                                <label>Pattern per il SN <small>(autogenerato)</small></label>
                            </div>
                            <div class="col-lg-6" *ngIf="record.has_serials">
                                <input type="text" class="form-control form-control-sm" placeholder="esempio: AA{sn}" name="snpattern" #snpattern="ngModel" [(ngModel)]="record.snpattern">
                            </div>
                        </div>
                    </ng-container>
                    <hr/>
                    <div class="form-group row mb-1" *ngIf="isModuleEnabled('address')">
                        <div class="col-lg-2">
                            <label>Fornitore</label>
                        </div>
                        <div class="col-lg-10">
                            <div class="input-group">
                                <input *ngIf="record.supplier" type="text" class="form-control form-control-sm" value="{{record.supplier.name}}"/>
                                <div class="input-group-append ">
                                    <a class="btn btn-sm btn-default" (click)="selectSupplier()"><span class="d-none d-sm-inline-block mr-2"><i class="fa fa-user mr-2"></i>Seleziona</span></a>
                                    <a class="btn btn-sm btn-default" *ngIf="record.id_supplier>0" (click)="record.id_supplier=0;record.supplier=null;"><i class="fa fa-times text-danger"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Marca</label>
                        </div>
                        <div class="col-lg-10">
                            <div class="input-group">
                                <select class="form-control form-control-sm" [(ngModel)]="record.id_brand" name="id_brand" #id_brand="ngModel" required>
                                    <option *ngFor="let t of brands" [ngValue]="t.id">{{t.name}}</option>
                                </select>
                                <div class="input-group-append ">
                                    <a class="btn btn-sm btn-default" title="Apri gestione marche" (click)="openBrandsManager()"><i class="fa fa-cog"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Categoria</label>
                        </div>
                        <div class="col-lg-10">
                            <select [class.is-invalid]="!record.id_category" class="form-control form-control-sm" [(ngModel)]="record.id_category" name="id_category" #id_category="ngModel" required>
                                <option *ngFor="let t of categories" [ngValue]="t.id"><span *ngFor="let i of [].constructor(t.level-1)"> - </span>{{t.name}}</option>
                            </select>
                            <!-- <div *ngIf="id_category.invalid && (id_category.dirty || id_category.touched)" class="alert alert-danger">
                                <div *ngIf="id_category.errors.required">Campo obbligatorio.</div>
                            </div> -->
                        </div>
                    </div>
                    <div class="form-group row mb-0">
                        <div class="col-lg-2">
                            <label>Costo</label>
                        </div>
                        <div class="col-lg-2 pr-lg-0">
                            <div class="input-group input-group-sm">
                                <input type="number" class="form-control form-control-sm text-right" min="0" step="0.001" 
                                name="netcost" placeholder="..." [(ngModel)]="record.netcost" (keyup)="calculateCost()" (change)="calculateCost()">
                                <div class="input-group-append">
                                    <span class="input-group-text">&euro;</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1 pr-lg-0 text-lg-right">
                            <label>Sconti <small>%</small></label>
                        </div>
                        <div class="col-lg-1 pr-lg-0">
                            <div class="input-group input-group-sm">
                                <input  title="% Sconto 1"  class="form-control form-control-sm" 
                                 min="0" step="1" name="discountcost1" placeholder="..." 
                                [(ngModel)]="record.discountcost1" (keyup)="calculateCost()" (change)="calculateCost()">
                            </div>
                        </div>
                        <div class="col-lg-1 px-lg-0">
                            <div class="input-group input-group-sm">
                                <span class="p-2">+</span>
                                <input   title="% Sconto 2" class="form-control form-control-sm" 
                                 min="0" step="1" name="discountcost2" placeholder="..." 
                                [(ngModel)]="record.discountcost2" (keyup)="calculateCost()" (change)="calculateCost()">
                            </div>
                        </div>
                        <div class="col-lg-1 px-lg-0">
                            <div class="input-group input-group-sm">
                                <span class="p-2">+</span>
                                <input  title="% Sconto 3" class="form-control form-control-sm" 
                                 min="0" step="1" name="discountcost3" placeholder="..."
                                [(ngModel)]="record.discountcost3" (keyup)="calculateCost()" (change)="calculateCost()">
                            </div>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                            <label>Totale <small>IVA esclusa</small></label>
                        </div>
                        <div class="col-lg-2 pl-lg-0">
                            <div class="input-group input-group-sm">
                                <input readonly type="text" class="form-control form-control-sm text-right" value="{{record.netcost*(1-record.discountcost1/100)*(1-record.discountcost2/100)*(1-record.discountcost3/100) | currency:'&euro;'}}"/>
                                <div class="input-group-append"><span class="input-group-text">&euro;</span></div>
                            </div>
                        </div>
                       
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-lg-9 text-lg-right">
                            <label>IVA <small>%</small></label>
                        </div>
                        <div class="col-lg-1 px-lg-1">
                            <div class="input-group input-group-sm">
                                <input type="text" class="form-control form-control-sm" placeholder="" name="taxcost" placeholder="..." #tax="ngModel" [(ngModel)]="record.taxcost" (keyup)="calculateCost();">
                            </div>
                        </div>
                        <div class="col-lg-2 pl-lg-0">
                            <div class="input-group input-group-sm">
                                <input readonly type="text" class="form-control form-control-sm text-right" value="{{(record.netcost*(1-record.discountcost1/100)*(1-record.discountcost2/100)*(1-record.discountcost3/100))*record.taxcost/100 | currency:'&euro;'}}"/>
                                <div class="input-group-append"><span class="input-group-text">&euro;</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row mb-2">
                        <div class="col-lg-10 text-lg-right">
                            <label>Totale <small>IVA inclusa</small></label>
                        </div>
                        <div class="col-lg-2 pl-lg-0">
                            <div class="input-group input-group-sm">
                                <input type="text" class="form-control form-control-sm text-right" value="{{record.grosscost}}" (keyup)="calculateCostNet($event);"/>
                                <div class="input-group-append"><span class="input-group-text">&euro;</span></div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div class="form-group row mb-2">
                        <div class="col-lg-2">
                            <label>Scorta minima</label>
                        </div>
                        <div class="col-lg-2">
                            <div class="input-group input-group-sm">
                                <input type="number" class="form-control form-control-sm" min="1" step="1" name="minstock" [(ngModel)]="record.minstock" />
                                <div class="input-group-append">
                                    <span class="input-group-text">{{record.unit}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 pr-lg-0 text-lg-right">
                            <label>Sconto massimo</label>
                        </div>
                        <div class="col-lg-2">
                            <div class="input-group input-group-sm">
                                <input type="number" class="form-control form-control-sm" min="1" step="1" name="maxdiscount" [(ngModel)]="record.maxdiscount" />
                                <div class="input-group-append">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                            <label>IVA <small>(vendita)</small></label>
                        </div>
                        <div class="col-lg-2 pl-lg-0">
                            <div class="input-group input-group-sm">
                                <input type="number" class="form-control form-control-sm" min="1" step="1" name="tax" [(ngModel)]="record.tax" />
                                <div class="input-group-append">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                    </div>

                     <!-- LISTINO PREZZI-->
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Listino Prezzi</label>
                        </div>
                        <div class="col-lg-10">
                            <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm" >
                                <thead class="bg-light">
                                    <th>Tipologia</th>
                                    <th>Ricarico</th>
                                    <th>Prezzo <small>(IVA esclusa)</small></th>
                                    <th>Prezzo <small>(IVA inclusa)</small></th>
                                    <th>
                                        <a class="btn btn-xs btn-success float-right" (click)="addPricelist()"><i class="fa fa-plus"></i></a>
                                    </th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let p of record.pricelist;let i=index">
                                        <td>

                                            <div class="input-group input-group-sm">
                                                
                                                <app-type [showAll]="true" [mode]="2" [id_type]="getTypePricelist(p.tag)" [table]="'addresses'" (onEntrySelected)="p.tag=setTypePricelist($event.id_type,$event.name,p.tag)"   ></app-type>
                                                <!--
                                                <input  class="form-control form-control-sm" type="text" size="3"   name="tag[{{p.id}}]" placeholder="..." [(ngModel)]="p.tag" >
                                                -->                         
                                            </div>                                        
                                        </td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input  class="form-control form-control-sm text-right" type="number" size="3"   min="0" step="1" name="markup[{{p.id}}]" placeholder="..." [(ngModel)]="p.markup" (keyup)="calculateMarkup2Price(p)" >
                                                <div class="input-group-append">
                                                    <span class="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input class="form-control form-control-sm text-right" type="number" size="10"  min="0" step="0.01" name="price[{{p.id}}]" placeholder="..." [(ngModel)]="p.price" (keyup)="calculatePrice2Markup(p)" >
                                                <div class="input-group-append">
                                                    <span class="input-group-text">&euro;</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input class="form-control form-control-sm text-right" type="number" size="10"  min="0" step="0.01" name="pricegross[{{p.id}}]" placeholder="..." [(ngModel)]="p.grossprice" (keyup)="calculatePriceGross2Markup(p)" >
                                                <div class="input-group-append">
                                                    <span class="input-group-text">&euro;</span>
                                    
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <a class="btn btn-xs btn-danger float-right" (click)="removePricelist(p)"><i class="fa fa-trash"></i></a>
                                        </td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- CARATTERISTICHE-->
                <div class="tab-pane fade show active" *ngIf="tab==2">
                    
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Tags</label>
                        </div>
                        <div class="col-lg-10">
                            <input  type="text" class="form-control form-control-sm" name="tags" placeholder="..." #tags="ngModel" [(ngModel)]="record.tags">
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Dimensioni</label>
                        </div>
                        <div class="col-lg-10">
                            <div class="input-group input-group-sm">
                                <input  type="text" class="form-control form-control-sm" name="height" placeholder="..." #height="ngModel" [(ngModel)]="record.height">
                                <div class="input-group-append">
                                    <span class="input-group-text">cm</span>
                                </div>
                                <input  type="text" class="form-control form-control-sm" name="width" placeholder="..." #width="ngModel" [(ngModel)]="record.width">
                                <div class="input-group-append">
                                    <span class="input-group-text">cm</span>
                                </div>
                                <input  type="text" class="form-control form-control-sm" name="length" placeholder="..." #length="ngModel" [(ngModel)]="record.length">
                                <div class="input-group-append">
                                    <span class="input-group-text">cm</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Peso</label>
                        </div>
                        <div class="col-lg-4">
                            <div class="input-group input-group-sm">
                                <input  type="text" class="form-control form-control-sm" name="weight" placeholder="..." #weight="ngModel" [(ngModel)]="record.weight">
                                <div class="input-group-append">
                                    <span class="input-group-text">Kg</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-customfields  [list]="" [tag]="'features'" [table]="'installations'" [id_table]="record.id" [condition_id]="record.id_category"  [condition_field]="'category'"></app-customfields>
                </div>

                <!-- MAGAZZINO -->
                <div class="tab-pane fade show active" *ngIf="tab==3">                   
                    <app-inventories [mode]="'embedded'" [id_product]="record.id" ></app-inventories>
                </div>

                <!-- DOCUMENTI -->
                <div class="tab-pane fade show active" *ngIf="tab==6">
                    <div class="row">
                        <div class="col-12">
                            <div class="mb-2">
                                <span class="badge  border mr-1 pointer" title="Fattura" [class.bg-olive]="check_filterdocument(1)" (click)="filterDocument(1)">FS</span>
                                <span class="badge  border mr-1 pointer" title="Fattura Acconto" [class.bg-lime]="check_filterdocument(12)" (click)="filterDocument(12)">FA</span>
                                <span class="badge  border mr-1 pointer" title="Fattura Accompagnatoria" [class.bg-teal]="check_filterdocument(2)" (click)="filterDocument(2)">FV</span>
                                <span class="badge  border mr-1 pointer" title="Nota di Credito" [class.bg-maroon]="check_filterdocument(3)" (click)="filterDocument(3)">NC</span>
                                <span class="badge  border mr-1 pointer" title="Preventivo" [class.bg-warning]="check_filterdocument(4)" (click)="filterDocument(4)">PR</span>
                                <span class="badge  border mr-1 pointer" title="Documento Di Trasporto" [class.bg-lightblue]="check_filterdocument(5)" (click)="filterDocument(5)">DDT</span>
                                <span class="badge  border mr-1 pointer" title="Scontrino" [class.bg-gray]="check_filterdocument(6)" (click)="filterDocument(6)">SC</span>
                                <span class="badge  border mr-1 pointer" title="Trasferimento" [class.badge-secondary]="check_filterdocument(10)" (click)="filterDocument(10)">TR</span>
                                <span class="badge  border mr-1 pointer" title="Ordine Cliente" [class.bg-secondary]="check_filterdocument(7)" (click)="filterDocument(7)">OC</span>
                                <span class="badge  border mr-1 pointer" title="Ordine Fornitore" [class.bg-secondary]="check_filterdocument(8)" (click)="filterDocument(8)">OF</span>
                                <span class="badge  border mr-1 pointer" title="Non Fiscale" [class.bg-secondary]="check_filterdocument(9)"(click)="filterDocument(9)">NF</span>
                                <span class="badge  border mr-1 pointer" title="Documento Amministrativo" [class.bg-secondary]="check_filterdocument(11)" (click)="filterDocument(11)">DA</span>
                                <span class="badge  border mr-1 pointer" title="Inventario" [class.bg-secondary]="check_filterdocument(13)" (click)="filterDocument(13)">INV</span>
                                <span class="badge border mr-1 pointer"  (click)="filterDocument(0)">Deseleziona</span>
                                |
                                <span class="badge border ml-1 pointer"  [class.bg-secondary]="filter_inventory" (click)="filter_inventory=!filter_inventory">Solo movimentati</span>
                            </div>
                            <div *ngIf="!documents || documents.length==0" class="m-5 text-center text-secondary">
                                <i class="icon fas fa-info-circle mr-2"></i><i>Nessun documento presente</i>
                            </div>
                            <table class="table table-sm table-striped" *ngIf="documents || documents.length>0">
                                <thead>
                                    <th></th>
                                    <th>Data</th>
                                    <th>Documento</th>
                                    <th>Riferimento</th>
                                    <th>Causale</th>
                                    <th>Intestazione</th>
                                    <th>Importo</th>
                                    <th>IVA</th>
                                    <th>Totale</th>
                                    <th>Quantità</th>
                                    <th>Stato</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let d of documents">
                                        <tr *ngIf="showDocuments(d)" >
                                            <td>                                           
                                                <span *ngIf="d.id_type==2">in Ingresso</span>
                                                <span *ngIf="d.id_type==1">in Uscita</span>
                                            </td>
                                            <td>
                                                {{d.date | date:"d/M/Y"}}
                                            </td>
                                            <td>
                                                {{getTypeDocument(d.typedocument)}}
                                            </td>
                                            <td>
                                                {{d.reference}} {{d.referenceprefix}}
                                            </td>
                                           
                                            <td>
                                                {{d.description}}
                                            </td>
                                            <td>
                                                {{d.name}}
                                            </td>
                                            <td>
                                                {{d.netprice*d.quantity*(1-d.discount1/100)*(1-d.discount2/100)*(1-d.discount3/100) | currency:"EUR"}}
                                            </td>
                                            <td>
                                                {{d.netprice*d.quantity*(1-d.discount1/100)*(1-d.discount2/100)*(1-d.discount3/100)*(d.tax/100) | currency:"EUR"}}
                                            </td>
                                            <td>
                                                {{d.netprice*d.quantity*(1-d.discount1/100)*(1-d.discount2/100)*(1-d.discount3/100)*(1+d.tax/100) | currency:"EUR"}}
                                            </td>
                                            <td>
                                                <ng-container *ngIf="d.id_inventory_detail_load>0 || d.id_inventory_detail_unload>0">
                                                    <span *ngIf="d.id_inventory_detail_load>0">+</span>{{d.quantity}} {{d.unit}}
                                                </ng-container>
                                            </td>
                                            <td>
                                                <span class="badge badge-warning m-1" *ngIf="d.state==1">da confermare</span>
                                                <span class="badge badge-success m-1" *ngIf="d.state==2">Confermato</span>
                                                <span class="badge badge-warning text-danger m-1" *ngIf="d.id_inventory_detail_unload>0"><i class="fa fa-boxes mr-1"></i>Scaricato</span>
                                                <span class="badge badge-warning text-success m-1" *ngIf="d.id_inventory_detail_load>0"><i class="fa fa-boxes mr-1"></i>Caricato</span>
                                            </td>
                                            
                                            <th><a (click)="openDocument(d)" class="btn btn-sm btn-secondary float-right"><i class="fa fa-pencil-alt"></i></a></th>
                                        </tr>
                                    </ng-container>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="9"><b>Totale</b></td>
                                        <td><b>{{getTotalDocuments().quantity}}</b></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- STATISTICHE -->
                <div class="tab-pane fade show active" *ngIf="tab==8">
                    <div class="form-group row">
                        <div class="col-lg-1 text-right">
                            <label>Anno</label>
                        </div>
                        <div class="col-lg-1">
                            <input type="number" class="form-control form-control-sm" name="search_year_analytic" [(ngModel)]="search_year_analytic">
                        </div>
                        <div class="col-lg-1 text-right">
                            <label>Tipo</label>
                        </div>
                        <div class="col-lg-2">
                            <select class="form-control form-control-sm" name="search_type_analytic" [(ngModel)]="search_type_analytic">
                                <option value="1">in Uscita</option>
                                <option value="2">in Ingresso</option>
                            </select>
                        </div>
                        <div class="col-lg-2 text-right">
                            <label>Documenti</label>
                        </div>
                        <div class="col-lg-3">
                            <select class="form-control form-control-sm" name="search_id_document_analytic" [(ngModel)]="search_id_document_analytic">
                                <option value="1,2,12">Solo fatture</option>
                                <option value="6">Scontrini</option>
                                <option value="1,2,12,6">Fatture e Scontrini</option>                                
                            </select>
                        </div>
                        <div class="col-lg-2">
                            <button class="btn btn-sm btn-secondary float-right" (click)="getAnalytic()"><i class="fa fa-search mr-2"></i>Cerca</button>
                        </div>
                    </div>
                    <div class="row"> 
                        <div class="col-12">
                            <!-- <div *ngIf="!analitycs || analitycs.length==0" class="m-5 text-center text-secondary">
                                <i class="icon fas fa-info-circle mr-2"></i><i>Nessun elemento soddisfa i parametri di ricerca impostati!</i>
                            </div> -->
                            <table *ngIf="analitycs && analitycs.length>0" class="table">
                                <thead>
                                    <th>Mese</th>
                                    <th>Importo</th>
                                    <th>Imposta</th>
                                    <th>Totale</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let a of analitycs">
                                        <td>{{a.date | date:"MMMM"}}</td>
                                        <td>{{a.total_net | currency:"EUR"}}</td>
                                        <td>{{a.total_tax | currency:"EUR"}}</td>
                                        <td><b>{{a.total_gross | currency:"EUR"}}</b></td>
                                        
                                    </tr>
                                </tbody>
                                <tfoot *ngIf="total_analytics">
                                    <tr>
                                        <td><b>TOTALE</b></td>
                                        <td><b>{{total_analytics['net'] | currency:"EUR"}}</b></td>
                                        <td><b>{{total_analytics['tax'] | currency:"EUR"}}</b></td>
                                        <td><b>{{total_analytics['gross'] | currency:"EUR"}}</b></td>
                                    </tr>
                                </tfoot>
                            </table>

                        </div>

                    </div>
                </div>

                <!-- SERIALI -->
                <div class="tab-pane fade show active" *ngIf="tab==4">
                    <app-serials-list [mode]="'embedded'" [id_product]="record.id"></app-serials-list>
                </div>

                <!-- SCHEDA WEB -->
                <div class="tab-pane fade show active" *ngIf="tab==5">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group row mb-1">
                                <div class="col-lg-6 text-center mt-3" *ngFor="let i of record.images">
                                
                                    <img src="{{i.imagePath}}" class="img-thumbnail" style="height: 100px;"><br>
                                    <a (click)="removeImage(i)" class="btn btn-sm btn-danger mt-1"><i class="fa fa-trash mr-1"></i> Elimina</a>
                                
                                </div>
                                <div class="col-lg-6 text-center mt-4">
                                    <a class="btn btn_add_photo btn-default" (click)="fileToUpload.click()"><i class="fa fa-3x fa-plus"></i><br/>Aggiungi foto</a>
                                </div>
                                <input type="file" style="display: none;" #fileToUpload name="fileToUpload" (change)="handleFileInput($event.target.files)">
                            </div>
                        </div>
                        <div class="col-lg-8">
                        <div class="form-group mb-1">
                            <div class="col-12"><label>Titolo</label></div>
                            <div class="col-12">
                                <input type="text" name="title" class="form-control form-control-sm" [(ngModel)]="record.title"/>
                            </div>
                        </div>
                        <div class="form-group mb-1">
                            
                            <div class="col-12">
                                <label>Descrizione</label>
                            </div>
                            <div class="col-12">
                                <textarea class="form-control form-control-sm" name="description" [(ngModel)]="record.description" rows="20"></textarea>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                <!-- CORRELATI -->
                <div class="tab-pane fade show active" *ngIf="tab==7">                                    
                    <div *ngFor="let p of record.correlations" class="row">
                        <div class="col-10">
                            {{p.name}}<span class="badge border ml-2">{{p.codeinternal}}</span>
                        </div>
                        <div class="col-2 text-right">
                            <a class="btn btn-sm btn-danger m-1" title="Elimina" (click)="removeCorrelation(p);"><i class="fa fa-trash"></i></a>
                            <a class="btn btn-sm btn-info m-1" title="Visualizza" (click)="openCorrelation(p);"><i class="fa fa-eye"></i></a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">       
                            <a class="btn btn-success btn-sm" (click)="addCorrelation()"><i class="fas fa-plus mr-2"></i>Aggiungi</a>
                        </div>
                    </div>                   
                </div> 
                
                
                <!-- STATISTICA-->
                <div class="tab-pane fade show active" *ngIf="tab==9">

                </div>

            </div>
        </div>

    </form>
</app-window>
<app-toolbarmodel [model]="this"></app-toolbarmodel>
