import { Component, OnInit } from '@angular/core';
import { ModelList } from '../../../include/modellist'
import { UntypedFormBuilder } from '@angular/forms';
import { Filter, FilterMode, UserGroup } from '../../../include/structures';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '../../../globals';
import { UserGroupService } from 'projects/core/src/lib/usergroup.service';
import { UserGroupComponent } from './detail/usergroup.component';

@Component({
  selector: 'app-listusergroups',
  templateUrl: './usergroups.component.html',
  styleUrls: ['./usergroups.component.sass']
})

export class UserGroupsComponent extends ModelList<UserGroup> implements OnInit {

 
  get currentUser(){
    return Globals.user;
  }


  
  filter_search:Filter=new Filter();

  constructor(
    private usergroupService: UserGroupService,
    private fb1: UntypedFormBuilder,
    private modalService:NgbModal
  ) {
    super(usergroupService,fb1);
    this.detailViewModal=UserGroupComponent;
   }


  ngOnInit(): void {
    this.title='Ruoli';
    super.ngOnInit.apply(this, arguments); 
    this.getItems();
   
    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("ug.title");
    this.filter_search.fields.push("ug.name");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

  }


  confirm_delete(id){

    Globals.modal.showConfirm("","Confermi di voler eliminare il ruolo "+id.title+"?",()=>{
      this.delete(id)
    },"Sì, elimina","No. Annulla","danger")

  }

 
}
