<app-windowlist [model]="this">
    <div class="card">
        <div class="card-body p-0">
            <form>
            <table class="table table-sm table-striped text-nowrap m-0" >
                <thead>
                    <th>ID</th>
                    <th>Nome</th>
                    <th>Tipologia</th>
                    <th><button (click)="addNotification()"  title="Aggiungi" data-toggle="modal" data-target="#modalOptionCustomField"  class="btn btn-success btn-xs float-right mr-1"><i class="fas fa-plus"></i></button></th>
                </thead>
                <tbody>

                    <!-- elemento ripetuto -->
                    <tr *ngFor="let record of list">
                        <td>
                            <i *ngIf="record.status==1"  title="abilitato" class="fa fa-check text-success"></i>
                            <i *ngIf="record.status==0"  title="disabilitato" class="fas fa-times text-danger"></i>
                        
                            {{record.id}}
                        </td>
                        
                        <td class="pl-2">
                            {{record.name}}
                        </td>
                        <td>
                            {{record.table}}
                        </td>
                        <td class="pr-2">
                            <a type="button"  *ngIf="mode=='modal'" class="btn btn-secondary btn-sm" (click)="selectRecord(record);">
                                <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                            </a>
                            <button *ngIf="mode!='modal'" type="button"  title="Elimina" class="btn btn-danger btn-xs float-right ml-1"  (click)="confirm_delete(record.id)">
                                <i class="fas fa-trash"></i>
                            </button>
                            <button *ngIf="mode!='modal'" type="button" class="btn btn-secondary btn-xs float-right ml-1" data-toggle="modal" data-target="#modalOptionCustomField" (click)="setNotify(record)">
                                <i class="fas fa-pencil-alt"></i></button>
                            <button *ngIf="mode!='modal'" type="button"  title="Duplica" class="btn btn-info btn-xs float-right ml-1"  (click)="duplicate(record)">
                                <i class="fas fa-clone"></i>
                            </button>
                        </td>
                    </tr>

                </tbody>
            </table>
            </form>
        </div>
        <div class="card-footer clearfix py-1">
            <small class="mr-1">Visualizzati <b class="text-primary" style="font-size: 110%;">{{recordcount}}</b> elementi</small>
        </div>
    </div>
</app-windowlist>

<div class="modal fade" id="modalOptionCustomField" tabindex="-1" role="dialog" >
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content ">
            <div class="modal-header">
                <b>Impostazione campo</b>
                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-dismiss="modal">
                        <i class="fas fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="modal-body" *ngIf="selectedNotify">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="form-group form-group-sm">
                            <label>Nome</label>
                            <input class="form-control form-control-sm" type="text" name="name" [(ngModel)]="selectedNotify.name"/>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group form-group-sm">
                            <label>Stato</label>
                            <div class="input-group input-group-sm" >
                                <select name="status" class="form-control form-control-sm" name="status" [(ngModel)]="selectedNotify.status">
                                    <option value="0">Non pubblicato</option>
                                    <option value="1">Pubblicato</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="form-group form-group-sm">
                            <label>Tabella</label>
                            <app-selecttables [name]="'table'" [value]="selectedNotify.table" [show_all]="false" (onchange)="selectedNotify.table=$event;"></app-selecttables>
                        </div>
                    </div>
                    <div class="col-lg-12 mt-3">
                        <div class="form-group">
                            <h5>Condizioni</h5>
                            <small>Indicare le condizioni che si devono verificare per applicare la regola</small>
                            <table class="table table-sm mb-0">
                                <thead>
                                    <th>Campo</th>
                                    <th>Condizione</th>
                                    <th>Valore</th>
                                    <th></th>
                                    <th><button type="button" class="btn btn-xs btn-success float-right" (click)="addCondition()"><i class="fa fa-plus"></i></button></th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let c of selectedNotify.conditionsArray">
                                        <td><input type="text" class="controls" [(ngModel)]="c.field"/></td>
                                        <td><select class="controls" [(ngModel)]="c.condition">
                                                <option value="ugual">=</option>
                                                <option value="minor">minore</option>
                                                <option value="major">maggiore</option>
                                            </select> 
                                        </td>
                                        <td><input type="text" class="controls" [(ngModel)]="c.value"/></td>
                                        <td><button type="button" class="btn btn-sm btn-danger" (click)="removeCondition(c)"><i class="fa fa-trash"></i></button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-12 mt-2">
                        <ul class=" nav nav-tabs auto ">
                            <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab3a" aria-selected="false">Email</a></li>
                            <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#tab2" role="tab" aria-controls="tab3a" aria-selected="false">SMS</a></li>
                            <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#tab3" role="tab" aria-controls="tab3a" aria-selected="false">Whatsapp</a></li>
                        </ul>
                    </div>
                    <div class="tab-content" id="myTab2Content">
                        <div class="tab-pane p-4 fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1a-tab">
                            <app-gateway *ngIf="gateway_email!=undefined" [gateway]="gateway_email" (OnFieldsChanged)="gateway_email=$event" [isConfig]="true" ></app-gateway>
                        </div>
                        <div class="tab-pane p-4 fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                            <app-gateway *ngIf="gateway_sms!=undefined" [gateway]="gateway_sms" (OnFieldsChanged)="gateway_sms=$event" [isConfig]="true"></app-gateway>
                        </div>
                        <div class="tab-pane p-4 fade" id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
                            <app-gateway *ngIf="gateway_whatsapp!=undefined" [gateway]="gateway_whatsapp" (OnFieldsChanged)="gateway_whatsapp=$event" [isConfig]="true"></app-gateway>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success" data-dismiss="modal" (click)="confirmNotify()"><i class="fas fa-save mr-1"></i>Conferma</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal"  >Annulla</button>
            </div>
        </div>
    </div>
</div>