import { Component, OnInit ,Input,Output,EventEmitter, HostListener, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {ProductsService} from '../../services/products.service'; 
import {Globals} from 'projects/core/src/globals';
import {ProductsComponent} from '../../views/products/products.component'
import {Product,Pricelist} from '../../db/product';
import { SNAvaible } from '../../db/inventories';
import { ToastMode } from 'projects/core/src/include/structures';
import { QrcodereaderComponent } from 'projects/core/src/common/qrcodereader/qrcodereader.component';
import { ProductsDetailsComponent } from '../../views/products/products-details/products-details.component';
import { Address } from '../../db/address';

@Component({
  selector: 'app-searchproduct',
  templateUrl: './searchproduct.component.html',
  styleUrls: ['./searchproduct.component.sass']
})
export class SearchproductComponent implements OnInit ,AfterViewInit {
  
  @ViewChild("searchCode")
  searchCode:ElementRef;
  
  @Input()
  code:string="";

  @Input()
  description:string="";
  @Input()
  id_address:number=0;

  @Input()
  address:Address=null;

  @Input()
  quantity:number=0;

  @Input()
  type:number=2; //1== acquisto 2 == vendita

  @Input()
  serial:string="";

  @Input()
  showSN:boolean=true;

  @Input()
  showDescription:boolean=true;

  @Input()
  showPrice:boolean=true;

  @Input()
  editSN:boolean=false;

  @Input()
  resetOnEnter:boolean=false;

  @Input()
  autoSelect:boolean=true; //permette di selezionare un record in automatico se è il singolo della lista

  @Input()
  canExploreCatalog:boolean=true;
  @Input()
  canEdit:boolean=true;

  @Input()
  canNewProduct:boolean=false;


  @Input()
  canQRCode:boolean=true;
  @Input()
  enableHeader:boolean=true;
  
  pricelist:Pricelist[]=[];
  productSearched:Product;
  serialList:SNAvaible[]=[];


  @Output() onEntrySelected = new EventEmitter();

  @Output() onNoEntrySelected = new EventEmitter();


  constructor(private productsService:ProductsService) { }
  
  
  focusSearch(){
    this.searchCode.nativeElement.focus();
  }

  ngOnInit(): void {
    
  }

  ngAfterViewInit():void{
    this.focusSearch();
  }

  parseProduct(item):Product{
    let p:Product=new Product();
    let props=Object.getOwnPropertyNames(item);
    for(let k of props){
      p[k]=item[k];
    }

    return p;
  }

  searchProduct(){
    if(this.code){
      this.productsService.getProduct(this.code).subscribe((item)=>{
        if(item!=null){
          this.productSearched=this.parseProduct(item);
          if(this.productSearched.sn=="" || this.productSearched.sn==undefined)
            this.productSearched.sn=this.serial;
          this.description=item.brand+" "+item.name;


          //verifica se è stato ricercato un EAN di pacco
          if(this.code==this.productSearched.barcode_pack){
            this.productSearched['quantity']=this.productSearched.barcode_quantity;
          }else{
            this.productSearched['quantity']=1;
          }

          
          this.code=item.id.toString();
          
          this.serialList=item.serials;
          this.setPrice();
          this.update();
        }else{
          Globals.message.showToaster("Nessun codice trovato",ToastMode.DANGER);
          this.onNoEntrySelected.emit();
        }

      })
    }
  }

  update():void{
    
    if(this.productSearched){
      
    }else{
      this.productSearched=new Product();
      this.productSearched.id=0;
      this.productSearched.brand="";
      this.productSearched.name=this.description;
    }
    if(this.productSearched.sn=="" || this.productSearched.sn==undefined)
      this.productSearched.sn=this.serial;


    let s=new Product();
    Object.assign(s,this.productSearched);
    s['quantity']=this.productSearched['quantity'];
    

    this.onEntrySelected.emit(s);
    if(this.resetOnEnter){
      this.reset();
      this.focusSearch();
    }
  }

  searchIntoProducts(){
    
    
    Globals.modal.showModal(ProductsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id_type_pricelist","value":this.address?this.address.id_type:0},
      //{"name":"id_supplier","value":this.id_address},
      {"name":"showPrice","value":this.showPrice},
      {"name":"canEdit","value":this.canEdit},
      {"name":"autoSelect","value":this.autoSelect}
    ],(instance)=>{
      let r=instance['recordSelected'];
      this.productSearched=this.parseProduct(r);
      this.productSearched.sn=this.serial;
      this.description=r.brand+" " +r.name;
      this.code=r.id;
      this.serialList=r.serials;
      this.setPrice();
      this.update();
    });
    
  }


  setPrice(){
    if(this.type==2){
      try{
        if(!Array.isArray(this.productSearched.pricelist)){
          this.pricelist=JSON.parse(this.productSearched.pricelist);
        }else{
          this.pricelist=this.productSearched.pricelist;
        }
        
      }catch{
        this.pricelist=[];
      }

      if(this.pricelist.length>0)
        this.productSearched.netprice=this.pricelist[0].price;
      else
        this.productSearched.netprice=0;

      
    }
    else{
      if(this.productSearched.netcost>0)
        this.productSearched.netprice=this.productSearched.netcost;
    }
  }

  reset(){
    this.code="";
    this.description="";

  }

  openQrCodeReader(){
    Globals.modal.showModal(QrcodereaderComponent,[],(instance)=>{
      if(instance!=null){
        this.code=instance['code'];
        this.searchProduct();
      }
    })
  }

  newProduct(){
    Globals.modal.showModal(ProductsDetailsComponent,[
      {"name":"mode","value":"modal"}
    ],(instance)=>{
      if(instance!=null){
        let item=instance['record'];
        this.productSearched=this.parseProduct(item);
        if(this.productSearched.sn=="" || this.productSearched.sn==undefined)
          this.productSearched.sn=this.serial;
        this.description=item.brand+" "+item.name;
        this.code=item.id.toString();
        
        this.serialList=item.serials;
        this.setPrice();
        this.update();
      }

    })
  }

}
