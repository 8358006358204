<app-modal title="Gestione magazzini" [class]="'modal-md'">
    <div class="row">
        <div class="col-12">
            <table class="table table-sm mb-0">
                <thead>
                    <th>ID</th>
                    <th>Titolo</th>
                    <th>Codice</th>
                    <th colspan="4">Indirizzo</th>
                    <th>Collegato a</th>
                    <th class="text-center">Sfondo</th>
                    <th class="text-center">Testo</th>
                    <th><button *ngIf="user.isSuperUser()" class="btn btn-success btn-xs float-right" title="Aggiungi" (click)="addInventory()"><i class="fas fa-plus"></i></button></th>
                </thead>
                <tbody>
                    <tr *ngFor="let r of list">
                        <td>{{r.id}}</td>
                        <td><input title="Titolo" type="text" class="form-control form-control-sm" name="name" [(ngModel)]="r.name"  placeholder="inserisci titolo magazzino ..." (change)="update(r)"></td>
                        <td><input title="Codice" type="text" class="form-control form-control-sm" name="code" [(ngModel)]="r.code" placeholder="inserisci codice magazzino ..." (change)="update(r)"></td>
                        <td colspan="4"><input title="Indirizzo" type="text" class="form-control form-control-sm" name="location" [(ngModel)]="r.location" placeholder="inserisci indirizzo magazzino ..." (change)="update(r)"></td>
                        <td>
                            <select class="form-control form-control-sm" name="id_parent" [(ngModel)]="r.id_parent" (change)="update(r)">
                                <option value="0"></option>
                                <option *ngFor="let r2 of list" value="{{r2.id}}">{{r2.name}}</option>
                            </select>
                        </td>
                        <td class="text-center">
                            <input class="form-control form-control-sm pointer bg-white border-0" title="Colore sfondo" type="color" [(ngModel)]="r.color" (change)="update(r)" />
                        </td>
                        <td class="text-center">
                            <input class="form-control form-control-sm pointer bg-white border-0" title="Colore testo" type="color" [(ngModel)]="r.textcolor" (change)="update(r)" />
                        </td>
                        <td class="text-right">
                            <button class="btn btn-xs btn-danger" title="Elimina" (click)="deleteInventory(r)"><i class="far fa-trash-alt"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</app-modal>