import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewformRoutingModule } from './newform-routing.module';
import { NewformComponent } from './newform.component';
import { FormsModule } from '@angular/forms';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { FieldsModule } from '../fields/fields.module';


@NgModule({
  declarations: [
    NewformComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AngularSignaturePadModule,
    NewformRoutingModule,
    FieldsModule
  ]
})
export class NewformModule { }
