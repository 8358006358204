import { Injectable, ViewChild, ViewContainerRef} from '@angular/core';
import { DeadlineRules } from '../db/deadlinerules';
import { DLMathEval } from 'dl-math-eval-ts';
import { Deadlines } from '../db/deadlines';
import { WSService } from 'projects/core/src/include/service';
import { DeadlinesService } from './deadlines.service';
import { Helper } from 'projects/core/src/helper';
import { Globals } from 'projects/core/src/globals';
import { ConfirmdeadlineComponent } from '../views/deadlines/confirmdeadline/confirmdeadline.component';
import { ConfirmdeadlinesComponent } from '../views/deadlines/confirmdeadlines/confirmdeadlines.component';

import * as math from 'mathjs';

@Injectable({
  providedIn: 'root'
})


export class DeadlinerulesService extends WSService<DeadlineRules>{

  @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;

  constructor() {
    super("deadlinerules");
   };


 


  getRule(table:string,record:any,oncomplete:Function){

    if(record['id']==null && record['idkey']!=null)
      record['id']=record['idkey'];

    let deadlinesService:DeadlinesService=new DeadlinesService();

    //verifica se ci sono delle regole per le scadenze da imporre
    Globals.ws.requestUrl<DeadlineRules[]>("deadlinerules","getItems",["d.table='"+table+"' AND d.status=1"]).subscribe((items)=>{
      
      let deadlinesToDefined=[];
      
      //verifica  le condizioni
      for(let i=0;i<items.length;i++){
        items[i].conditionsArray=JSON.parse(items[i].conditions);
        let verify:boolean=true;
        for(let j=0;j<items[i].conditionsArray.length;j++){
          //prendi il valore nel campo
          let valuefield=Helper.getFieldbyString(items[i].conditionsArray[j].field,record);
          if(valuefield==null)
            verify=false;
          else
            if(valuefield.toString()!=items[i].conditionsArray[j].value && verify==true)
              verify=false;
        }

        if(verify){

          //calcola la formula
          let formula=Helper.replaceKeyword(items[i].nextDaysFormula,record);
          formula=formula==''?"0":formula;
          let ref : any = {};
          //let days=DLMathEval.evaluateExpression(formula,ref);
          let days=math.evaluate(formula);
          let newDate:Date;
          if(items[i].field_date=="NOW"){
            newDate=new Date();
          }else{
            newDate=new Date(Helper.getFieldbyString(items[i].field_date,record));
          }

          
          newDate.setDate(newDate.getDate()+days);
          let description=Helper.replaceKeyword(items[i].description,record);
          let d:Deadlines={} as Deadlines;
          d.id=0;
          d.date=Helper.convertDateControl(newDate);
          d.description=description;
          d.table=items[i].table_assigned;
          d.id_table=Helper.getFieldbyString(items[i].field_id_table,record);
          d.id_type=items[i].id_type;
          d.reference=record;
          //verifica se ci sono customfields da inserire
          if(items[i].customfieldsassignment!=""){
            d.customfields=Helper.replaceKeyword(items[i].customfieldsassignment,record);
            d.customfields=JSON.parse(d.customfields.toString());
          }
          
          d.status=1;
          d.id_address=Helper.searchKey("id_address",record);
          /*
          //verifica che la data non sia stata già inserita
          deadlinesService.nextDeadline(d).subscribe((items)=>{
            if(items.length>0){
              d['nextDate']=items[0];
            }

            
            
            /*Globals.modal.showModal(ConfirmdeadlineComponent,[{"name":"record","value":d}],(instance)=>{
              if(instance!=null)
                deadlinesService.save(d,(id)=>{

                });
             });*/
              
            
          //})

          deadlinesToDefined.push(d);


          


          
        }

        //if(oncomplete) oncomplete(true);
        //continue;


      }


      if(deadlinesToDefined.length>0){
        Globals.modal.showModal(ConfirmdeadlinesComponent,[{"name":"list","value":deadlinesToDefined}],(instance)=>{
          if(instance!=null)
            deadlinesService.saveMultiple(instance['list'],()=>{

            });
         });
      }

      if(oncomplete) oncomplete(false);

    });
  }



 
  

  

  


}
