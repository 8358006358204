import { Component, OnInit,Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NotificationService } from 'projects/c1-backend/src/app/services/notification.service';
import { ShopsService } from 'projects/c1-backend/src/app/services/shops.service';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { ModelList } from 'projects/core/src/include/modellist';
import { Filter, FilterMode, OrderingMode, ToastMode, User } from 'projects/core/src/include/structures';
import { UserService } from 'projects/core/src/lib/user.service';
import { Booking, BookingDetail } from '../../../db/booking';
import { BookingService } from '../../../services/booking.service';
import { BookingDetailComponent } from '../booking-detail/booking-detail.component';
import { RentalpricesComponent } from '../rentalprices/rentalprices.component';
import { WizardComponent } from '../wizard/wizard.component';
import { DocumentDetail } from '../../../db/documents';
import { DocumentsDetailsComponent } from '../../documents/documents-details/documents-details.component';
import { CheckinComponent } from '../checkinbooking/checkinbooking.component';
import { CheckoutComponent } from '../checkoutbooking/checkoutbooking.component';
import { TypesService } from '../../../services/types.service';
import { MethodpaymentsService } from '../../../services/methodpayments.service';
import { DocumentsTemplateService } from '../../../services/documents-template.service';
import { BookingAddPaymentComponent } from '../addpayment/addpayment.component';

@Component({
  selector: 'app-booking-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent extends ModelList<Booking>  implements OnInit {

  @Input()
  filterbox=true;

  @Input()
  id_shop_start="";
  
  filter_status:Filter=new Filter();
  filter_search:Filter=new Filter();
  filter_state:Filter=new Filter();

  filter_date:Filter=new Filter();
  filter_type:Filter=new Filter();
  filter_id_user:Filter=new Filter();
  filter_id_shop_start:Filter=new Filter();
  filter_type_address:Filter=new Filter();

  filter_id_type_tour:Filter=new Filter();

  filter_method_payment:Filter=new Filter();

  
  @Input()
  date_from: Date=new Date();

  @Input()
  date_to: Date=new Date();

  total=0;
  users:User[];
  shops=[];

  isFilterSet:boolean=false;
  
  type_tours=[];
  methodpayments=[];

  constructor(
    private bookingService: BookingService,
    private fb1: UntypedFormBuilder,
    private userService:UserService,
    private shopsService:ShopsService,
    private notificationService:NotificationService,
    private typesService:TypesService,
    private methodpaymentsService:MethodpaymentsService,
    private documentTemplateService:DocumentsTemplateService


  ) {
    super(bookingService,fb1);
    this.detailViewModal=BookingDetailComponent;
   }

  ngOnInit(): void {
    this.title="Prenotazioni"

    super.ngOnInit.apply(this, arguments); 

    this.userService.getUsers().subscribe((items)=>{
      this.users=items;
    });

    this.typesService.getTypes("tour").subscribe((items)=>{
      this.type_tours=items;
    });

    this.methodpaymentsService.getMethods().subscribe((items_m)=>{
      this.methodpayments=items_m;
    });
    

    const default_address=Globals.parameters.get("general").getParam("default_address");


    this.shopsService.getItemsByAddress(default_address).subscribe((items)=>{
      this.shops=items;
    });
    this.afterGetItems=(()=>{
      //calcolo i valori totali
      this.bookingService.getTotals(this.filter,this.orderingList,this.advancedFilter).subscribe((item)=>{
        this.total=item['total'];
      });
    });

    //this.updateList();
  }

  ngOnChanges(){
    if(this.filter_id_shop_start)
      this.filter_id_shop_start.value=this.id_shop_start;
    if(this.filter_date){
      this.filter_date.value=Helper.convertDateControl(this.date_from);
      this.filter_date.value2=Helper.convertDateControl(this.date_to);   
    }
    
    this.updateList();
  }

  updateList():void{
    if(!this.isFilterSet)
      this.setFilters();

    this.getItems();
  }

  setFilters(){
    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("b.code");
    this.filter_search.fields.push("a.name");
    this.filter_search.fields.push("b.tracking");
    this.filter_search.value="";
    this.filter.push(this.filter_search);
    
    this.date_from.setDate(1);
    this.date_from.setMonth(1);
    this.date_from.setFullYear(this.today.getFullYear());

    this.date_to.setDate(31);
    this.date_to.setMonth(11);
    this.date_to.setFullYear(this.today.getFullYear());
    
    this.filter_date.mode=FilterMode.date;
    this.filter_date.fields=[];
    this.filter_date.fields.push("b.date_start");
    //this.filter_date.value=Helper.convertDateControl(this.date_from);
    //this.filter_date.value2=Helper.convertDateControl(this.date_to);   
    this.filter.push(this.filter_date);

    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("b.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    this.filter_state.mode=FilterMode.normal;
    this.filter_state.fields=[];
    this.filter_state.fields.push("b.state");
    this.filter_state.value="";
    this.filter.push(this.filter_state);


    this.filter_type.mode=FilterMode.normal;
    this.filter_type.fields=[];
    this.filter_type.fields.push("b.type");
    this.filter_type.value="";
    this.filter.push(this.filter_type);

    this.filter_id_type_tour.mode=FilterMode.normal;
    this.filter_id_type_tour.fields=[];
    this.filter_id_type_tour.fields.push("t.id_type");
    this.filter_id_type_tour.value="";
    this.filter.push(this.filter_id_type_tour);

    this.filter_method_payment.mode=FilterMode.contain;
    this.filter_method_payment.fields=[];
    this.filter_method_payment.fields.push("b.methodpayment");
    this.filter_method_payment.value="";
    this.filter.push(this.filter_method_payment);

    


    

    this.filter_id_user.mode=FilterMode.normal;
    this.filter_id_user.fields=[];
    this.filter_id_user.fields.push("b.created_by");
    this.filter_id_user.value="";
    this.filter.push(this.filter_id_user);

    this.filter_type_address.mode=FilterMode.normal;
    this.filter_type_address.fields=[];
    this.filter_type_address.fields.push("b.type_address");
    this.filter_type_address.value="";
    this.filter.push(this.filter_type_address);


    this.filter_id_shop_start.mode=FilterMode.normal;
    this.filter_id_shop_start.fields=[];
    this.filter_id_shop_start.fields.push("b.id_shop_start");
    this.filter_id_shop_start.value=this.id_shop_start;
    this.filter.push(this.filter_id_shop_start);

    this.ordering.field="b.date_start DESC, b.time_start";
    this.ordering.mode=OrderingMode.ascendent;

    this.isFilterSet=true;

  }

  confirmBooking(record){
    /*
    Globals.modal.showModal(ConfirmbookingComponent,[{"name":"booking","value":record}],()=>{
      this.getItems();
    },"md");

    */
  }

  openRentalPrices(){
    Globals.modal.showModal(RentalpricesComponent,[{"name":"mode","value":"modal"}],()=>{});
  }

  printInvoice(id_booking){
    this.bookingService.printInvoice(id_booking).subscribe((uri_template)=>{
      window.open(uri_template,"_blank");
    })
  }
  notify(record:Booking){
   
    this.notificationService.showPreviewNotification(record.addressItem.contacts,record,"booking",()=>{

    });
    
    
    
  }

  openWizard(){
    Globals.modal.showModal(WizardComponent,[{"name":"mode","value":"modal"}],(instance)=>{
      if(instance!=null)
        this.getItems();
    })
  }

  checkout(record){
    Globals.modal.showModal(CheckoutComponent,[{"name":"booking","value":record}],(instance)=>{
      if(instance)
        this.getItems();
    });
  }

  checkin(record){
    Globals.modal.showModal(CheckinComponent,[{"name":"booking","value":record}],(instance)=>{
      if(instance)
        this.getItems();
    });
  }


  addDocument(r){

    let booking:Booking=new Booking();
    Object.assign(booking,r);
    r=booking;
    let details:DocumentDetail[]=[];
    for(let d of r.details){
      let dd:DocumentDetail=new DocumentDetail();
      dd.id_product=d.id_product;
      dd.product=d.product;
      dd.description=d.product.title?d.product.title:(d.product.brand?d.product.brand+" ":"")+d.product.name;
      let type=r.id_tour==1?"Tour":"Noleggio";
      dd.note=type+" dal "+r.dateStart+" "+r.time_start+" al "+r.dateEnd+" "+r.time_end;
      dd.netprice=d.price*r.duration;
      dd.quantity=d.quantity;
      dd.discount1=d.discount;
      dd.discount2=0;
      dd.discount3=0;
      dd.tax=d.tax;
      details.push(dd);
    }

    let drecord={};
    Object.assign(drecord,r);
    drecord['details']=details;
   

    this.documentTemplateService.createOpenDocument("booking",drecord,this.total-r.paid,r.methodpayment,r.details,()=>{
       
      //verifica 
       this.updateList();
    });

    /*
    let record:Booking=new Booking();
    Object.assign(record,r);

    //crea il dettaglio fattura
    let details:DocumentDetail[]=[];
    for(let d of record.details){
      let dd:DocumentDetail=new DocumentDetail();
      dd.id_product=d.id_product;
      dd.product=d.product;
      dd.description=d.product.title?d.product.title:(d.product.brand?d.product.brand+" ":"")+d.product.name;
      dd.note="Noleggio dal "+record.dateStart+" "+record.time_start+" al "+record.dateEnd+" "+record.time_end;
      dd.netprice=d.price*record.duration;
      dd.quantity=d.quantity;
      dd.discount1=d.discount;
      dd.discount2=0;
      dd.discount3=0;
      dd.tax=d.tax;
      details.push(dd);
    }

    Globals.modal.showModal(DocumentsDetailsComponent,[
      {"name":"typedocument","value":1},
      {"name":"mode","value":"modal"},
      {"name":"id_type","value":1},
      {"name":"id_address","value":record.id_address},
      {"name":"description","value":record.id_tour>0?"Tour":"Noleggio"},
      {"name":"id_documentrule","value":record.id_tour>0?Globals.parameters.get("booking").getParam("id_documentrule_tour"):Globals.parameters.get("booking").getParam("id_documentrule_bike")},
      {"name":"details","value":details},
      {"name":"referenceprefix","value":record.id_tour>0?Globals.parameters.get("booking").getParam("referenceprefix_tour"):Globals.parameters.get("booking").getParam("referenceprefix_bike")},
      {"name":"id_table","value":record.id},
      {"name":"table","value":"booking"},
      {"name":"referencedocument","value":record},
      {"name":"methodpayment","value":record.methodpayment},
      {"name":"autoUnload","value":false},
      
    ],(instance)=>{
      if(instance){
        record.state=2;
        this.bookingService.save(record,()=>{
          this.getItems();
        })
      }
      
    
    })
    */
  }

  addPayment(r){
    let booking:Booking=new Booking();
    Object.assign(booking,r);
    r=booking;
    Globals.modal.showModal(BookingAddPaymentComponent,[{"name":"mode","value":"modal"},{"name":"booking","value":r}],()=>{
      this.updateList();
    },"md")
  }

  createDocument(){
    if(this.form.value.id.length==0){
      Globals.message.showToaster("Selezionare almeno una prenotazione",ToastMode.WARNING);
      return;
    }

    let ids=this.form.value.id;

    //prendi i record delle prenotazioni selezionate
    this.bookingService.getBookingByIDs(ids).subscribe((items)=>{
      let bookings:Booking[]=[];
      for(let i of items){
        let b=new Booking();
        Object.assign(b,i);
        b['table_reference']="booking";
        bookings.push(b);

      }
      
      

      //per ogni prenotazione crea il dettaglio fattura
      let details:DocumentDetail[]=[];

      for(let b of bookings){

        //nel caso di un tour
        if(b.id_tour>0){
          let dd:DocumentDetail=new DocumentDetail();
          if(b.details.length>0){
            dd.id_product=b.details[0].id_product;
            dd.product=b.details[0].product;
          }
          dd.description="Tour "+b.tour.code+" "+b.tour.title;
          dd.note="Tour per "+b.adults+" adulti e "+b.children+" bambini, partenza "+b.dateStart+" ore "+b.time_start+" e rientro il "+b.dateEnd+" ore "+b.time_end;

          if(b.type_address==1){ //nel caso di un B2B aggiungi i riferimenti di cliente e stanza
            if(b.clientname)
                dd.note=dd.note+"\n"+"Riferimento: "+b.clientname+(b.clientemail!=''?" ("+b.clientemail+")":"") + (b.clientnote!=''?"\nnote/stanza: "+b.clientnote:"");
          }
          dd.netprice=b.amount+b.tax;
          dd.quantity=1;
          dd.discount1=0;
          dd.discount2=0;
          dd.discount3=0;
          dd.tax=b.tax;
          details.push(dd);

        }else{
          for(let d of b.details){
            let dd:DocumentDetail=new DocumentDetail();
            dd.id_product=d.id_product;
            dd.product=d.product;
            if(d.product)
                dd.description=d.product.title?d.product.title:(d.product.brand?d.product.brand+" ":"")+d.product.name;
            dd.note="Noleggio dal "+b.dateStart+" "+b.time_start+" al "+b.dateEnd+" "+b.time_end;
            
            if(b.type_address==1){ //nel caso di un B2B aggiungi i riferimenti di cliente e stanza
              if(b.clientname)
                  dd.note=dd.note+"\n"+"Riferimento: "+b.clientname+(b.clientemail!=''?" ("+b.clientemail+")":"") + (b.clientnote!=''?"\nnote/stanza: "+b.clientnote:"");
            }
  
  
            dd.netprice=d.price*b.duration;
            dd.quantity=d.quantity;
            dd.discount1=d.discount;
            dd.discount2=0;
            dd.discount3=0;
            dd.tax=d.tax;
            details.push(dd);
          }
        }

        
      }


      Globals.modal.showModal(DocumentsDetailsComponent,[
        {"name":"typedocument","value":1},
        {"name":"mode","value":"modal"},
        {"name":"id_type","value":1},
        {"name":"id_address","value":bookings[0].id_address},
        {"name":"description","value":bookings[0].id_tour>0?"Tour":"Noleggio"},
        {"name":"id_documentrule","value":bookings[0].id_tour>0?Globals.parameters.get("booking").getParam("id_documentrule_tour"):Globals.parameters.get("booking").getParam("id_documentrule_bike")},
        {"name":"details","value":details},
        {"name":"referenceprefix","value":bookings[0].id_tour>0?Globals.parameters.get("booking").getParam("referenceprefix_tour"):Globals.parameters.get("booking").getParam("referenceprefix_bike")},
        {"name":"id_table","value":0},
        {"name":"table","value":"multiple"},
        {"name":"referencedocument","value":bookings},
        {"name":"methodpayment","value":bookings[0].methodpayment},
        {"name":"autoUnload","value":false},
        
      ],()=>{
        this.updateList();
      });


    
    })
      

    


    



   

    

  }

}
