import { NgModule } from '@angular/core';
import { NotificationlogsComponent } from './notificationlogs.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';


@NgModule({
    imports: [FormsModule,CommonModule],
   exports: [NotificationlogsComponent],
   declarations: [NotificationlogsComponent],
   providers: [],
})
export class NotificationlogsModule{ }