import { Component, Input, OnInit } from '@angular/core';
import { ActivitiesService } from '../../../services/activities.service';
import { Globals } from 'projects/core/src/globals';
import { ToastMode } from 'projects/core/src/include/structures';
import { Activities } from '../../../db/activities';
import { ShopsComponent } from '../../shops/shops.component';
import { ShopsService } from '../../../services/shops.service';
import { TypesService } from '../../../services/types.service';

@Component({
  selector: 'app-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.css']
})
export class UserActionsComponent implements OnInit {


  @Input()
  record:Activities=new Activities();

  product_types=[];
  shopname:string="";
  constructor(
    private activitiesService:ActivitiesService,
    private shopsService:ShopsService,
    private typesService:TypesService
  ) { }

  ngOnInit(): void {

    let id_shop_inventory_retired=Globals.parameters.get("activities").getParam("id_shop_inventory_retired",0);
    let id_product_type_retired=Globals.parameters.get("activities").getParam("id_product_type_retired",0);
    
    if(id_product_type_retired!=0){
      this.record.user_action.id_product_type_retired=id_product_type_retired;
    }

    this.typesService.getTypes("installations").subscribe((result)=>{
      this.product_types=result;
    });
    this.shopsService.getItem(id_shop_inventory_retired).subscribe((shop)=>{
      if(shop){
        this.record.user_action.id_shop_internal_inventory=shop.id;
        this.shopname=shop.name;
      }
    });
  }

  confirm(){
    if(!this.record.user_action.id_shop_internal_inventory || this.record.user_action.id_shop_internal_inventory==0){
      alert("Specificare il magazzino di destinazione del prodotto ritirato");
      return;
    }

    if(!this.record.user_action.id_product_type_retired || this.record.user_action.id_product_type_retired==0){
      alert("Specificare la modifica di tipologia");
      return;
    }

    Globals.message.showToaster("Aggiornamento valori in corso...",ToastMode.WARNING);
    this.activitiesService.executeUserAction(this.record.user_action).subscribe((result)=>{
      
      Globals.message.showToaster("Valori aggiornati nel database",ToastMode.SUCCESS);
      let toclose=true;
      if(result){
        for(let p of this.record.user_action['products']){
          for(let r of result){
            if(!r['result'])
              toclose=false;
            if(r['sn']==p['oldproduct']){
              p['status_action']=r['result'];
              p['message']=p['status_action']==false?"Errore":"Completato";
            }
          }
        }
        
      }
      if(toclose)
        this.close();   
    });
    
  }

  close(){
    this['modalWindow'].close();
  }

  selectIDShop(){
    Globals.modal.showModal(ShopsComponent,[
      {"name":"mode","value":"modal"}
    ],(instance)=>{
      if(instance!=null){
        let shop=instance['recordSelected'];
        this.record.user_action.id_shop_internal_inventory=shop.id;
        this.shopname=shop.name;
      }
    })
  }

}
