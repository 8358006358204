<div class="row">
    <div class="col-lg-12">
      <div class="p-2">
        <div class="row mb-2">
            <div class="col-lg-3"></div>
              <div class="col-lg-6">
                  <div class="btn-group btn-block bg-white" role="group" aria-label="Visualizzazione">
                      <button type="button" class="btn btn-sm border" [class.btn-primary]="tab==1" [class.active]="tab==1" (click)="tab=1;getMonths()">Mensile</button>
                      <button type="button" class="btn btn-sm border" [class.btn-primary]="tab==2" [class.active]="tab==2" (click)="tab=2;getDocuments()">Per tipo documento</button>
                      <button type="button" class="btn btn-sm border" [class.btn-primary]="tab==3" [class.active]="tab==3" (click)="tab=3;getAnalyticsByTable()">Per modulo</button>
                  </div>
              </div>
        </div>
        <hr>

        <!-- Mensile -->
        <ng-container *ngIf="tab==1">
         <div class="card mb-0">
            <div class="row my-1">
                <div class="col-lg-4"></div>
                <div class="col-6 col-lg-1 pr-0 text-right">
                    <label>Anno</label>
                </div>
                <div class="col-6 col-lg-2">
                    <div class="input-group input-group-info input-group-sm">
                        <input class="form-control form-control-sm text-center" type="number" name="year" [(ngModel)]="selectedYear" (keyup.enter)="getMonths()">
                        <div class="input-group-append">
                            <button type="button" class="btn btn-default text-primary"  title="avvia ricerca" (click)="getMonths()">
                                <i class="fas fa-search"></i></button>
                        </div>
                    </div>
                </div>
            </div>
            <table class="table table-nowrap table-striped text-sm">
                <thead>
                    <th>Mese</th>
                    <th class="text-right">Doc. in uscita (Emessi)</th>
                    <th class="text-right">Doc. in ingresso (Ricevuti)</th>
                    <th class="text-right">Margine</th>
                    <th class="text-right">da Pagare</th>
                    <th class="text-right">da Incassare</th>
                </thead>
                <tbody>
                    <tr *ngFor="let r of list_month">
                        <td>{{r.date | date:"MMMM"}}</td>                        
                        <td class="text-right">{{r.total_out | currency:" €"}}</td>
                        <td class="text-right">{{r.total_in | currency:" €"}}</td>
                        <td class="text-right">
                            <span [class.text-danger]="(r.total_out - r.total_in)<0" class="text-success">
                                {{r.total_out - r.total_in | currency:" €"}}
                            </span>                        
                        </td>
                        <td class="text-right">{{r.total_pay | currency:" €"}}</td>
                        <td class="text-right">{{r.total_paid | currency:" €"}}</td>                        
                    </tr>
                </tbody>
                <tfoot style="border-top: 1px solid #333">
                    <tr>
                        <td><b>TOTALE</b></td>
                        <td class="text-right"><b>{{total.total_out | currency: "EUR"}}</b></td>
                        <td class="text-right"><b>{{total.total_in | currency: "EUR"}}</b></td>
                        <td class="text-right"><b>{{total.total_difference | currency: "EUR"}}</b></td>
                        <td class="text-right"><b>{{total.total_pay | currency: "EUR"}}</b></td>
                        <td class="text-right"><b>{{total.total_paid | currency: "EUR"}}</b></td>
                    </tr>
                </tfoot>
            </table>
         </div>
        </ng-container>

        <!-- per tipo documento -->
        <ng-container *ngIf="tab==2">
            <div class="row py-1">
                <div class="col-lg-2 pr-md-0" >
                    <small>
                        <app-filterdate [date_type]="98" [class]="'card p-1'" [filter_date]="filter_date" (onSelect)="getDocuments()"></app-filterdate>
                    </small>
                </div>
                <div class="col-lg-10">
                    <b>Documenti in uscita</b>
                    <div class="card">
                        <div class="card-body py-0">
                            <table class="table mb-0 no-border tabel-sm">
                                <thead class="border-bottom">
                                    <th>Sezionale</th>
                                    <th class="text-right">Importo</th>
                                    <th class="text-right">Pagato</th>
                                    <th class="text-right">Differenza</th>
                                    <th></th>
                                </thead>
                                <tbody *ngFor="let r of list['out']">
                                    <tr>
                                        <td>{{r.referenceprefix}}
                                            <small *ngIf="r.show" class="float-right">Tipo \ Totali</small>
                                            <small *ngIf="!r.show" class="float-right">(tutti i tipi)</small>
                                        </td>
                                        <td class="text-right">{{r.total | currency:" &euro;"}}</td>
                                        <td class="text-right">{{r.paid | currency:" &euro;"}}</td>
                                        <td class="text-right" [class.text-danger]="r.paid-r.total<0" [class.text-success]="r.paid-r.total>=0">{{r.paid-r.total | currency:" &euro;"}}</td>
                                        <td class="text-right">
                                            <button class="btn btn-xs btn-outline-secondary" *ngIf="!r.show" (click)="r.show=true">Dettagli<i class="fas fa-chevron-down ml-1"></i></button>
                                            <button class="btn btn-xs btn-secondary" *ngIf="r.show" (click)="r.show=false">Nascondi<i class="fas fa-chevron-up ml-1"></i></button>
                                        </td>
                                    </tr>
                                    <ng-container *ngIf="r.show">
                                        <tr *ngFor="let d of r.documents" >
                                            <td class="text-right py-1">
                                                <div *ngIf="d.typedocument==1"   title="Fattura" class="badge badge-info bg-olive">FS</div>
                                                <div *ngIf="d.typedocument==2"   title="Fattura Accompagnatoria" class="badge badge-info bg-teal">FV</div>
                                                <div *ngIf="d.typedocument==12"  title="Fattura Acconto" class="badge badge-info bg-lime" >FA</div>
                                                <div *ngIf="d.typedocument==3"   title="Nota di Credito" class="badge badge-info bg-maroon">NC</div>
                                                <div *ngIf="d.typedocument==4"   title="Preventivo" class="badge badge-warning">PR</div>
                                                <div *ngIf="d.typedocument==5"   title="Documento Di Trasporto" class="badge badge-info">DDT</div>
                                                <div *ngIf="d.typedocument==10"  title="Trasferimento" class="badge border">TR</div>
                                                <div *ngIf="d.typedocument==6"   title="Scontrino" class="badge bg-gray">SC</div>
                                                <div *ngIf="d.typedocument==7"   title="Ordine cliente" class="badge badge-secondary">OC</div>
                                                <div *ngIf="d.typedocument==8"   title="Ordine fornitore" class="badge badge-secondary">OF</div>
                                                <div *ngIf="d.typedocument==9"   title="Non fiscale" class="badge badge-secondary">NF</div>
                                                <div *ngIf="d.typedocument==11"  title="Documento amministrativo" class="badge bg-secondary">DA</div>
                                                <div *ngIf="d.typedocument==12"  title="Inventario" class="badge border">INV</div>
                                            </td>
                                            <td class="text-right py-1">
                                                <small>
                                                    <ng-container *ngIf="d.accounted">
                                                        {{d.total | currency:" &euro;" }}
                                                    </ng-container>
                                                    <ng-container *ngIf="!d.accounted">
                                                        <span class="help text-gray" title="Non conteggiato nel totale">({{d.total | currency:" &euro;" }})</span>
                                                    </ng-container>
                                                </small>
                                            </td>
                                            <td class="text-right py-1">
                                                <small>
                                                    <ng-container *ngIf="d.accounted">
                                                        {{d.paid | currency:" &euro;" }}
                                                    </ng-container>
                                                </small>
                                            </td>
                                            <td class="text-right py-1" [class.text-danger]="d.paid-d.total<0" [class.text-success]="d.paid-d.total>=0">
                                                <small>
                                                    <ng-container *ngIf="d.accounted">
                                                        {{d.paid-d.total | currency:" &euro;" }}
                                                    </ng-container>
                                                </small>
                                            </td>
                                            <td class="text-right py-1">
                                                <small *ngIf="d.accounted" class="text-gray">*({{d.total/r.total*100 | number:'1.2'}}%)</small>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <b>Documenti in ingresso</b>
                    <div class="card">
                        <div class="card-body">
                            <table class="table tabel-sm mb-0 no-border table-striped ">
                                <thead>
                                    <th>Tipo</th>
                                    <th class="text-right">Importo</th>
                                    <th class="text-right">Pagato</th>
                                    <th class="text-right">Differenza</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let r of list['in']">
                                        <td>
                                            <div *ngIf="r.typedocument==1"   title="Fattura" class="badge bg-olive">FS</div>
                                            <div *ngIf="r.typedocument==2"   title="Fattura Accompagnatoria" class="badge bg-teal" >FV</div>
                                            <div *ngIf="r.typedocument==12"  title="Fattura Acconto" class="badge bg-lime" >FA</div>
                                            <div *ngIf="r.typedocument==3"   title="Nota di Credito" class="badge bg-maroon">NC</div>
                                            <div *ngIf="r.typedocument==4"   title="Preventivo" class="badge badge-warning">PR</div>
                                            <div *ngIf="r.typedocument==5"   title="Documento Di Trasporto" class="badge badge-info">DDT</div>
                                            <div *ngIf="r.typedocument==10"  title="Trasferimento" class="badge border">TR</div>
                                            <div *ngIf="r.typedocument==6"   title="Scontrino" class="badge bg-gray">SC</div>
                                            <div *ngIf="r.typedocument==7"   title="Ordine cliente" class="badge badge-secondary">OC</div>
                                            <div *ngIf="r.typedocument==8"   title="Ordine fornitore" class="badge badge-secondary">OF</div>
                                            <div *ngIf="r.typedocument==9"   title="Non fiscale" class="badge badge-secondary">NF</div>
                                            <div *ngIf="r.typedocument==11"  title="Documento amministrativo" class="badge bg-olive">DA</div>
                                            <div *ngIf="r.typedocument==13"  title="Inventario" class="badge border">INV</div>
                                        </td>
                                        <td class="text-right">{{r.total | currency:" &euro;"}}</td>
                                        <td class="text-right">{{r.paid | currency:" &euro;"}}</td>
                                        <td class="text-right" [class.text-danger]="r.paid-r.total<0" [class.text-success]="r.paid-r.total>=0">{{r.paid-r.total | currency:" &euro;"}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- per modulo -->
        <ng-container *ngIf="tab==3">
            <div class="row py-1">
                <div class="col-lg-2 pr-md-0" >
                    <small>
                        <app-filterdate [date_type]="98" [class]="'card p-1'" [filter_date]="filter_date" (onSelect)="getAnalyticsByTable()"></app-filterdate>
                    </small>
                </div>
                <div class="col-lg-10">
                    <div class="card">
                        <div class="card-body">
                            <table class="table">
                                <thead>
                                    <th>Modulo</th>
                                    <th class="text-right">Vendita<br>(prodotti)</th>
                                    <th class="text-right">Vendita<br>(servizi)</th>
                                    <th class="text-right">Vendita<br>(non categorizzato)</th>
                                    <th class="text-right">Vendita totale</th>
                                    <th class="text-right">Costo<br>(prodotti)</th>
                                    <th class="text-right">Costo<br>(servizi)</th>
                                    <th class="text-right">Costo totale</th>
                                    <th class="text-right">Ricavi</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let r of list">
                                        <td>
                                            <div *ngIf="r.table" class="badge border" title="modulo: {{r.table}}">
                                                <b><app-selecttables [value]="r.table" [no_select]="true"></app-selecttables></b>
                                            </div>
                                        </td>
                                        <td class="text-right">{{r.amount_products | currency:"EUR"}}</td>
                                        <td class="text-right">{{r.amount_services | currency:"EUR"}}</td>
                                        <td class="text-right">{{r.amount_not_categorized | currency:"EUR"}}</td>
                                        <td class="text-right">{{totalAmount(r) | currency:"EUR"}}</td>
                                        <td class="text-right">{{r.cost_products | currency:"EUR"}}</td>
                                        <td class="text-right">{{r.cost_services | currency:"EUR"}}</td>
                                        <td class="text-right">{{totalCost(r) | currency:"EUR"}}</td>
                                        <td class="text-right">{{(totalAmount(r) - totalCost(r)) | currency:"EUR"}}</td>
                                    </tr>
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td>TOTALE</td>
                                        <td class="text-right"><b>{{total_module.total_amount_products | currency:"EUR"}}</b></td>
                                        <td class="text-right"><b>{{total_module.total_amount_services | currency:"EUR"}}</b></td>
                                        <td class="text-right"><b>{{total_module.total_amount_not_categorized | currency:"EUR"}}</b></td>
                                        <td class="text-right"><b>{{total_module.total_amount | currency:"EUR"}}</b></td>
                                        <td class="text-right"><b>{{total_module.total_cost_products | currency:"EUR"}}</b></td>
                                        <td class="text-right"><b>{{total_module.total_cost_services | currency:"EUR"}}</b></td>
                                        <td class="text-right"><b>{{total_module.total_cost | currency:"EUR"}}</b></td>
                                        <td class="text-right"><b>{{total_module.total_reveneu | currency:"EUR"}}</b></td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                    
                </div>
            </div>
        </ng-container>

    </div>
</div>