<div class="modal-header">
    <b>{{title}}</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="close(true)" >
            <i class="fas fa-times"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <ng-container *ngIf="step==0">
        <div class="alert alert-info">Caricamento in corso...</div>
    </ng-container>
    <ng-container *ngIf="!forms || forms.length==0">
        <div class="alert alert-light">
            Non ci sono moduli attivi per questo record
        </div>
    </ng-container>
    <ng-container *ngIf="step==1 && forms && forms.length>0">
        <div class="row">
            <div class="col-12">
                <label>Seleziona il modulo</label>
                <select class="form-control form-control-sm" name="id_form" [(ngModel)]="record.id_form">
                    
                    <option [value]="f.id" *ngFor="let f of forms">{{f.name}}</option>
                </select>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12 text-center">
                <button class="btn btn-success" (click)="formSelected()">Avanti</button>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="step==2">
        <div class="row">
            <div [class.col-md-8]="record.sections.length>1" [class.col-md-12]="record.sections.length==1" >
                <div class="card">
                    <ng-container *ngFor="let s of record.sections;let idx_sections=index">
                        <ng-container *ngIf="s.id==id_section">
                            <div class="card-header">
                                <div class="row">
                                    <!--<div class="col-3">
                                        <h6>{{idx_sections+1}}/{{record.sections.length}}</h6>
                                    </div>-->
                                    <div class="col-12">
                                        <h5>{{s.label}}  </h5>
                                        <h6>
                                            <span *ngIf="getCompiledField(s.id)>0" class="badge badge-danger">{{getCompiledField(s.id)}} campo obbligatorio da compilare</span>
                                            <span *ngIf="getCompiledField(s.id)==0" class="badge badge-success"><i class="fa fa-check"></i> tutti i campi obbligatori compilati</span>
                                
                                        </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <ng-container *ngFor="let f of s.fields">
                                    <ng-container *ngIf="f.condition_enable_fieldname=='' || f.condition_enable_fieldname==undefined">
                                        <ng-container *ngIf="f.access==1">
                                            <app-field [disabled]="readonly" [field]="f" [record]="record"></app-field>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="f.condition_enable_fieldname!='' && f.condition_enable_fieldname!=undefined">
                                        <ng-container *ngIf="checkEnableCondition(s.fields,f.condition_enable_fieldname)">
                                            <ng-container *ngIf="f.access==1">
                                                <app-field [disabled]="readonly" [field]="f" [record]="record"></app-field>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <div class="row mt-5 fixed-bottom-only-sm">
                                    <div class="col-12 text-center">
                                        <button  class="btn btn-secondary mr-2" *ngIf="idx_sections>0" (click)="prevSection();"><i class="fa-chevron-left"></i>&nbsp; Indietro</button>
                                        <button  class="btn btn-secondary mr-2" *ngIf="idx_sections<(record.sections.length-1)" (click)="nextSection();">Avanti &nbsp;<i class="fa-chevron-right"></i></button>
                                        <button  class="btn btn-secondary mr-2" *ngIf="idx_sections==(record.sections.length-1)" (click)="formConfirm();">Fine &nbsp;<i class="fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <!-- breadcrumbs -->
            <div class="col-md-4 d-none d-lg-block" *ngIf="record.sections.length>1">
                <ul class="list-group">
                    <ng-container *ngFor="let s of record.sections">                        
                            <li class="list-group-item" [class.bg-info]="id_section==s.id" *ngIf="s.type=='section'" (click)="id_section=s.id">
                                <span *ngIf="s.label==''" >Generale </span>
                                <span *ngIf="s.label!=''" >{{s.label}}</span>
                                <span *ngIf="getCompiledField(s.id)>0" class="badge badge-danger">{{getCompiledField(s.id)}}</span>
                                <span *ngIf="getCompiledField(s.id)==0" class="badge badge-success"><i class="fa fa-check"></i></span>
                            </li>                       
                    </ng-container>                
                </ul>
            </div>
        </div>        
    </ng-container>

    <ng-container *ngIf="step==3">
        <div class="row">
            <div class="col-12 text-center">
                <h6>Firma</h6>
                <img *ngIf="record.signature" src="{{getSignatureImage()}}" style="height: 150px;" />
                <br/>
                <button class="btn btn-warning" (click)="openSignature()">Modifica</button>                
            </div>
        </div>        
        <div class="row mt-5">
            <div class="col-12 text-center">                
            </div>
        </div>        
    </ng-container>

</div>
<div class="modal-footer" *ngIf="id_form==0 || step==3">
    <div class="col-6 text-right">
        <button *ngIf="id_form==0" class="btn btn-secondary" (click)="step=step-1;">Indietro</button>
        <button *ngIf="step==3" class="btn btn-success" (click)="formSave()">Salva modulo</button>
    </div>    
</div>



<ng-template #modalSignature>
    <div class="modal-header">
        <b>Firma Modulo</b>
    <!-- <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="close()" >
            <i class="fas fa-times"></i>
        </button>
    </div> -->
    </div>
    <div class="modal-body">
        <signature-pad #sigPad [options]="signaturePadOptions"></signature-pad>
        <button (click)="sigPad.clear()">Cancella</button>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="confirmSignature(sigPad)">OK</button>
    </div>    
</ng-template>