import { Injectable } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { WSService } from 'projects/core/src/include/service';
import { Observable } from 'rxjs';
import { MapPoi } from '../mappoi';

@Injectable({
  providedIn: 'root'
})
export class MapService extends WSService<MapPoi> {

  constructor() { super("map")}

  getPois(min_latitude,max_latitude,min_longitude,max_longitude,date_from,date_to):Observable<any>{
    return Globals.ws.requestUrl("map","getPois",[min_latitude,max_latitude,min_longitude,max_longitude,date_from,date_to]);
  }

  getLayers(all=0):Observable<any>{
    return Globals.ws.requestUrl("map","getLayers",[all.toString()]);
  }

  getTrack(date,id_vehicle):Observable<any>{
    return Globals.ws.requestUrl("map","getTrack",[date,id_vehicle]);

  }

  getVehicles():Observable<any>{
    return Globals.ws.requestUrl("map","getVehicles",[]);

  }

}
