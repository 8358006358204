<div class="row">
    <div class="col-12" style="max-height:60vh; overflow:auto">
        <form role="form" id="detailForm">
            <ul class="list-group list-group-flush" >
                <ng-container *ngFor="let f of files;let i=index">
                    <li class="list-group-item border-bottom pb-2" *ngIf="checkFileShow(f) || f.edit || user.isSuperUser()">                        
                        <ng-container *ngIf="!f['edit']">
                            <div class="row ">
                                <div class="col-9">
                                    <i  title="{{getExtension(f) | uppercase }}" class="text-gray fas fa-2x fa-file fa-file-{{getExtension(f)}}"
                                        [class.fa-file-zipper]="getExtension(f)=='zip' || getExtension(f)=='rar'"
                                        [class.fa-file-image]="getExtension(f)=='bmp' || getExtension(f)=='jpg' || getExtension(f)=='png'"
                                        [class.fa-file-video]="getExtension(f)=='mov' ||getExtension(f)=='avi' || getExtension(f)=='mp4'"
                                        [class.fa-file-audio]="getExtension(f)=='wav' ||getExtension(f)=='mp3' || getExtension(f)=='wma'"
                                        [class.fa-file-word]="getExtension(f)=='doc' || getExtension(f)=='docx'"
                                        [class.fa-file-excel]="getExtension(f)=='xls' || getExtension(f)=='xlsx'"
                                        [class.fa-file-powerpoint]="getExtension(f)=='ppt' || getExtension(f)=='pptx'"
                                        [class.fa-file-code]="getExtension(f)=='xml'"
                                        [class.fa-file-signature]="getExtension(f)=='p7m'">
                                    </i>
                                    <span title="{{f.name}}" class="text-truncate d-inline-block ml-3" style="width:90%" >{{f.name}} 
                                        <div class="badge badge-warning" *ngIf="f.permissions=='1'">Solo <b>{{f.role}}</b></div>
                                        <div class="badge badge-warning" *ngIf="f.permissions=='2'">Solo <b>{{f.username}}</b></div>                                        
                                    </span>
                                </div>
                                <div class="col-3 text-right">
                                    <a class="btn btn-info btn-sm ml-1" title="Visualizza" (click)="downloadFile(f)"><i class="fa fa-eye"></i></a>
                                    <a class="btn btn-secondary btn-sm ml-1" title="Rinomina" (click)="f.edit=!f.edit"><i class="fa fa-pencil-alt"></i></a>
                                    <a class="btn btn-danger btn-sm ml-1"  title="Elimina" (click)="removeFile(f)"><i class="fa fa-trash"></i></a>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container  *ngIf="f['edit']">
                            <div class="input-group input-group-sm">
                                <input type="text" class="form-control form-control-sm" autofocus  name="name" [(ngModel)]="f.name"/>
                                <div class="input-group-append">
                                    <select class="form-control form-control-sm" name="{{i}}_permissions" [(ngModel)]="f.permissions">
                                        <option value="0">Visibile a tutti</option>
                                        <option value="1">Visibile solo agli utenti con lo stesso ruolo</option>
                                        <option value="2">Visibile solo all'utente che lo ha caricato</option>
                                    </select>
                                </div>                                        
                                <div class="input-group-append">
                                    <span class="btn btn-sm btn-default" (click)="f['edit']=false;confirm(f);" title="Conferma"><i class="fas fa-check text-success"></i></span>
                                    <span class="btn btn-sm btn-default" (click)="f['edit']=false;" title="Annulla"><i class="fas fa-times text-danger"></i></span>
                                </div>
                            </div>
                            <span title="{{config.serverUrl}}{{f.file}}" class="text-xs text-truncate d-inline-block" style="width:90%">
                                Caricato da: <b>{{f.username}}</b> il {{f.created| date:'d MMMM yyyy'}}&ensp;|&ensp;Percorso file: <b>{{config.serverUrl}}{{f.file}}</b>
                            </span>
                        </ng-container>
                    </li>
                </ng-container>
            </ul>
        </form>
    </div>
    <div class="col-12 text-center mt-1">
        <app-uploadfile [directory]="directory" (fileuploaded)="this.files.push($event);"></app-uploadfile>
    </div>
</div>