<div class="card m-0">
    <div class="row p-1">
        <div class="col-lg-3">
            <div class="input-group input-group-sm" >
                <input type="text" name="table_search" class="form-control  form-control-sm" placeholder="Cerca..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                <div class="input-group-append input-group-append-sm">
                    <button type="submit" class="btn btn-default text-primary"  title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                    <button class="btn btn-default text-danger"   title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="input-group input-group-sm mr-1">
                <div class="input-group-prepend" >
                    <span class="input-group-text text-right">Da</span>
                </div>
                <input class="form-control form-control-sm" type="date"  name="date_from" [(ngModel)]="filter_date.value" (ngModelChange)="getItems()">
            </div>
        </div>
        <div class="col-lg-3">
            <div class="input-group input-group-sm" >
                <div class="input-group-prepend ">
                    <span class="input-group-text text-right">A</span>
                </div>
                <input class="form-control form-control-sm" type="date"  name="date_to" [(ngModel)]="filter_date.value2" (ngModelChange)="getItems()">
                
            </div>
        </div>       
        <!-- </div>
        <div class="row d-block mt-2 border-top">
        <p class="card-title text-secondary"><i class="fas fa-list mr-2"></i><b>Risultati</b>
            (<span class="text-info font-weight-bold">{{recordcount}}</span>
            <span class="d-none d-sm-inline-block">&thinsp;totali, <span class="text-info font-weight-bold">{{form.value.id.length}}</span> selezionati</span>)
        </p>             -->
        <div class="col-lg-3 py-1" *ngIf="mode!='modal'" >
            <button title="Elimina" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1"><i class="fas fa-trash"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
            <button title="Esporta" (click)="export()" [disabled]="form.value.id.length == 0" class="btn btn-warning btn-xs float-right ml-1"><i class="fas fa-print"></i><span class="d-none d-sm-inline-block">&ensp;</span></button>
        </div>
    </div>
    <form>
        <table class="table table-sm table-head-fixed table-hover text-nowrap m-0" >
            <thead>
                <tr>
                    <th>
                        <div class="icheck-primary d-inline mr-3">
                            <input  type="checkbox" title="Seleziona tutti"
                            (change)="onChangeAll($event.target.checked)" />
                        </div>
                        Data
                        <button class="btn btn-tool" (click)="switchOrdering('n.date')">
                            <i class="fas fa-sort {{classOrdering('n.date')}}" ></i>
                        </button>
                    </th>
                    <!-- <th>
                        Tipologia
                        <button class="btn btn-tool" (click)="switchOrdering('a.id_type')">
                            <i class="fas fa-sort {{classOrdering('a.id_type')}}"></i>
                        </button>
                    </th> -->
                    <th class="d-none d-sm-table-cell">
                        Messaggio
                    </th>
                    <th>Gateway
                        <button class="btn btn-tool" (click)="switchOrdering('n.gateway')">
                            <i class="fas fa-sort {{classOrdering('n.gateway')}}"></i>
                        </button>
                    </th>
                    <th>Utente</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>

            <!-- elemento ripetuto -->
            <tr *ngFor="let record of list">
                <td>
                    <div class="icheck-primary d-inline mr-3">
                        <input type="checkbox" id="checkrecord"
                        (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll"
                        />
                    </div>
                    <b>{{ record.date | date:'dd-MM-yyyy'}}</b>
                </td>
                <td class="d-none d-sm-table-cell">
                    
                    <span [innerHTML]="record.preview"></span><br/>
                </td>
                <td >{{record.gateway}}</td>
                <td>{{record.username}}</td>
                <td>
                    <button class="btn btn-info btn-xs float-right m-1" data-toggle="modal" data-target="#modalNotify" (click)="setNotify(record)"><i class="fas fa-eye"></i>&ensp;</button>
                </td>
            </tr>

            </tbody>
        </table>
    </form>
</div>


<div class="modal fade" id="modalNotify" tabindex="-1" role="dialog" >
    <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content ">
            <div class="modal-header">
                <b><i class="far fa-paper-plane mr-2"></i>Notifica</b>
                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-dismiss="modal" >
                        <i class="fas fa-times"></i>
                    </button>
                </div>
            </div>
            <div class="modal-body" *ngIf="selectedNotify">
                <div class="row">
                    <div class="col-12">
                        <label>Inviato a: {{contact}}</label><br/>
                        <div *ngIf="selectedNotify.gateway=='email'">
                            <label>Da: {{email_from}}</label><br/>
                            <label>Oggetto: {{email_subject}}</label><br/>
                        </div>
                        <p [innerHTML]="body"></p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"  >Chiudi</button>
            </div>
        </div>
    </div>
</div>