import { Injectable } from '@angular/core';
import { Observable, Observer, of } from 'rxjs';
import { Globals } from 'projects/core/src/globals';
import { ProductCategories } from '../db/productcategories'
import { WSService } from 'projects/core/src/include/service'
//import { TreeviewItem } from 'ngx-treeview';

@Injectable({
  providedIn: 'root'
})

export class ProductcategoriesService extends WSService<ProductCategories> {

  public static categories=[];
  constructor() { super("productcategories") }

  getCategories():Observable<ProductCategories[]>{

    if(ProductcategoriesService.categories.length==0){
      Globals.ws.requestUrl<ProductCategories[]>("productcategories","getItems",["pc.status=1"]).subscribe((items:any)=>{
        ProductcategoriesService.categories=items;
      });
      return Globals.ws.requestUrl<ProductCategories[]>("productcategories","getItems",["pc.status=1"]);

    }else{

      function sequenceSubscriber(observer: Observer<any[]>) {
        observer.next(ProductcategoriesService.categories);
        observer.complete();
        return {unsubscribe() {}};
      }
     
        return new Observable(sequenceSubscriber);
    }

    return  Globals.ws.requestUrl<ProductCategories[]>("productcategories","getItems",["pc.status=1"]);
    
  }

  getTree():Observable<any[]>{
    return  Globals.ws.requestUrl<any[]>("productcategories","getTree",[]);
   
  }

}