import { Component, OnInit, Input,ViewChild,TemplateRef, OnChanges } from '@angular/core';
import { ModelList } from 'projects/core/src/include/modellist'
import { UntypedFormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { Type } from '../../../db/type';
import { Filter,FilterMode, User } from 'projects/core/src/include/structures'

import { InventoriesDetailsService } from '../../../services/inventoriesdetails.service';
import { Inventories,InventoriesDetails, InventoriesTotal,InventoryMode,SNAvaible } from '../../../db/inventories';

import {ProductsService} from '../../../services/products.service';
import {NgbModal, } from '@ng-bootstrap/ng-bootstrap';
import { Product } from '../../../db/product';
import { ListinventoriesComponent } from './../listinventories/listinventories.component';
import { ShowserialsComponent } from '../showserials/showserials.component';
import { Globals } from 'projects/core/src/globals';
import { InventoriesDetailsComponent } from '../inventories-details/inventories-details.component';
import { InventoryoutComponent } from '../inventoryout/inventoryout.component';
import { ProductcategoriesService } from '../../../services/productcategories.service';
import { ProductCategories } from '../../../db/productcategories';
import { InventoriesModule } from '../inventories.module';
import { SalesComponent } from '../sales/sales.component';
import { LoadComponent } from '../load/load.component';
import { DocumentMode } from '../../../db/documents';
import { DocumentsDetailsComponent } from '../../documents/documents-details/documents-details.component';
import { TypesService } from '../../../services/types.service';
import { Brand } from '../../../db/brand';
import { ProductBrandsService } from '../../../services/productbrands.service';
import { InventoriesService } from '../../../services/inventories.service';



@Component({
  selector: 'app-logstock',
  templateUrl: './logstock.component.html',
  styleUrls: ['./logstock.component.sass']
})
export class LogstockComponent extends ModelList<Product> implements OnInit,OnChanges {
  


  @Input()
  id_product:number=0;

  @Input()
  sn:string="";

  get user(): User {
      return Globals.user;
  }
  
  
  inventories:Inventories[];
  status_inventories:Type[];
  filter_date_stock:Filter=new Filter();
  filter_stock:Filter=new Filter();
  filter_stockable:Filter=new Filter();
  filter_search:Filter=new Filter();
  filter_id_inventory:Filter=new Filter();
  filter_status:Filter=new Filter();
  filter_id_product:Filter=new Filter();
  filter_sn:Filter=new Filter();
  filter_id_type:Filter=new Filter();
  filter_id_category:Filter=new Filter();
  filter_id_brand:Filter=new Filter();


  total_amount:number=0;

  total:InventoriesTotal;
  categories:ProductCategories[]=[];
  brands:Brand[]=[];

  types:Type[];

  isFilterSet:boolean=false;

  today:Date=new Date();
  current_year=this.today.getFullYear();

  constructor( private fb1: UntypedFormBuilder,
    private InventoriesDetailsService: InventoriesDetailsService,
    private productsService: ProductsService,
    private productBrandsService: ProductBrandsService,
    private productcategoriesService:ProductcategoriesService,
    private typesService: TypesService,
    private inventoriesService:InventoriesService

    
  ) {
    super(productsService,fb1);
    this.sectionname="logstock";
    this.modulename="inventories";
    this.detailViewModal=InventoriesDetailsComponent;
   }


   ngOnInit(): void {
    this.title = 'Magazzini';
    super.ngOnInit.apply(this, arguments); 
    this.paging_count=25;
    this.inventoriesService.getInventories().subscribe((items)=>{
      this.inventories=items;
    })

    this.typesService.getTypes("products").subscribe((items)=>{
      this.types=items;
    });

    this.productBrandsService.getBrands(0).subscribe((items)=>{
      this.brands=items;
    });

    
   
    if(this.mode!="embedded") //se incoroporato in una finestra non avviare subito la ricerca, ma aspetta che il filtro sia attivo
      this.updateList();

   InventoriesModule.savingInventoriesFastEvent.subscribe(()=>{
    this.getItems();
   });
    

    this.productcategoriesService.getCategories().subscribe((items)=>{
      this.categories=items;
    });

  }

  setFilters():void{
    this.filter_stockable.mode=FilterMode.normal;
    this.filter_stockable.fields=[];
    this.filter_stockable.fields.push("pm.stockable");
    this.filter_stockable.value="1";
    this.filter.push(this.filter_stockable);
    
    this.filter_id_inventory.mode=FilterMode.normal;
    this.filter_id_inventory.fields=[];
    this.filter_id_inventory.fields.push("ps.id_inventory");
    this.filter_id_inventory.value="";
    this.filter.push(this.filter_id_inventory);

    this.filter_id_category.mode=FilterMode.contain;
    this.filter_id_category.fields=[];
    this.filter_id_category.fields.push("pc.id");
    this.filter_id_category.value="";
    this.filter.push(this.filter_id_category);

    if(this.id_product){
      this.filter_id_product.mode=FilterMode.normal;
      this.filter_id_product.fields=[];
      this.filter_id_product.fields.push("pm.id");
      
      this.filter_id_product.value=this.id_product.toString();
      this.filter.push(this.filter_id_product);
    }

    if(this.sn!=""){
      this.filter_sn.mode=FilterMode.normal;
      this.filter_sn.fields=[];
      this.filter_sn.fields.push("pm.sn");
      this.filter_sn.value=this.sn;
      this.filter.push(this.filter_sn);
    }

    this.filter_stock.mode=FilterMode.disabled;
    this.filter_stock.fields=[];
    this.filter_stock.fields.push("ps.stock");
    this.filter_stock.value="0";
    this.filter.push(this.filter_stock);

    this.filter_id_type.mode=FilterMode.normal;
    this.filter_id_type.fields=[];
    this.filter_id_type.fields.push("pm.id_type");
    this.filter_id_type.value="";
    this.filter.push(this.filter_id_type);

    this.filter_id_brand.mode=FilterMode.normal;
    this.filter_id_brand.fields=[];
    this.filter_id_brand.fields.push("pm.id_brand");
    this.filter_id_brand.value="";
    this.filter.push(this.filter_id_brand);

    this.filter_date_stock.mode=FilterMode.disabled;
    this.filter_date_stock.fields=[];
    this.filter_date_stock.fields.push("ps.date");
    this.filter_date_stock.value="";
    this.filter.push(this.filter_date_stock);

    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("pm.name");
    this.filter_search.fields.push("pb.name"); //brand
    this.filter_search.fields.push("pm.codeinternal");
    this.filter_search.fields.push("pm.codeexternal");
    
    this.filter_search.fields.push("pm.barcode");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("pm.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    this.ordering.field="pm.name";

    if(this.mode!="modal" && this.mode!="embedded")
      Globals.navigation.getStateFilters(this.constructor.name,this.filter);
      
    this.afterGetItems=(()=>{
      this.InventoriesDetailsService.getTotals(this.filter,this.orderingList,this.advancedFilter).subscribe((item)=>{         
        this.total=item;
      });    
    })
    this.isFilterSet=true;
       
  }


  ngOnChanges():void{
    if(this.id_product>0 || this.sn!="") //avvia la ricerca quando il filtro è attivo
      this.updateList();
  }


  updateList():void{
    if(!this.isFilterSet)
        this.setFilters();

    this.total_amount=0;
    
    if(this.id_product>0){
      this.filter_id_product.value=this.id_product.toString();
    }

    this.getItems();
    
  }


  openInventoryManager(){
    Globals.modal.showModal(ListinventoriesComponent,[],()=>{
      this.inventoriesService.getInventories().subscribe((items)=>{
        this.inventories=items;
      })
    });
  }


  move(record){
    //prendi i dettagli del prodotto
    this.InventoriesDetailsService.move(record['id'],()=>{
      this.getItems();
    });
  }


  load(){
    Globals.modal.showModal(DocumentsDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id_type","value":2}
      
    ],()=>{
      this.getItems();
    });    
  }


  unload(){
    Globals.modal.showModal(SalesComponent,[],()=>{
      this.getItems();
    },'xl');    
  }


  resetStock(r){
    Globals.modal.showConfirm("","Confermi di voler azzerare la giacenza di magazzino di questo prodotto?",()=>{  
      this.inventoriesService.resetStock(r.id,"","").subscribe(
        ()=>{
          this.getItems();
        }
      );
    },"Sì. Azzera","No. Annulla","danger");
  }

}
