<app-window [title]="title">
    
    <div class="row">
        <div class="col-lg-4">
            <div class="form-group form-group-sm">
                <label>Nome</label>
                <input class="form-control form-control-sm" type="text" name="name" [(ngModel)]="record.name"/>
            </div>
        </div>       
        <div class="col-lg-2">
            <div class="form-group form-group-sm">
                <label>Stato</label>
                <select name="status" class="form-control form-control-sm" name="status" [class.text-danger]="record.status==0" [(ngModel)]="record.status">
                    <option value="0">Non pubblicato</option>
                    <option value="1">Pubblicato</option>
                </select>
            </div>
        </div>
        <div class="col-lg-2">
            <div class="form-group form-group-sm">
                <label>Tabella</label>
                <app-selecttables [name]="'table'" [value]="record.table" [show_all]="false" (onchange)="record.table=$event;getSections();"></app-selecttables>
            </div>
        </div>
        <div class="col-lg-2" *ngIf="sections && sections.length>0">
            <div class="form-group form-group-sm">
                <label>Sezione</label>
                <select name="section" class="form-control form-control-sm" [(ngModel)]="record.section">
                    <option value=""></option>
                    <option *ngFor="let s of sections" value="{{s}}">{{s}}</option>                                
                </select>
            </div>
        </div>

        
        <div class="col-lg-2">
            <div class="form-group form-group-sm" *ngIf="user.role=='superuser'">
                <label>Sorgente</label>
                <div class="input-group input-group-sm">
                    <select [class.bg-warning]="record.source=='remote'" class="form-control form-control-sm" [disabled]="record.id>0"  name="source" [(ngModel)]="record.source">
                        <option value="remote">Generico</option>
                        <option value="local">Personalizzato</option>
                    </select>
                    <div class="input-group-append input-group-append-sm">
                        <button type="button" data-dismiss="modal" *ngIf="record.source=='local'" class="btn btn-warning btn-sm" (click)="transferToRemote()"><i class="fas fa-arrow-circle-right mr-1"></i><small>Generico</small></button>
                        <button type="button" data-dismiss="modal" *ngIf="record.source=='remote'" class="btn border btn-sm" (click)="transferToLocal()"><i class="fas fa-arrow-circle-right mr-1"></i><small>Personalizzato</small></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12">
            <div class="form-group form-group-sm">
                <label>Descrizione</label>
                <input class="form-control form-control-sm" type="text" name="description" [(ngModel)]="record.description"/>
            </div>
        </div>
    </div>    
    <div class="row">
        <div class="col-lg-5">
            <div class="form-group form-group-sm">
                <label>Nome file <small>(se vuoto verrà generato un nome random)</small></label>
                <input class="form-control form-control-sm" type="text" name="filename" [(ngModel)]="record.filename"/>
            </div>
        </div>
        <div class="col-lg-1">
            <div class="form-group form-group-sm">
                <label>Formato</label>
                <select class="form-control form-control-sm" name="format" [(ngModel)]="record.format">
                    <option value="pdf">PDF</option>
                    <option value="csv">CSV</option>
                    <option value="htm">HTML</option>
                    <option value="xml">XML</option>
                </select>
            </div>
        </div>

        <div class="col-lg-1">
            <div class="form-group form-group-sm">
                <label>Versione</label>
                <select class="form-control form-control-sm" name="version" [(ngModel)]="record.version">
                    <option value="1">V1</option>
                    <option value="2">V2 (xslt)</option>
                </select>
            </div>
        </div>
        <div class="col-lg-3">
            <div class="form-group form-group-sm">
                <label>Origine dei record</label>
                <select class="form-control form-control-sm" name="detail" [(ngModel)]="record.detail"  title="{{record.detail}} {{record.inrow}} ">
                    <option [value]="0">Utilizza i record presenti nella lista</option>
                    <option [value]="1">Richiede la selezione di uno o più record</option>
                </select>
            </div>
        </div>        
        <div class="col-lg-2" *ngIf="record.detail">
            <div class="form-group form-group-sm">
                <label>Pulsante rapido <i *ngIf="record.status==0" title="Se vuoi che anche nel pulsante il modello non sia visibile, imposta Pulsante rapido su No." class="icon fas fa-info-circle text-info"></i></label>
                <select class="form-control form-control-sm" name="inrow" [(ngModel)]="record.inrow"  title="Scegli se inserire fra i pulsanti nella riga della tabella">
                    <option value="0">No</option>
                    <option value="1">Si</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row mb-2" *ngIf="record.detail && record.inrow">    
        <div class="col-lg-12" >
            <div class="form-group form-group-sm" >
                <label>Condizioni per la visualizzazione nel pulsante <small>(opzionale)</small></label>
                <input type="text" class="form-control form-control-sm" name="condition" [(ngModel)]="record.condition">
            </div>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-12">
            <label>Parametri da richiedere all'utente <small>(opzionale)</small></label>
            <table class="table table-sm table-head-fixed text-nowrap m-0" >
                <thead>
                    <th>Nome</th>
                    <th>Etichetta</th>
                    <th>Tipologia</th>
                    <th>Valore di default</th>
                    <th><a type="button" class="btn btn-xs btn-success float-right"  title="Aggiungi" (click)="addRequestParam()"><i class="fa fa-plus"></i></a></th>
                </thead>
                <tbody>
                    <tr class="form-group" *ngFor="let o of record.requestparamsArray">
                        <td><input class="form-control form-control-sm" type="text" [(ngModel)]="o.name"/></td>
                        <td><input class="form-control form-control-sm" type="text" [(ngModel)]="o.label"/></td>
                        <td>
                            <select class="form-control form-control-sm" [(ngModel)]="o.type">
                                <option value="text">Casella di testo</option>
                                <option value="select">Menu a tendina</option>
                            </select>
                        </td>
                        <td>
                            <input class="form-control form-control-sm" type="text" [(ngModel)]="o.default"/>
                        </td>
                        <td>
                            <a type="button" class="btn btn-xs btn-danger float-right"  title="Elimina" (click)="removeRequestParam(o)"><i class="fa fa-trash"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-12">
            <label>Sorgente dei dati</label>
            <table class="table table-sm table-head-fixed text-nowrap m-0" >
                <thead>
                    <th></th>
                    <th>Identificativo</th>
                    <th>Tabella</th>
                    <th>Funzione</th>
                    <th>Argomenti</th>
                    <th><a type="button" class="btn btn-xs btn-success float-right" title="Aggiungi" (click)="addOrigin()"><i class="fa fa-plus"></i></a></th>
                </thead>
                <tbody>
                    <tr class="form-group" *ngFor="let o of record.originsArray">
                        <td>
                            <a type="button" class="btn btn-xs btn-secondary" title="Esempio di struttura dati" (click)="getExample(o)"><i class="fas fa-database mr-1"></i>Struttura</a>
                        </td>
                        <td><input class="form-control form-control-sm" type="text" [(ngModel)]="o.id"/></td>
                        <td>
                            <app-selecttables [name]="'table'" [value]="o.table" [show_all]="false" (onchange)="o.table=$event;"></app-selecttables></td>
                        <td>
                            <div class="input-group input-group-sm">
                                <input class="form-control form-control-sm" type="text" [(ngModel)]="o.task"/>
                                <div class="input-group-append">
                                    <button class="btn btn-default" (click)="openModalMethods(o.table,o)"><i class="fas fa-ellipsis-h"></i></button>
                                </div>
                            </div>                                      
                        </td>
                        <td><input class="form-control form-control-sm" type="text" [(ngModel)]="o.args"/></td>
                        <td>
                            <a type="button" class="btn btn-xs btn-danger float-right"  title="Elimina" (click)="removeOrigin(o)"><i class="fa fa-trash"></i></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-2 pr-lg-0 example_container" *ngIf="example!='0'">
            <label>Dati di esempio</label>
            <ngx-json-viewer [json]="example" ></ngx-json-viewer>
        </div>
        <div class="col-lg-4" [class.col-lg-10]="example!='0'">
            <label>Codice HTML</label>
            <ckeditor *ngIf="editorOn && record.format=='html'" [config]="{allowedContent:true}" tagName="textarea" [editor]="Editor" [(ngModel)]="record.body"></ckeditor>
            <!--<textarea *ngIf="!editorOn || record.format!='html'" rows="28" class="form-control bg-light" name="body"  [(ngModel)]="record.body" (keyup)="updatePreview()"></textarea>
        -->
            <ace style="height:300px"  [config]="configEditor" [mode]="'text'" [theme]="'github'" [(value)]="record.body"></ace>
            
            <a type="button" class="btn btn-xs btn-secondary m-1" *ngIf="example!='0'" (click)="example=0"><i class="fas fa-chevron-left mr-1"></i>Nascondi</a>
            <a type="button" class="btn btn-xs btn-warning m-1" (click)="getPreview();"><i class="fas fa-eye mr-1"></i>Anteprima di stampa</a>
            <a type="button" class="btn btn-xs btn-info m-1 float-right" *ngIf="example=='0'" (click)="updatePreview()"><i class="fas fa-sync mr-1"></i>Aggiorna</a>
        </div>
        <div class="col-lg-8 pl-lg-0" *ngIf="example=='0'">
            <label>Visualizzazione Mista</label>
            <iframe class="border w-100" style="height: calc(100% - 2rem)" #preview></iframe>
        </div>
    </div>
    <app-toolbarmodel [model]="this"></app-toolbarmodel>
</app-window>


<ng-template #modalMethods>
    <div class="modal-header">
        <b>Funzioni disponibili</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModalMethods()" >
              <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <table class="table">
            <thead>
                <th>Nome</th>
                <th>Descrizione</th>
                <th></th>
            </thead>
            <tbody>
                <tr *ngFor="let m of methods">
                    <td><b>{{m['name']}}</b></td>
                    <td [innerHTML]="m['comment']"></td>
                    <td class="text-right">
                        <button class="btn btn-secondary btn-sm" (click)="selectMethod(m)">Seleziona <i class="fa fa-chevron-right"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="closeModalMethods()">Chiudi</button>
    </div>
</ng-template>