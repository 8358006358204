import { Injectable } from "@angular/core";
import { Globals } from "../globals";

@Injectable()
export class ModulePermissions{

    list:any=[];




    addPermissionRule(modulename,permissionname,description){
        
        let permission_found=false;
        //cerca se nella lista è presente il modulo
        for(let i=0;i<this.list.length;i++){
            if(this.list[i]['modulename']==modulename){


                if(this.list[i]['permissions']){
                    //cerca nel modulo se il permesso non è stato inserito
                    for(let j=0;j<this.list[i]['permissions'].length;j++){
                        if(this.list[i]['permissions'][j]['name']==permissionname){
                            permission_found=true;
                        }
                    }
                }else{
                    this.list[i]['permissions']=[];
                }

                if(!permission_found){
                    let p={"name":permissionname,"description":description}
                    this.list[i]['permissions'].push(p);
                }

                return;

            }

        }

        let m={"modulename":modulename,"permissions":[
            {"name":permissionname,"description":description}
        ]};
        this.list.push(m);

    }



    checkPermission(modulename,permissionname){
        if(!Globals.user.permissions)
            return true;

        if(typeof(Globals.user.permissions)=="string")
            Globals.user.permissions=JSON.parse(Globals.user.permissions);

        //verifica se è presente il permesso
        for(let m of Globals.user.permissions){
            if(m.modulename.replace("-","")==modulename.replace("-","")){
                for(let p of m.permissions){
                    if(p.name==permissionname){
                        return p.value;
                    }
                }
            }
        }

        return true;
    }

    checkModule(modulename){
        if(modulename!="" && modulename!=undefined){
            for(let m of Globals.parameters.modules){
                if(m.modulename==modulename){
                    if(m.permissions.indexOf(Globals.user.role)>-1 && m.status==1){
                        return true;
                    }else{
                        return false;
                    }
                }
            }
        }
        return true;
    }


}