<div class="modal-header" *ngIf="record.product">
    <span><b>{{record.product.brand}}</b> {{record.product.name}} ({{record.sn}})</span>
</div>

<div class="modal-body">    
    <form role="form" id="detailForm" class="mt-1">
            <!-- TAB GENERAL -->
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item ">
                <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#tabInstallation2" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-clipboard"></i><span class="d-none d-sm-inline-block ml-2">Generali</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabInstallation3" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fab fa-diaspora"></i><span class="d-none d-sm-inline-block ml-2">Caratteristiche</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabInstallation5" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-sticky-note"></i><span class="d-none d-sm-inline-block ml-2">Note<span *ngIf="record.notes && record.notes.length>0" class="badge badge-warning ml-1">{{record.notes.length}}</span></span></a>
            </li>
            
            <li class="nav-item" *ngIf="isModuleEnabled('inventories')">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabInstallation8" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-boxes"></i><span class="d-none d-sm-inline-block ml-2">Magazzino</span></a>
            </li>

            <li class="nav-item" *ngIf="record.id_product>0 && record.product && record.product.id_category==id_category_log && record.sn!=''" >
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabInstallation7" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-tachometer-alt"></i><span class="d-none d-sm-inline-block ml-2">Monitoraggio chilometri</span></a>
            </li>

            <li class="nav-item" *ngIf="record.id>0 && isModuleEnabled('forms')" >
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabInstallation9" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fab fa-wpforms"></i><span class="d-none d-sm-inline-block ml-2">Moduli</span></a>
            </li>
        </ul>
        <div class="pt-2" >
            <div class="tab-content" id="myTabContent">
                <!-- GENERALE -->
                <div class="tab-pane fade show active" id="tabInstallation2" role="tabpanel" aria-labelledby="tab2-tab">
                    <div class="row">
                        <div [class.col-lg-8]="isModuleEnabled('deadlines')"  [class.col-lg-12]="!isModuleEnabled('deadlines')">
                            <div class="form-group row">
                                <div class="col-lg-2"><label>Prodotto</label></div>
                                <div class="col-lg-10">
                                    <div class="input-group input-group-sm">
                                        <input *ngIf="record.product" name="description" type="text" readonly class="form-control form-control-sm" value="{{record.product.brand}} {{record.product.name}}" />                            
                                        <div class="input-group-append">
                                            <button class="btn btn-default" (click)="selectProduct()">
                                                <i class="fa-list-alt far mr-1"></i>Scegli...
                                            </button>
                                        </div>
                                    </div>                           
                                </div>
                            </div>
                            <div class="form-group row">                                
                                <div class="col-lg-2"><label>Numero seriale</label></div>
                                <div class="col-lg-4">
                                    <input type="text" class="form-control form-control-sm" placeholder="..." name="sn" [(ngModel)]="record.sn" (change)="checkSN();">
                                    <div class="badge badge-warning" *ngIf="sn_exist">Numero seriale già presente nel database</div>
                                </div>
                                <div class="col-lg-2 text-lg-right"><label>Matricola telematica</label></div>
                                <div class="col-lg-4">
                                    <input type="text" class="form-control form-control-sm" placeholder="..." name="matricola" [(ngModel)]="record.matricola">
                                </div>
                            </div>
                            <div class="form-group row">                                
                                <div class="col-lg-2"><label>Utilizzo</label></div>
                                <div class="col-lg-4">
                                    <select class="form-control form-control-sm" [(ngModel)]="record.id_type" name="id_type" #id_type="ngModel" required>
                                        <option *ngFor="let t of type_installation" [ngValue]="t.id">{{t.name}}</option>
                                    </select>
                                </div>                            
                                <!--
                                <div class="col-2"><label>Stato</label></div>
                                <div class="col-4">
                                    <select class="form-control form-control-sm" [(ngModel)]="record.status" name="status" #id_type="ngModel" required>
                                        <option value="1">Attivi</option>
                                        <option value="2">Cestinati</option>
                                        <option value="3">Cessati</option>
                                        <option value="4">Persi</option>
                                        <option value="5">Chiusi</option>
                                    </select>
                                </div>
                                -->
                            </div>
                            <div class="form-group row">
                                <div class="col-lg-2"><label>Codice (QR Code)</label></div>
                                <div class="col-lg-10">
                                    <div class="input-group input-group-sm">
                                        <input type="text" class="form-control form-control-sm" placeholder="..." name="code" [(ngModel)]="record.code">
                                        <div class="input-group-append">
                                            <button class="btn btn-default" (click)="openQrCode()"><i class="fa fa-eye mr-1"></i>Vedi</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row" *ngIf="isModuleEnabled('shops')">
                                <div class="col-lg-2"><label>Ubicazione attuale</label></div>
                                <div class="col-lg-10">
                                    <div class="input-group input-group-sm">
                                        <p class="form-control form-control-sm">
                                            <span *ngIf="record.shop">
                                                {{record.shop.name}}
                                                <span class="badge badge-danger" *ngIf="record.shop.status==3">Cessato</span>
                                                <span class="badge badge-danger" *ngIf="record.shop.status==4">Perso</span>
                                                <span class="badge badge-danger" *ngIf="record.shop.status==5">Chiuso</span>
                                            </span>
                                        </p>
                                        <div class="input-group-append ">
                                            <button class="btn btn-default" (click)="selectShop()">
                                                <i class="fas fa-store-alt mr-1"></i>
                                                <span class="d-none d-sm-inline-block">
                                                    <ng-container *ngIf="record.id==0">
                                                        Scegli sede
                                                    </ng-container>
                                                    <ng-container *ngIf="record.id>0">
                                                        Sposta sede
                                                    </ng-container>
                                                </span>
                                                
                                            </button>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div class="form-group row" *ngIf="isModuleEnabled('address')">
                                <div class="col-lg-2"><label>Rivenditore</label></div>
                                <div class="col-lg-10">
                                    <div class="input-group input-group-sm">
                                        <p class="form-control form-control-sm"><span *ngIf="record.reseller" >{{record.reseller.name}}</span></p>
                                        <div class="input-group-append ">
                                            <button class="btn btn-default" (click)="selectReseller()">
                                                <i class="fas fa-users mr-1"></i><span class="d-none d-sm-inline-block">Scegli...</span>
                                            </button>
                                            <button class="btn btn-default" *ngIf="record.id_reseller>0" (click)="record.id_reseller=0;record.reseller=null;"><i class="fa fa-times"></i></button>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4" *ngIf="isModuleEnabled('deadlines')">
                            <ng-container *ngFor="let cf of customfields_frontend">
                                <div class="card" *ngIf="cf.value" >
                                    <div class="card-header">
                                        {{cf.label}}
                                    </div>
                                    <div class="card-body text-center">
                                       
                                        <ng-container [ngSwitch]="cf.type">
                                            <h5 *ngSwitchCase="'date'">{{cf.value | date:"d/M/Y"}}</h5>
                                            <h5 *ngSwitchDefault>{{cf.value}}</h5>
                                        </ng-container>
                                        
                                    </div>
                                </div>
                            </ng-container>
                            <!--
                            <div class="card">
                                <div class="card-header">
                                    Prossima scadenza
                                </div>
                                <div class="card-body text-center">
                                    <ng-container *ngIf="record.nextDeadline && record.nextDeadline.id>0">
                                        {{record.nextDeadline.type}}<br/>
                                        <h5>{{record.nextDeadline.date | date:"dd/MM/YYYY"}}</h5>
                                        <button class="btn btn-info btn-sm btn-block mt-3" (click)="openDeadline()">Apri scadenza</button>
                                    </ng-container>
                                    <ng-container *ngIf="!record.nextDeadline || record.nextDeadline.id==undefined">
                                        <div class="p-3"><i class="fas fa-info-circle mr-2"></i><i>Nessuna scadenza pianificata!</i></div>
                                        <button class="btn btn-success btn-sm btn-block"  (click)="addDeadline()"><i class="fas fa-plus mr-2"></i>Aggiungi scadenza</button>
                                    </ng-container>
                                </div>
                            </div>-->
                            <div class="card">
                                <div class="card-header">
                                    Contratti
                                </div>
                                <div class="card-body text-center">
                                    <ng-container *ngIf="record.contracts && record.contracts.length>0">
                                        <ng-container *ngFor="let c of record.contracts">
                                            contratto di {{c.type}} {{c.description}} 
                                            <h5>scadenza {{c.end | date:"dd-MM-Y"}}</h5>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!record.contracts || record.contracts.length==0">
                                        <div class="p-3"><i class="icon fas fa-info-circle mr-2"></i><i>Nessun contratto collegato!</i></div>
                                    </ng-container>
                                </div>
                            </div>
                            <!-- ACTIONS -->
                            <div class="card" *ngIf="actions_response && actions_response.length>0">
                                <div class="card-header">
                                    Azioni
                                </div>
                                <div class="card-body text-center">
                                    <ng-container *ngFor="let action of actions_response">
                                        <p>{{action.result}}</p>
                                    </ng-container>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- CARATTERISTICHE -->
                <div class="tab-pane " id="tabInstallation3" role="tabpanel" aria-labelledby="tab3-tab">
                    <button *ngIf="record.id_product>0" class="btn btn-secondary btn-sm" (click)="setDeafultCustomFields(record.id_product)">Imposta valori di default</button>
                    <!-- Extra fields -->
                    <app-customfields *ngIf="record.id_product>0 && record.product" [list]="record.customfields" [table]="'installations'" [id_table]="record.id" [condition_id]="record.product.id_category" [condition_field]="'category'"></app-customfields>
                </div>
                <!-- NOTE -->
                <div class="tab-pane " id="tabInstallation5" role="tabpanel" aria-labelledby="tab4-tab">
                    <app-notebox [list]="record.notes" [table]="'installations'" [id_table]="record.id" [id_address]="record.shop.id_address" *ngIf="id != 0 && record.shop"></app-notebox>
                </div>
                
                <!-- MONITORAGGIO -->
                <div class="tab-pane " id="tabInstallation7" role="tabpanel" aria-labelledby="tab7-tab">
                    <app-serialslogs *ngIf="record.id_product>0 && record.product && record.product.id_category==id_category_log && record.sn!=''" [sn]="record.sn"></app-serialslogs>
                </div>
                <!-- MAGAZZINO -->
                <div class="tab-pane " id="tabInstallation8" role="tabpanel" aria-labelledby="tab7-tab" *ngIf="isModuleEnabled('inventories')">                    
                    <div class="row" *ngIf="record.serials">                        
                        <ng-container *ngFor="let s of record.serials">
                            <div class="col-4 col-lg-2" *ngIf="s.sn==record.sn">
                                    <div class="card">
                                        <div class="card-header py-1 text-center">
                                            <div class="w-100 text-truncate">{{s.inventoryname}}</div>
                                        </div>
                                        <div class="card-body text-center">
                                            <h3>{{s.avaible}}</h3>
                                            <button [disabled]="s.avaible==0" [class.btn-light]="s.avaible==0" class="btn btn-danger btn-block btn-xs" (click)="resetStock(s.id_inventory)">Azzera giacenza</button>
                                        </div>
                                    </div>
                            </div>
                        </ng-container>
                    </div>
                    <app-loginventory *ngIf="record.sn" [filterbox]="false" [mode]="'embedded'" [sn]="record.sn" [id_product]="record.id_product"></app-loginventory>
                </div>
                <!-- MODULI -->
                <div class="tab-pane " id="tabInstallation9" role="tabpanel" aria-labelledby="tab9-tab" *ngIf="isModuleEnabled('forms')">
                    <app-form-list [mode]="'embedded'" [default_filterdate]="100" [id_table]="record.id" [table]="'installations'" [record]="record"></app-form-list>
                </div>
            </div>
        </div>
        <hr>
        
        <!-- SEZIONE INFERIORE -->
        <div class="row" *ngIf="id != 0">            
            <div class="pr-0 col-2">
                <div class="nav flex-column nav-pills" id="custom-tabs-four-tab" role="tablist">
                    <!-- Cronologia -->
                    <ng-container *ngIf="id != 0 && (isModuleEnabled('forms') || isModuleEnabled('shops'))">
                        <a class="nav-link active " id="installation_extra-tabs-6-tab" data-toggle="pill" href="#installation_extra-tabs-4" 
                        role="tab" aria-controls="installation_extra-tabs-6" aria-selected="true">
                        <i class="nav-icon far fa-clock"></i><span class="d-none d-sm-inline-block ml-2">Cronologia</span></a>
                    </ng-container>
                    <!-- Attività -->
                    <ng-container *ngIf="id != 0 && isModuleEnabled('activities')">
                        <a class="nav-link " id="installation_extra-tabs-3-tab" data-toggle="pill" href="#installation_extra-tabs-3" 
                        role="tab" aria-controls="installation_extra-tabs-3" aria-selected="true">
                        <i class="nav-icon fas fa-tasks"></i><span class="d-none d-sm-inline-block ml-2">Attività</span></a>
                    </ng-container>
                    <!-- Movimenti -->
                    <ng-container *ngIf="id != 0 && isModuleEnabled('cash-flow')">
                        <a class="nav-link " id="installation_extra-tabs-1-tab" data-toggle="pill" href="#installation_extra-tabs-1" 
                        role="tab" aria-controls="installation_extra-tabs-1" aria-selected="true">
                            <i class="nav-icon fas fa-dollar-sign"></i><span class="d-none d-sm-inline-block ml-2">Movimenti</span></a>
                    </ng-container>
                    <!-- Scadenze -->
                    <ng-container  *ngIf="id != 0 && isModuleEnabled('deadlines')">
                        <a class="nav-link " id="installation_extra-tabs-2-tab" data-toggle="pill" href="#installation_extra-tabs-2" 
                        role="tab" aria-controls="installation_extra-tabs-2" aria-selected="true">
                        <i class="nav-icon far fa-calendar-alt"></i><span class="d-none d-sm-inline-block ml-2">Agenda</span></a>
                    </ng-container>
                    <!-- Operatori -->
                    <!--
                    <ng-container *ngIf="id != 0">
                        <a class="nav-link  " id="installation_extra-tabs-4-tab" data-toggle="pill" href="#installation_extra-tabs-4" 
                        role="tab" aria-controls="installation_extra-tabs-4" aria-selected="true">
                        <i class="nav-icon fas fa-user-tie"></i><span class="d-none d-sm-inline-block ml-2">Operatori</span></a>
                    </ng-container>
                    -->
                    <!-- Contratti -->
                    <!--
                    <ng-container *ngIf="id != 0">
                        <a class="nav-link  " id="installation_extra-tabs-5-tab" data-toggle="pill" href="#installation_extra-tabs-5" 
                        role="tab" aria-controls="custoinstallation_extra-tabs-5" aria-selected="true">
                        <i class="nav-icon fas fa-file-invoice-dollar"></i><span class="d-none d-sm-inline-block ml-2">Contratti</span></a>
                    </ng-container>
                    -->
                </div>
            </div>
            <div class="col-10">
                <div class="tab-content" id="custom-tabs-four-tabContent">
                    <!-- TAB: Attività -->
                        <div class="tab-pane pb-2" id="installation_extra-tabs-3" role="tabpanel" aria-labelledby="installation_extra-tabs-3-tab">
                        <app-activities-list [default_filterdate]="100" [id_table]="record.id" [table]="'installations'" [reference]="record" [no_dateend]="true"  [mode]="'embedded'" *ngIf="id != 0"></app-activities-list>
                    </div>
                    <!-- TAB: Scadenze -->
                    <div class="tab-pane" id="installation_extra-tabs-2" role="tabpanel" aria-labelledby="installation_extra-tabs-2-tab">
                        <app-deadlines [viewMode]="'table'" [filter_box]="false" [default_filterdate]="100" [id_table]="record.id" [table]="'installations'"  [reference]="record" [no_dateend]="true" [mode]="'embedded'" *ngIf="id != 0"></app-deadlines>
                    </div>
                    <!-- TAB: Movimenti -->
                    <div class="tab-pane " id="installation_extra-tabs-1" role="tabpanel" aria-labelledby="installation_extra-tabs-1-tab">
                        <app-cash-flow *ngIf="record.id != 0" [mode]="'embedded'" [id_table]="record.id" [table]="'installations'" [reference]="record"></app-cash-flow>
                    </div>
                    <!-- TAB: Operatori -->
                    <div class="tab-pane" id="installation_extra-tabs-4" role="tabpanel" aria-labelledby="installation_extra-tabs-4-tab">
                        <app-logoperatordetail *ngIf="record.id != 0" [id_table]="record.id" [table]="'installations'"></app-logoperatordetail>
                    </div>
                    <!-- TAB: Contratti -->
                    <div class="tab-pane" id="installation_extra-tabs-5" role="tabpanel" aria-labelledby="installation_extra-tabs-5-tab" *ngIf="isModuleEnabled('contracts')">
                        <app-contracts [mode]="'embedded'" *ngIf="record.id != 0" [id_installation]="record.id" [id_shop]="record.id_shop"></app-contracts>
                    </div>
                    <!-- TAB: Cronologia -->
                    <div class="tab-pane fade show active " *ngIf="record.id>0" id="installation_extra-tabs-6" role="tabpanel" aria-labelledby="tab6-tab">
                        <div class="row">
                            <div class="col-12" *ngIf="isModuleEnabled('forms')">
                                <div class="card">
                                    <div class="card-header py-1">
                                        Censimenti
                                    </div>
                                    <div class="card-body">
                                        <table class="table table-sm">
                                            <thead>
                                                <th>Data</th>
                                                <th *ngFor="let f of record.fields">{{f.label}}</th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let f of record.fieldsvalues">
                                                <td>
                                                    {{f.date | date:"dd/MM/Y"}}
                                                </td>
                                                <td *ngFor="let fv of f.values">
                                                        {{fv.value}}
                                                </td> 
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" *ngIf="isModuleEnabled('shops')">
                                <div class="card">
                                    <div class="card-header py-1">
                                        Spostamenti
                                        <div class="card-tools">
                                            <button class="btn btn-success btn-xs" (click)="newModalXrifInstallationShop()"><i class="fa fa-plus"></i> &nbsp;Aggiungi</button>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <table class="table table-sm">
                                            <thead>
                                                <th>Data</th>
                                                <th>Ubicazione</th>
                                            
                                                <th>Note</th>
                                                <th></th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let h of record.history">
                                                    <td>{{h.created | date:'dd/MM/yyyy'}}</td>
                                                    <td>{{h.shopname }}
                                                        <span class="badge badge-danger" *ngIf="h.shopstatus==3">Cessato</span>
                                                        <span class="badge badge-danger" *ngIf="h.shopstatus==4">Perso</span>
                                                        <span class="badge badge-danger" *ngIf="h.shopstatus==5">Chiuso</span>
                                                    </td>
                                                    <td>{{h.note}}</td>
                                                    <td>
                                                        <button class="btn btn-secondary btn-xs" (click)="openModalXrifInstallationShop(h)"><i class="fa fa-pencil-alt"></i></button>
                                                        <button class="btn btn-danger btn-xs ml-1" (click)="removeCronology(h)"><i class="fa fa-trash"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- fine TAB: Cronologia -->
                </div>
            </div>
        </div>
    </form>
</div>

<app-toolbarmodel [model]="this"></app-toolbarmodel>


<ng-template #modalChangeLocation>
    <div class="modal-body">
        <h3>Indicare la motivazione dello spostamento del prodotto</h3>
        <div class="form-group">
            <input type="text" class="form-control form-control-sm" name="causalMove" [(ngModel)]="causalMove" >
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="closeModalChangeLocation()">Annulla</button>
        <button class="btn btn-success" (click)="setCausal()">Ok</button>
    </div>
</ng-template>


<ng-template #modalXrifInstallationShop>
    <div class="modal-body">
        <div class="form-group">
            <label>Data</label>
            <input *ngIf="selectedXrifInstallationShop.id>0" type="text" class="form-control form-control-sm" name="created" [(ngModel)]="selectedXrifInstallationShop.created">
            
            <input *ngIf="selectedXrifInstallationShop.id==0" type="date" class="form-control form-control-sm" name="created" [(ngModel)]="selectedXrifInstallationShop.created">
        </div>
        <div class="form-group">
            <label>Ubicazione</label>
            <div class="input-group input-group-sm">
                <p class="form-control form-control-sm">
                    <span *ngIf="selectedXrifInstallationShop.shopname">
                        {{selectedXrifInstallationShop.shopname}}
                    </span>
                </p>
                <div class="input-group-append ">
                    <button class="btn btn-default" (click)="selectXrifShop()">
                        <span class="d-none d-sm-inline-block mr-2">Sposta ubicazione</span><i class="fas fa-shop"></i>
                    </button>
                </div>
            </div> 
        </div>
        <div class="form-group">
            <label>Note</label>
            <input type="text" class="form-control form-control-sm" name="note" [(ngModel)]="selectedXrifInstallationShop.note">
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="closeModalXrifInstallationShop()">Annulla</button>
        <button class="btn btn-success" (click)="SaveXrifInstallationShop()">Salva</button>
    </div>
</ng-template>