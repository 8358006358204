    <div class="row">
        <div class="col-lg-2 pr-0 pt-1 border-right">
            <small><i class="fas fa-sliders-h mx-2"></i><b>Ricerca avanzata</b></small>
        </div>
        <div class="col-lg-9">
            <div *ngIf="!filters || filters.length==0" class="m-1 text-center text-secondary">
                <small>
                    <ng-container *ngIf="customfields && customfields.length>0">
                        <i class="fas fa-info-circle mr-2"></i>
                        <i>Aggiungi un criterio di ricerca premendo sul pulsante<i class="fa fa-plus text-success ml-1"></i></i>
                    </ng-container>
                    <ng-container *ngIf="!customfields || customfields.length==0">
                        <i class="fas fa-exclamation-triangle text-warning mr-2"></i>
                        <i>Non sono stati definiti campi personalizzati per questa sezione!</i> 
                    </ng-container>
                </small>
            </div>
            <div *ngIf="filters">                
                <div class="input-group input-group-sm mb-1" *ngFor="let f of filters">
                    <div class="col-1 col-lg-1 pr-1">
                        <a title="Elimina regola" class="btn btn-sm btn-outline-danger float-right" (click)="removeFilter(f)"><i class="fa fa-minus"></i></a>
                    </div>
                        <div class="col-11 col-lg-4 px-0">
                            <select name="customfield" class="form-control form-control-sm" [(ngModel)]="f.customfield">
                                <ng-container *ngFor="let c of customfields">
                                    <ng-container *ngIf="c.type=='separator'">
                                        <optgroup label="{{c.label}}"></optgroup>
                                    </ng-container>
                                    <ng-container *ngIf="c.type!='separator'">
                                        <option  [ngValue]="c">{{c.label}}</option>
                                    </ng-container>
                                </ng-container>
                            </select>
                        </div>
                        <div class="col-lg-3 input-group px-0">
                            <select name="condition" class="form-control form-control-sm text-center" [(ngModel)]="f.condition">
                                <option value="ugual">uguale</option>
                                <option value="major">maggiore</option>
                                <option value="majorugual">maggiore e uguale</option>
                                <option value="minor">minore</option>
                                <option value="minorugual">minore e uguale</option>
                                <option value="like">contiene</option>
                                <option value="notlike">non contiene</option>
                                <option value="null">non valorizzato</option>
                                <option value="notnull">valorizzato</option>
                            </select>
                        </div>
                        <div class="col-lg-4 px-0" *ngIf="f.customfield">
                                <input *ngIf="f.customfield.type=='text' || f.customfield.type=='multi' || f.customfield.type=='tax_code'" type="text" name="value" class="form-control form-control-sm" [(ngModel)]="f.value" />
                                <input *ngIf="f.customfield.type=='date'" type="date" name="value" class="form-control form-control-sm" [(ngModel)]="f.value" />
                                <input *ngIf="f.customfield.type=='url'" type="url" name="value" class="form-control form-control-sm" [(ngModel)]="f.value" />
                                <select *ngIf="f.customfield.type=='checkbox'" name="value" class="form-control form-control-sm" [(ngModel)]="f.value">
                                    <option value="1">Si</option>    
                                    <option value="0">No</option>
                                </select>
                                <input *ngIf="f.customfield.type=='number'" type="number" name="value" class="form-control form-control-sm" [(ngModel)]="f.value" />

                                <div class="input-group" *ngIf="f.customfield.type=='currency'" >
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text">&euro;</span>
                                    </div>
                                    <input type="number" class="form-control form-control-sm"  min="0" step="0.01" name="value" placeholder="..." [(ngModel)]="f.value" >
                                </div>
                                <div *ngIf="f.customfield.type=='select'">
                                    <select class="form-control form-control-sm"  name="value" [(ngModel)]="f.value">
                                        <optgroup *ngIf="f.customfield.option!=null && f.customfield.option!=''">
                                            <option  *ngFor="let o of f.customfield.option" value="{{o.item}}">{{o.item}}</option>
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                            <!--<td class="pt-0 border-0">
                                <select class="custom-select"  name="value" [(ngModel)]="f.nextCondition">
                                    <option value="INTERSECT"> E </option>
                                    <option value="UNION"> O </option>
                                </select>
                            </td>-->
            </div>
        </div>
    </div>
    <div class="col-lg-1 pr-0">
        <button type="button" title="Aggiungi criterio" class="btn btn-outline-success btn-sm float-right" 
                [disabled]="!customfields || customfields.length==0" 
                (click)="addFilter()">
        <i class="fa fa-plus"></i></button>
    </div>
</div>
