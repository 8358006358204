import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'projects/core/src/authGuard';
import { ContractsComponent } from './contracts.component';
import { ContractsDetailComponent } from './contracts-detail/contracts-detail.component';


const routes: Routes = [
  { path: '', component: ContractsComponent,canActivate:[AuthGuard] },
  { path: 'contracts-details/:id', component: ContractsDetailComponent ,canActivate:[AuthGuard]},
  { path: 'contracts-details/:id/:id_shop', component: ContractsDetailComponent,canActivate:[AuthGuard]},
  { path: 'contracts-details/:id/:id_shop/:id_installation', component: ContractsDetailComponent,canActivate:[AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContractsRoutingModule { }
