<app-window [title]="title">

    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item ">
            <a class="nav-link"  [class.active]="main_tab==1" (click)="main_tab=1">
                <i class="fas fa-clipboard"></i><span class="d-none d-sm-inline-block ml-2">Generale</span></a>
        </li>
        <li class="nav-item" *ngIf="id>0">
            <a class="nav-link" [class.active]="main_tab==2" (click)="main_tab=2">
                <i class="fas fa-calendar-alt"></i><span class="d-none d-sm-inline-block ml-2">Piano rate</span></a>
        </li>
        <li class="nav-item" *ngIf="isModuleEnabled('installations')">
            <a class="nav-link" [class.active]="main_tab==3" (click)="main_tab=3">
                <i class="fas fa-cash-register"></i>
                <span class="d-none d-sm-inline-block ml-2">Prodotti del contratto 
                    <small>
                        <span class="badge badge-warning" *ngIf="record.installations && record.installations.length>0">{{record.installations.length}}</span>
                        <span class="badge badge-danger" *ngIf="!record.installations || record.installations.length==0">0</span>
                    </small>
                </span>
            </a>
        </li>
        <li class="nav-item" *ngIf="id>0">
            <a class="nav-link" [class.active]="main_tab==4" (click)="main_tab=4">
                <i class="fas fa-hdd"></i><span class="d-none d-sm-inline-block ml-2">Files <small><span class="badge badge-info" *ngIf="record.files && record.files.length>0">{{record.files.length}}</span></small></span></a>
        </li>
        <li class="nav-item" *ngIf="isModuleEnabled('forms') && id>0">
            <a class="nav-link" [class.active]="main_tab==5" (click)="main_tab=5">
                <i class="fab fa-wpforms"></i><span class="d-none d-sm-inline-block ml-2">Moduli </span></a>
        </li>
        <li class="nav-item" *ngIf="isModuleEnabled('documents') && id>0">
            <a class="nav-link" [class.active]="main_tab==6" (click)="main_tab=6">
                <i class="far fa-file-alt"></i><span class="d-none d-sm-inline-block ml-2">Documenti <small><span class="badge badge-primary" *ngIf="documentList && documentList.recordcount">{{documentList.recordcount}}</span></small></span></a>
        </li>
    </ul>
    <div class="p-2" >
        <div class="tab-content" id="myTabContent">
            <!-- GENERALE -->
            <div *ngIf="main_tab==1" [class.active]="main_tab==1" class="tab-pane fade show">
                <form role="form" id="detailForm" *ngIf="record">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="">
                                <label>Inizio</label>
                                <div class="input-group" >
                                    <input class="form-control form-control-sm" type="date"  name="start"  [(ngModel)]="record.start"  />
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="">
                                <label>Fine</label>
                                <div class="input-group input-group-sm" >
                                    <input class="form-control form-control-sm" type="date"  name="end"  [(ngModel)]="record.end"  />
                                    <div class="input-group-append ">
                                        <button class="btn btn-default" title="Senza fine" (click)="record.end='2099-12-31'">
                                            <i class="far fa-calendar-times"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <app-type [id_type]="record.id_type" [table]="'contracts'" [record]="record" (onEntrySelected)="record.id_type=$event.id_type"></app-type>
                        </div>
                        <div class="col-sm-6">
                            <div class="">
                                <label>Fase</label>
                                <select class="form-control form-control-sm" [(ngModel)]="record.phase" name="phase" #phase="ngModel" required>
                                    <option value="1">Attivo</option>
                                    <option value="2">Concluso</option>
                                    <option value="3">Annullato</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <label>Anagrafica</label>
                            <div class="small-box bg-light mb-0">
                                <app-selectfieldcustom (OnExplore)="selectAddress()" (OnRecordSelected)="OnSelectAddress($event)" (OnRecordView)="OnViewAddress($event)"
                                    [showViewButton]="isModuleEnabled('address')"
                                    [showSearchButton]="isModuleEnabled('address')"
                                    [recordSelected]="record.addressItem"
                                    [service]="addressesService"
                                    [fieldRender]="'name'"
                                    [class]="'form-control form-control-sm'"></app-selectfieldcustom>
                                <div class="p-1" *ngIf="record.addressItem && record.addressItem.id>0">
                                    <small>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <small>
                                                    <ng-container *ngIf="record.addressItem.address || record.addressItem.city">
                                                        <b>INDIRIZZO:</b><br>
                                                        {{record.addressItem.address}}<br>
                                                        {{record.addressItem.zip}} {{record.addressItem.city}} {{record.addressItem.country}}<br>
                                                    </ng-container>
                                                    <span *ngIf="record.addressItem.tax_code">COD. FISC.: {{record.addressItem.tax_code}}<br></span>
                                                    <span *ngIf="record.addressItem.vat_number">P.IVA: {{record.addressItem.vat_number}}</span>
                                                </small>
                                            </div>
                                            <div class="col-lg-6 border-left" *ngIf="record.addressItem.contacts.length > 0">
                                                <small>
                                                    <b>CONTATTI:</b><br>
                                                    <span *ngFor="let c of record.addressItem.contacts">{{c.value}}<br></span>
                                                </small>
                                            </div>
                                        </div>
                                    </small>
                                    <div class="icon"><i class="fa fa-user"></i></div>
                                </div>
                            </div>            
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="">
                                <label>Ubicazione</label>
                                <div class="input-group input-group-sm">
                                    <p class="form-control form-control-sm">
                                        <span *ngIf="record.shop">{{record.shop.name}}</span>
                                    </p>
                                    <div class="input-group-append" *ngIf="record.id_shop!=0">
                                        <button class="btn btn-default" (click)="record.id_shop=0;record.shop=''" title="Rimuovi">
                                            <i class="fa fa-times text-danger"></i></button>
                                    </div>
                                    <div class="input-group-append ">
                                        <button class="btn btn-default" (click)="selectShop()">
                                            <i class="fas fa-store-alt mr-2"></i><span class="d-none d-sm-inline-block">Scegli...</span></button>
                                    </div>                                    
                                </div> 
                            </div>
                        </div>
                    </div>                   
                    <div class="row">
                        <div class="col-sm-5">
                            <div class="">
                                <label>Importo</label>
                                <div class="input-group input-group-sm">
                                    <input (keyup)="calculateGross()"  (change)="calculateGross()" required class="form-control form-control-sm" type="number"  name="amount" #amount="ngModel"  [(ngModel)]="record.amount"  />
                                    <div *ngIf="amount.invalid && (amount.dirty || amount.touched)" class="alert alert-danger">
                                        <div *ngIf="amount.errors.required">Campo obbligatorio.</div>
                                    </div>
                                    <div class="input-group-append">
                                        <select class="form-control form-control-sm" name="amount_type" [(ngModel)]="record.amount_type">
                                            <option value="1">&euro;</option>
                                            <option value="30">&euro;/mese</option>
                                            <option value="360">&euro;/anno</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="">
                                <label>IVA</label>
                                <div class="input-group input-group-sm">
                                    <input required (keyup)="calculateGross()" (change)="calculateGross()" class="form-control form-control-sm" type="number" min="0" max="100"  name="tax" #tax="ngModel"  [(ngModel)]="record.tax"  />
                                    <div *ngIf="tax.invalid && (tax.dirty || tax.touched)" class="alert alert-danger">
                                        <div *ngIf="tax.errors.required">Campo obbligatorio.</div>
                                    </div>
                                    <div class="input-group-append">
                                        <span class="input-group-text input-group-text-sm">%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-5">
                            <div class="">
                                <label>Totale</label>
                                <div class="input-group input-group-sm">
                                    <input class="form-control form-control-sm" type="number"  name="gross" #gross="ngModel"  [(ngModel)]="record.gross" (keyup)="calculateNet()" (change)="calculateNet()"  />
                                    <div class="input-group-append">
                                        <span class="input-group-text input-group-text-sm">&euro;</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
<!--
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Frequenza sull'incasso</label>
                                <select class="custom-select" name="frequency" [(ngModel)]="record.frequency">
                                    <option value="0">nessuna ripetizione</option>
                                    <option value="1">ogni giorno</option>
                                    <option value="7">ogni 7 giorni</option>
                                    <option value="30">ogni mese</option>
                                    <option value="60">ogni 2 mesi</option>
                                    <option value="90">ogni 3 mesi</option>
                                    <option value="120">ogni 4 mesi</option>
                                    <option value="150">ogni 5 mesi</option>
                                    <option value="180">ogni 6 mesi</option>
                                    <option value="360">ogni anno</option>
                                    <option value="720">ogni 2 anni</option>
                                </select>
                        
                            </div>
                        </div>
                    -->

                    <div class="row">                       
                        <div class="col-sm-12">
                            <div class="">
                                <label>Descrizione</label>
                                <div class="input-group">
                                    <textarea  class="form-control form-control-sm" rows="1" onblur="this.rows=1;" onfocus="this.rows=4;"  name="description" [(ngModel)]="record.description" placeholder="..."></textarea>
                                </div> 
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="">
                                <label>Note</label>
                                <div class="input-group">
                                    <textarea  class="form-control form-control-sm" rows="1" onblur="this.rows=1;" onfocus="this.rows=4;"  name="note" [(ngModel)]="record.note" placeholder="..."></textarea>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <!-- Extra fields -->
                    <hr>
                    <app-customfields [list]="record.customfields" [table]="'contracts'" [id_table]="record.id" ></app-customfields>
                </form>
            </div>
            <!-- PIANO RATE-->
            <div *ngIf="main_tab==2" [class.active]="main_tab==2" class="tab-pane fade show">
                <ng-container *ngIf="record.id>0" >
                    <div class="row">
                        <div class="col-12">
                            <button class="btn btn-primary btn-sm m-1" (click)="openGenerateInstallments()"><i class="fas fa-plus-circle mr-2"></i>Genera piano rate</button>
                        </div>
                    </div>
                    <app-deadlines [viewMode]="'table'" [filter_box]="false" [toolbar_action]="false"  [no_dateend]="true" [id_table]="record.id" [table]="'contracts'" [mode]="'embedded'" [id_address]="record.shop.id_address" [paging_count]="24"></app-deadlines>
                </ng-container>
                <div *ngIf="!record || record.id==0 || record.id==undefined" class="m-5 text-center text-secondary">
                    <i class="fas fa-info-circle mr-2"></i>
                    <i>È necessario salvare il contratto per poter generare il piano delle rate!</i>
                </div>
            </div>
            <!-- PRODOTTI INSTALLATI -->
            <div *ngIf="main_tab==3" [class.active]="main_tab==3" class="tab-pane fade show">
                <div *ngIf="!record.installations || record.installations.length==0" class="m-5 text-center text-secondary">
                    <i class="fas fa-info-circle mr-2"></i>
                    <i>Nessun prodotto installato!</i>
                    <br/>
                    <button type="button" class="btn btn-sm btn-success"  (click)="addInstallation()" ><i class="fas fa-plus"></i>&nbsp;Aggiungi un prodotto</button>
                </div>
                <table class="table table-sm text-sm" *ngIf="record.installations && record.installations.length>0">
                    <thead>
                        <th>Marca e Modello</th>
                        <th>Importo Rata (IVA inclusa)</th>
                        <th>Valore bene</th>
                        <th><button type="button" class="btn btn-xs btn-success float-right"  (click)="addInstallation()" ><i class="fas fa-plus"></i></button></th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let p of record.installations">
                            <td><small class="text-info">{{p.category}}</small>
                                <br/>
                                <!-- <a title="Vedi dettagli" [routerLink]="['/installations/installations-details', p.id ]"><i class="fa fa-cash-register mr-2"></i></a> -->
                                <i class="fa fa-cash-register mr-1 text-primary pointer" (click)="openProductDetail(p.id)" title="Dettaglio prodotto"></i>
                                <b>{{p.brand}}</b> {{p.model}} <small>(SN: {{p.sn}})</small>
                                <div class="alert alert-warning mb-0 p-1" *ngIf="p.id_shop!=record.id_shop">
                                    <i class="fas fa-exclamation mr-2"></i>
                                    <small><b>Attualmente assegnato ad altra sede. </b>
                                        Salvando il record questo prodotto verrà assegnato alla sede del contratto!</small>
                                    <select class="form-control form-control-sm p-0" style="height:auto;" name="reassign" #reassign="ngModel"  required [(ngModel)]="p.reassign">                                        
                                        <option [value]="true" selected>Riassegna questo prodotto alla sede {{record.shop.name}}</option>
                                        <option [value]="false">Non fare nessuna azione</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">                                    
                                    <input type="number" min="0" step="0.01" class="form-control pr-0" [(ngModel)]="p.price" (keyup)="calculateTotal()" (change)="calculateTotal()" />
                                    <div class="input-group-append">
                                        <span class="input-group-text">&euro;
                                            <span *ngIf="record.amount_type==30">/mese</span>
                                            <span *ngIf="record.amount_type==360">/anno</span>
                                        </span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="input-group">                                   
                                    <input type="number" min="0" step="0.01" class="form-control pr-0" [(ngModel)]="p.value"  />
                                    <div class="input-group-append">
                                        <span class="input-group-text">&euro;</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <button class="btn btn-xs btn-danger float-right" title="Elimina" (click)="deleteInstallation(p);"><i class="far fa-trash-alt"></i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- FILE-->
            <div *ngIf="main_tab==4" [class.active]="main_tab==4" class="tab-pane fade show">
                <app-files [files]="record.files" [directory]="'contracts/'+id"></app-files>
            </div>
            <!-- MODULI-->
            <div *ngIf="main_tab==5" [class.active]="main_tab==5" class="tab-pane fade show">
                <app-form-list [mode]="'embedded'" [default_filterdate]="100" [id_table]="record.id" [table]="'contracts'" [record]="record"></app-form-list>
            </div>
            <!-- DOCUMENTI-->
            <div [style.visibility.hidden]="main_tab!=6" [class.active]="main_tab==6" class="tab-pane fade show">
                <button type="button" class="btn btn-sm btn-success" (click)="addDocument();"><i class="fa fa-plus mr-2"></i>Aggiungi documento</button>
                <button type="button" class="btn btn-sm btn-primary ml-2" (click)="linkDocument();"><i class="fa fa-link mr-2"></i>Collega documento</button>
                <app-documentlist #documentlist [filterbox]="false" [type]="0" [id_table]="record.id" [table]="'contracts'" [mode]="'embedded'"></app-documentlist>
              
            </div>
        </div>
    </div>
    <app-toolbarmodel [model]="this"></app-toolbarmodel>

    
    <ng-template #generateInstallmentsModal let-modal>
        <div class="modal-header">
            <b>Crea piano rate</b>
            <button type="button" class="btn btn-tool" (click)="closeGenerateInstallments()" >
                <i class="fas fa-times"></i>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-2"><label>Dal</label></div>
                <div class="col-4">
                    <div class="input-group">
                        <input class="form-control" type="date"  name="start"  [(ngModel)]="generate.start"  />
                    </div>
                </div>
                <div class="col-2 text-right"><label>Al</label></div>
                <div class="col-4">
                    <div class="form-group">
                        <div class="input-group" >
                            <input class="form-control" type="date"  name="end"  [(ngModel)]="generate.end"  />
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-2"><label>Descrizione</label></div>
                <div class="col-10">
                    <div class="">
                        <input type="text" name="description" class="form-control pr-0" [(ngModel)]="generate.description" />
                        <small><b>keyword</b>:<br>
                            %n% = numero rata<br>
                            %id% = numero contratto<br>
                            %month% = mese rata<br>
                            %year% = anno rata
                        </small>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-2"><label>Importo</label></div>
                <div class="col-10">
                    <div class="input-group">
                        <input type="number" min="0" step="0.01" name="amount" class="form-control pr-0" [(ngModel)]="generate.amount" (change)="calculateGrossGenerate()" />
                        <div class="input-group-prepend ">
                            <span class="input-group-text">&euro;</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-2"><label>IVA</label></div>
                <div class="col-10">
                    <div class="input-group">
                        <input type="number" min="0" step="1" name="tax" class="form-control pr-0" [(ngModel)]="generate.tax"  (change)="calculateGrossGenerate()" />
                        <div class="input-group-prepend ">
                            <span class="input-group-text">%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-2"><label>Totale</label></div>
                <div class="col-10">
                    <div class="input-group">
                        <input type="number" min="0" step="1" name="gross" class="form-control pr-0" [(ngModel)]="generate.gross"  (change)="calculateNetGenerate()" />
                        <div class="input-group-prepend ">
                            <span class="input-group-text">&euro;</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group row">
                        <div class="col-2"><label>Tipologia</label></div>
                        <div class="col-10">
                            <div class="btn-group btn-block" role="group">
                                <button type="button" class="btn bg-light" [class.active]="tab==0" (click)="tab=0;generate.type=1;generate.amount_type=30;">Pianifica</button>
                                <button type="button" class="btn bg-light" [class.active]="tab==1" (click)="tab=1;generate.type=0;generate.amount_type=1;">Rateizza</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- PIANIFICA INCASSO -->
            <div class="row" *ngIf="tab==0" >
                <div class="col-sm-12">
                    <div class="form-group row">
                        <div class="col-2"><label>Ripeti importo</label></div>
                        <div class="col-10">
                        <div class="input-group">
                            <select class="form-control"  name="typedetail"  [(ngModel)]="generate.typedetail" >
                                <option value="1">ogni giorno</option>
                                <option value="7">ogni 7 giorni</option>
                                <option value="14">ogni 14 giorni</option>
                                <option value="30">ogni 30 giorni</option>
                                <option value="60">ogni 60 giorni</option>
                                <option value="90" >ogni 90 giorni</option>
                                <option value="180" >ogni 180 giorni</option>
                                <option value="365" >ogni anno</option>
                                <option value="-1" >al giorno del mese (da specificare)</option>
                                <option value="-2" >al giorno di ogni bimestre (da specificare)</option>
                                <option value="-3" >al giorno di ogni trimestre (da specificare)</option>
                                <option value="-4" >al giorno di ogni quadrimestre (da specificare)</option>
                                <option value="-5" >al giorno di ogni semestre (da specificare)</option>
                                <!--<option value="-2" >al giorno della settimana (da specificare)</option>-->
                            </select>
                            <div class="input-group-append">
                                <input *ngIf="generate.typedetail=='-1' || generate.typedetail=='-2' || generate.typedetail=='-3' || generate.typedetail=='-4' || generate.typedetail=='-5'" type="number" step="1" min="1" max="31" class="form-control" name="day_of_month" [(ngModel)]="generate.params" >
                            </div>
                        </div>
                    </div>
                    </div>
                    <div class="text-center text-primary">
                        <i *ngIf="generate.typedetail>0">Crea una rata da <b>{{generate.gross | currency:"&euro;"}}</b> ogni <b>{{generate.typedetail}}</b> giorni</i>
                        <i *ngIf="generate.typedetail==-1">Crea una rata da <b>{{generate.gross | currency:"&euro;"}}</b> ogni giorno <b>{{generate.params}}</b> del mese</i>
                        <i *ngIf="generate.typedetail==-2">Crea una rata da <b>{{generate.gross | currency:"&euro;"}}</b> ogni giorno <b>{{generate.params}}</b> al bimestre</i>
                        <i *ngIf="generate.typedetail==-3">Crea una rata da <b>{{generate.gross | currency:"&euro;"}}</b> ogni giorno <b>{{generate.params}}</b> al trimestre</i>
                        <i *ngIf="generate.typedetail==-3">Crea una rata da <b>{{generate.gross | currency:"&euro;"}}</b> ogni giorno <b>{{generate.params}}</b> al quadrimestre</i>
                        <i *ngIf="generate.typedetail==-3">Crea una rata da <b>{{generate.gross | currency:" &euro;"}}</b> ogni giorno <b>{{generate.params}}</b> al semestre</i>
                    </div>
                </div>
            </div>
            <!-- RATEIZZA -->
            <div class="row" *ngIf="tab==1">
                <div class="col-12">
                    <div class="form-group row">
                        <div class="col-2"><label>Dividi importo</label></div>
                        <div class="col-10">                        
                            <select class="form-control"  name="typedetail"  [(ngModel)]="generate.typedetail" >
                                <option value="7" >ogni 7 giorni</option>
                                <option value="15" >ogni 15 giorni</option>
                                <option value="30" >ogni 30 giorni</option>
                                <option value="60" >ogni 60 giorni</option>
                                <option value="90" >ogni 90 giorni</option>
                                <option value="180" >ogni 180 giorni</option>
                                <option value="360" >ogni anno</option>
                                <option value="720" >ogni 2 anni</option>
                            </select>
                        </div>
                    </div>
                    <div class="text-center text-primary">
                        <i>Suddivide l'importo di <b>{{generate.gross | currency:" &euro;"}}</b> in rate da pagare ogni <b>{{generate.typedetail}}</b> giorni</i>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
        <button type="button" class="btn btn-success"   (click)="generateInstallments()"><i class="fas fa-plus-circle mr-2"></i>Crea piano</button>
        <button type="button" class="btn btn-secondary" (click)="closeGenerateInstallments()">Annulla</button>
        </div>
    </ng-template>
</app-window>