import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { UploadService } from 'projects/core/src/lib/upload.service';
import { File, ToastMode } from 'projects/core/src/include/structures';
import { FilesService } from 'projects/core/src/lib/files.service';
import { FilesModule } from '../files/files.module';
import { Helper } from 'projects/core/src/helper';

@Component({
  selector: 'app-uploadfile',
  templateUrl: './uploadfile.component.html',
  styleUrls: ['./uploadfile.component.css']
})
export class UploadfileComponent implements OnInit {
  fileToUpload = null;
  isModal=false;
  @Input()
  subdirectory:string="";

  @Output()
  onUploaded:EventEmitter<any>=new EventEmitter();

  
  @Input()
  hashfile=false;

  @Input()
  saveAfterUpload=false;

  @Input()
  class="";

  default_filename:string="";

  percentual=0;

  onerror=false;
  errormessage="";

  @Input()
  params:any;

  @Input()
  record:any; //necessario nel caso di parserizzazione delle keyword dei parametri

  filename:string;
  
  filenametoupload:string;
  sizetoupload=0;
  get readonly(){
    return Globals.readonly;
  }
  
  constructor(
    private uploadService:UploadService,
    private filesService:FilesService

  ) { }

  ngOnInit(): void {

    this.parseParams();
  }

  parseKeyword(value,record){
    
    if(record){
      
      return Helper.replaceKeyword(value,record);
    }
      
    else
      return value;
  }


  parseParams(){

    


    if(this.params){
      if(!Array.isArray(this.params)){
        try{
          this.params=JSON.parse(this.params);
        }catch{
          this.params=[];
        }
      }

      if(this.params.length>0){
        for(let p of this.params){
          if(p.name=="subdirectory"){
            this.subdirectory=this.parseKeyword(p.value,this.record);
          }

          if(p.name=="default_filename"){
            
            this.default_filename=this.parseKeyword(p.value,this.record);
          }
        }
      }

      
      

    }
  }

  handleFileInput(files: FileList) {
    
    this.fileToUpload = files.item(0);
    this.filenametoupload=this.fileToUpload.name;
    this.sizetoupload=this.fileToUpload.size;
    
    this.uploadService.uploadFile(this.fileToUpload,this.subdirectory,(result)=>{
      this.percentual=0;
      if(!result['error']){
        let df:File=new File();
        df.file=result;
        df.name=this.fileToUpload['name'];
        df.size=this.fileToUpload['size'];
        df.table=this.subdirectory;
        df.id_table=0;
        df.permissions=FilesModule.PERMISSIONS_ALL;
       

        if(this.saveAfterUpload){
          this.filesService.save(df,()=>{
            this.onUploaded.emit(df);
          });
        }else{
          this.onUploaded.emit(df);
        }
        this.filename=result;
        this.close();        

      }else{
        if(result['error']==1){
          this.errormessage="Il file supera i limiti di caricamento del server (max "+result['maxsize']+")";
        }

        this.onerror=true;
          setTimeout(()=>{
            this.onerror=false;
          },3000)
      }
     
     
    },this.hashfile,"",(onprogress)=>{
      this.percentual=onprogress;
    });
}

close(){
  this['modalWindow'].close("success");
}

  formatSize(size){
    if(size>1000000000){
      return Math.round(size/1000000000) +" GB";
    } 

    if(size>1000000){
      return Math.round(size/1000000) +" MB";
    }

    if(size>1000){
      return Math.round(size/1000) +" kB";
    }  
    
     

    return size+" bytes";

  }
}
