import { Injectable } from '@angular/core';
import { UserGroup } from '../include/structures'
import { WSService } from '../include/service'
import { Observable, Observer } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})

export class UserGroupService  extends WSService<UserGroup> {

  constructor() { super("usergroups") }
  static usergroups:UserGroup[]=[];
  getGroups():Observable<UserGroup[]>{

    if(UserGroupService.usergroups.length==0){
      Globals.ws.requestUrl<UserGroup[]>("usergroups","getItems",[],true,0,1000000).subscribe((items:any)=>{
        UserGroupService.usergroups=items;
      });
      return Globals.ws.requestUrl<UserGroup[]>("usergroups","getItems",[],true,0,1000000);

    }else{

      function sequenceSubscriber(observer: Observer<any[]>) {
        observer.next(UserGroupService.usergroups);
        observer.complete();
        return {unsubscribe() {}};
      }
      
        return new Observable(sequenceSubscriber);
    }
    return  Globals.ws.requestUrl<UserGroup[]>("usergroups","getItems",[],true,0,1000000);
    
  }
}
