<div class="row" *ngIf="list!=undefined && list.length>0">                                    
    <div class="col-sm-12" >        
        <div class="form-group" *ngFor="let m of list">
            <label>{{m['label']}} ({{convertTableToName(m['table'])}}) <span class="text-danger" *ngIf="m['required']">*</span></label>            
            <div class="input-group input-group-sm">

                <ng-container [ngSwitch]="m['type']">

                    <ng-container *ngSwitchCase="'tax_code'" >
                        <app-tax-code  [tax_code]="m['value']" [name]="getFieldName(m['field'])" (onTextChange)="m['value']=$event" ></app-tax-code>
                    </ng-container>

                    <ng-container *ngSwitchCase="'vat_number'" >
                        <app-vat-number  [vat_number]="m['value']" [name]="getFieldName(m['field'])" (onTextChange)="m['value']=$event" ></app-vat-number>
                    </ng-container>

                    <ng-container *ngSwitchCase="'gps'" >
                        <input type="text"  class="form-control form-control-sm" rows="3" name="missingValues_{{m['field']}}" [(ngModel)]="m['value']" />
                        <div class="input-group-append">
                            <button type="button" class="btn btn-outline-success" (click)="getCoordinate()">Trova</button>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'qrcode'" >
                        <input type="text"  class="form-control form-control-sm" rows="3" name="missingValues_{{m['field']}}" [(ngModel)]="m['value']" />
                        <div class="input-group-append">
                            <button type="button" class="btn btn-outline-success" (click)="scan(m['field'])">Scansiona</button>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'contact'" >
                        <input type="text"  class="form-control form-control-sm" rows="3" name="missingValues_{{m['field']}}_value1" placeholder="Contatto..." [(ngModel)]="m['value1']"  (change)="editContact(m)"/>
                        <div class="input-group-append">
                            <input type="text"  class="form-control form-control-sm" rows="3" name="missingValues_{{m['field']}}_value2" placeholder="Nome del contatto..." [(ngModel)]="m['value2']" (change)="editContact(m)"/>
                        </div>
                    </ng-container>

                    <ng-container *ngSwitchCase="'select'" >
                        <select class="form-control form-control-sm" name="missingValues_{{m['field']}}" [(ngModel)]="m['value']" >
                            <option *ngFor="let p of m['params'].split(';')" value="{{p}}">{{p}}</option>
                        </select>                        
                    </ng-container>

                    <ng-container *ngSwitchCase="'timesheet'" >
                        <app-timesheet [record]="m" [fieldname]="'value'"></app-timesheet>
                    </ng-container>

                    <input *ngSwitchDefault type="text" class="form-control form-control-sm" rows="3" name="missingValues_{{m['field']}}" [(ngModel)]="m['value']" />
    
                </ng-container>                
            </div> 
        </div>
    </div>
</div>