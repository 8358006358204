import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ModelList } from 'projects/core/src/include/modellist';
import { Filter, FilterMode } from 'projects/core/src/include/structures';
import { Company } from '../../company';
import { CompaniesService } from '../../services/companies.service';
import { CompanyDetailComponent } from './company-detail/company-detail.component';

@Component({
  selector: 'app-tickets-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.css']
})
export class CompaniesComponent extends ModelList<Company> implements OnInit {



  filter_status:Filter=new Filter();
  filter_search:Filter=new Filter();


  constructor(
    private fb1: UntypedFormBuilder,
    private companiesService:CompaniesService
  ) { 
    super(companiesService,fb1);
    this.detailViewModal=CompanyDetailComponent;
  }

  ngOnInit(): void {

    this.title="Compagnie";
    super.ngOnInit.apply(this, arguments); 

    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("c.name");
    this.filter_search.value="";
    this.filter.push(this.filter_search);

    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("c.status");
    this.filter_status.value="1";
    this.filter.push(this.filter_status);

    this.getItems();

    /*this.companiesService.getItems().subscribe((items)=>{
      this.companies=items;
    })*/
  }


  close(){
    this['modalWindow'].close("success");
  }
}
