<div class="modal-header">
    <b>Conferma aggiornamento dati</b>
    <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="close()" >
            <i class="fas fa-times"></i>
        </button>
    </div>
</div>
<div class="modal-body">
    <label>Record di origine:</label>
    <app-reference *ngIf="item.reference" [table]="item.table" [reference]="item.reference"></app-reference>
    <br/>
    <br/>
    <p>Sono stati raccolti i seguenti dati. Selezionare quali campi aggiornare nel database</p>
    <table class="table table-sm">
        <thead>
            <th>Nome</th>
            <th>Valore</th>
            <th>Tabella</th>
            <th></th>
        </thead>
        <tbody>
            <tr *ngFor="let r of list;let i=index">
                <td>
                    {{r.label}}
                </td>
                <td>
                    <ng-container [ngSwitch]="r['type']">
                        <ng-container *ngSwitchCase="'tax_code'" >
                            <app-tax-code  [tax_code]="r['value']" [name]="getFieldName(r['field'])" (onTextChange)="r['value']=$event" ></app-tax-code>
                        </ng-container>    
                        <ng-container *ngSwitchCase="'vat_number'" >
                            <app-vat-number  [vat_number]="r['value']" [name]="getFieldName(r['field'])" (onTextChange)="r['value']=$event" ></app-vat-number>
                        </ng-container>                       
                        <ng-container *ngSwitchCase="'gps'" >    
                            <input type="text"  class="form-control form-control-sm" rows="3" name="missingValues_{{r['field']}}" [(ngModel)]="r['value']" />
                            <div class="input-group-append">
                                <button type="button" class="btn btn-default" (click)="getCoordinate()">Trova</button>
                            </div>
                        </ng-container>    
                        <ng-container *ngSwitchCase="'qrcode'" >    
                            <input type="text"  class="form-control form-control-sm" rows="3" name="missingValues_{{r['field']}}" [(ngModel)]="r['value']" />
                            <div class="input-group-append">
                                <button type="button" class="btn btn-default" (click)="scan(r['field'])">Scansiona</button>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'contact'" >
                            <input type="text"  class="form-control form-control-sm" rows="3" name="missingValues_{{r['field']}}_value1" placeholder="Contatto..." [(ngModel)]="r['value1']" />
                            <div class="input-group-append">
                                <input type="text"  class="form-control form-control-sm" rows="3" name="missingValues_{{r['field']}}_value2" placeholder="Nome del contatto..." [(ngModel)]="r['value2']" />
                            </div>
                        </ng-container>    
                        <input *ngSwitchDefault type="text"  class="form-control form-control-sm" rows="3" name="missingValues_{{r['field']}}" [(ngModel)]="r['value']" />
                    </ng-container>
                </td>
                <td>
                    {{r.table}}
                </td>
                <td>
                    <select class="form-control form-control-sm" name="{{i}}" [(ngModel)]="r.enable">
                        <option [value]="false">Non aggiornare</option>
                        <option [value]="true">Aggiorna il valore</option>
                    </select>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="modal-footer">
    <button class="btn btn-sm btn-success" (click)="confirm()">Conferma</button>
    <button class="btn btn-sm btn-secondary" (click)="close()">Annulla</button>
</div>